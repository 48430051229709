import React from "react";
import { FormattedMessage } from "react-intl";

import useCategories from "../../data-store/useCategories";
import useFoodTypes from "../../data-store/useFoodstepsCategories";
import useSites from "../../data-store/useSites";
import useSuppliers from "../../data-store/useSuppliers";
import StatusDisplay from "../StatusDisplay";
import Accordion from "../utils/Accordion";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import "./ProcurementFilterSelect.css";

interface SegmentedProductFilterMenuListProps {
  filterByParentCollections?: boolean;
  setFilters: (
    filterToSelectedCategories: Array<string>,
    filterToSelectedFoodTypes: Array<string>,
    filterToSelectedSuppliers: Array<string>,
    filterToSelectedSites: Array<string>
  ) => void;
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  onMenuClose: () => void;
  selectedCategories: Array<string>;
  setSelectedCategories: (selectedCategories: Array<string>) => void;
  selectedFoodTypes: Array<string>;
  setSelectedFoodTypes: (selectedCategories: Array<string>) => void;
  selectedSuppliers: Array<string>;
  setSelectedSuppliers: (selectedSuppliers: Array<string>) => void;
  selectedSites: Array<string>;
  setSelectedSites: (selectedSites: Array<string>) => void;
  containerStyle?: React.CSSProperties;
  hideAttention?: boolean;
}

export const SegmentedProductFilterMenuList = (
  props: SegmentedProductFilterMenuListProps
) => {
  const {
    setFilters,
    onMenuClose,
    selectedCategories,
    setSelectedCategories,
    selectedFoodTypes,
    setSelectedFoodTypes,
    selectedSuppliers,
    setSelectedSuppliers,
    selectedSites,
    setSelectedSites,
    containerStyle,
  } = props;

  const [foodTypesStatus] = useFoodTypes();
  const [suppliersStatus] = useSuppliers();
  const [categoriesStatus] = useCategories();
  const [sitesStatus] = useSites();

  const onApplyClick = () => {
    setFilters(
      selectedCategories,
      selectedFoodTypes,
      selectedSuppliers,
      selectedSites
    );
    onMenuClose();
  };

  const onClearFiltersClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedCategories([]);
    setSelectedFoodTypes([]);
    setSelectedSuppliers([]);
    setSelectedSites([]);
    // Leave the any/all choice as last chosen
    e.currentTarget.blur();
  };

  const handleCategoryCheckboxChange = (index: number, categ: string) => {
    // The backend needs three separate lists of category ids, because it's possible that
    // e.g. the string "Beef" is both a category-2 and a category-3.
    // This was the easiest way to separate the lists to unblock me.  Feel free to change.
    categ = `${index}/${categ}`;
    if (selectedCategories?.includes(categ)) {
      setSelectedCategories([
        ...selectedCategories.filter((id) => id !== categ),
      ]);
    } else {
      setSelectedCategories([...(selectedCategories || []), categ]);
    }
  };

  const handleFoodTypesCheckboxChange = (foodtype: string) => {
    if (selectedFoodTypes?.includes(foodtype)) {
      setSelectedFoodTypes([
        ...selectedFoodTypes.filter((id) => id !== foodtype),
      ]);
    } else {
      setSelectedFoodTypes([...(selectedFoodTypes || []), foodtype]);
    }
  };

  const handleSuppliersCheckboxChange = (supplier: string) => {
    if (selectedSuppliers?.includes(supplier)) {
      setSelectedSuppliers([
        ...selectedSuppliers.filter((id) => id !== supplier),
      ]);
    } else {
      setSelectedSuppliers([...(selectedSuppliers || []), supplier]);
    }
  };

  const handleSitesCheckboxChange = (site: string) => {
    if (selectedSites?.includes(site)) {
      setSelectedSites([...selectedSites.filter((id) => id !== site)]);
    } else {
      setSelectedSites([...(selectedSites || []), site]);
    }
  };

  const foodTypesOptions = (foodtypes: any[]) => {
    return foodtypes
      ?.filter((f) => f.name && f.id)
      ?.map((f) => {
        return {
          id: f.id,
          name: f.name,
          checked: selectedFoodTypes.includes(f.id),
          onChange: () => handleFoodTypesCheckboxChange(f.id),
        };
      });
  };

  const suppliersOptions = (suppliers: any[]) => {
    return suppliers
      ?.filter((s) => s.name && s.id)
      ?.map((s) => {
        return {
          id: s.id,
          name: s.name,
          checked: selectedSuppliers.includes(s.id),
          onChange: () => handleSuppliersCheckboxChange(s.id),
        };
      });
  };

  const groupedCategoriesOptions = (categories: any[][]) => {
    return categories.slice(0, 3).map((group, index) => ({
      eventKey: `category-${index}`,
      title: (
        <>
          <FormattedMessage
            id="components/dashboard-filter/SegmentedProductFilterMenuList:category"
            defaultMessage="Category"
          />{" "}
          {index + 1}
        </>
      ),
      options: group
        .filter((c) => c.name && c.id)
        .map((c) => ({
          id: c.id,
          name: c.name,
          checked: selectedCategories.includes(`${index}/${c.id}`),
          onChange: () => handleCategoryCheckboxChange(index, c.id),
        })),
    }));
  };

  const sitesOptions = (sites: any[]) => {
    return sites
      ?.filter((s) => s.name && s.id)
      ?.map((s) => {
        return {
          id: s.id,
          name: s.name,
          checked: selectedSites.includes(s.id),
          onChange: () => handleSitesCheckboxChange(s.id),
        };
      });
  };

  const menuItems = (
    foodTypes: Array<any>,
    suppliers: Array<any>,
    categories: Array<any>,
    sites: Array<any>
  ) => {
    const categorySections = groupedCategoriesOptions(categories);
    return [
      ...categorySections,
      {
        eventKey: "foodType",
        title: (
          <FormattedMessage
            id="components/dashboard-filter/SegmentedProductFilterMenuList:foodtype"
            defaultMessage="Food Type"
          />
        ),
        options: foodTypesOptions(foodTypes),
      },
      {
        eventKey: "supplier",
        title: (
          <FormattedMessage
            id="components/dashboard-filter/SegmentedProductFilterMenuList:supplier"
            defaultMessage="Supplier"
          />
        ),
        options: suppliersOptions(suppliers),
      },
      {
        eventKey: "site",
        title: (
          <FormattedMessage
            id="components/dashboard-filter/SegmentedProductFilterMenuList:site"
            defaultMessage="Site"
          />
        ),
        options: sitesOptions(sites),
      },
    ];
  };

  return (
    <div
      style={containerStyle}
      className="ProductFilterMenuList text-body react-select__menu"
    >
      <div className="Header">
        <h4 className="Title">
          <FormattedMessage
            id="components/product-filter/ProductFilterSelect:Title"
            defaultMessage="Filter by"
          />
        </h4>
      </div>
      <StatusDisplay.Many<[any, any, any, any]>
        statuses={[
          foodTypesStatus,
          suppliersStatus,
          categoriesStatus,
          sitesStatus,
        ]}
      >
        {(foodTypes, suppliers, categories, sites) => {
          return (
            <>
              <div className="FilterSection">
                <Accordion
                  items={menuItems(
                    foodTypes ?? [],
                    suppliers ?? [],
                    categories ?? [],
                    sites ?? []
                  )}
                />
              </div>

              <hr className="Divider" />

              <div className="EndButtons">
                <PrimaryButton onClick={() => onApplyClick()}>
                  {" "}
                  <FormattedMessage
                    id="components/product-filter/ProductFilterSelect:ApplyButtonText"
                    defaultMessage="Apply"
                  />
                </PrimaryButton>
                <SecondaryButton onClick={onClearFiltersClick}>
                  <FormattedMessage
                    id="components/product-filter/ProductFilterSelect:ClearFiltersButtonText"
                    defaultMessage="Clear filters"
                  />
                </SecondaryButton>
              </div>
            </>
          );
        }}
      </StatusDisplay.Many>
    </div>
  );
};
