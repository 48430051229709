import { createContext, useContext } from "react";

import { Assessment } from ".";
import StatusDisplay from "../components/StatusDisplay"; // Adjust path as needed
import useAssessments from "./useAssessments"; // Ensure correct import path

interface AssessmentsContextType {
  assessments: Array<Assessment> | undefined;
  refetch: () => void;
}

const AssessmentsContext = createContext<AssessmentsContextType | undefined>(
  undefined
);

export function useAssessmentsContext(): [
  Assessment[] | undefined,
  () => void
] {
  const context = useContext(AssessmentsContext);
  if (!context) {
    throw new Error(
      "useAssessmentsContext must be used within an AssessmentsProvider"
    );
  }
  return [context.assessments, context.refetch];
}

export function AssessmentsProvider({
  organizationId,
  children,
}: {
  organizationId: string;
  children: React.ReactNode;
}) {
  const [assessmentsStatus, refetch] = useAssessments(organizationId);

  return (
    <StatusDisplay status={assessmentsStatus}>
      {(assessments) => (
        <AssessmentsContext.Provider value={{ assessments, refetch }}>
          {children}
        </AssessmentsContext.Provider>
      )}
    </StatusDisplay>
  );
}
