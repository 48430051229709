import { ChartType, Plugin } from "chart.js";

export function createHoverLinePlugin<T extends ChartType>({
  id = "hoverLine",
  color = "gray",
  lineWidth = 1,
  selectedLabelIndexRef,
}: {
  id?: string;
  color?: string;
  lineWidth?: number;
  selectedLabelIndexRef: React.MutableRefObject<number | null>;
}): Plugin<T> {
  return {
    id,
    afterDatasetsDraw(chart) {
      const { ctx, chartArea, scales } = chart;
      const xScale = scales.x;

      if (!chartArea || selectedLabelIndexRef.current === null) return;

      const tickX = xScale.getPixelForTick(selectedLabelIndexRef.current);

      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([0, 0]); // Solid line
      ctx.moveTo(tickX, chartArea.top);
      ctx.lineTo(tickX, chartArea.bottom);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
      ctx.restore();
    },
  };
}
