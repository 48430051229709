import ReactMultiCarousel, {
  ButtonGroupProps,
  DotProps,
} from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import ChevronButton from "./ChevronButton";
import FilledCircle from "./Vectors/FilledCircle";
import "./Carousel.css";

const numVisibleItems = 4;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: numVisibleItems,
    partialVisibilityGutter: -3,
  },
};

interface CarouselProps {
  items: Array<JSX.Element>;
  footnote?: JSX.Element;
}

export function Carousel(props: CarouselProps) {
  return (
    <div className="Carousel_Container">
      <ReactMultiCarousel
        arrows={false}
        customDot={<Dot />}
        itemClass="Carousel_Item"
        partialVisible={true}
        responsive={responsive}
        renderDotsOutside
        renderButtonGroupOutside
        showDots
        customButtonGroup={
          <ButtonGroup
            lastItemIndex={props.items.length - 1}
            footnote={props.footnote}
          />
        }
        slidesToSlide={numVisibleItems}
        customTransition="transform 600ms ease-in-out"
        transitionDuration={600}
      >
        {props.items}
      </ReactMultiCarousel>
    </div>
  );
}

interface CarouselButtonGroupProps extends ButtonGroupProps {
  lastItemIndex: number;
  footnote?: JSX.Element;
}

const ButtonGroup = ({
  next,
  previous,
  carouselState,
  lastItemIndex,
  footnote,
}: CarouselButtonGroupProps) => {
  const currentSlide = carouselState ? carouselState.currentSlide : null;
  return (
    <div className="Carousel_ButtonGroup">
      {footnote}
      <div className="Carousel_ChevronButtons">
        <ChevronButton
          disabled={currentSlide === 0}
          direction="back"
          onClick={() => (previous ? previous() : null)}
        />
        <ChevronButton
          disabled={currentSlide === lastItemIndex - numVisibleItems + 1}
          direction="forward"
          onClick={() => (next ? next() : null)}
        />
      </div>
    </div>
  );
};

const Dot = ({ onClick, active }: DotProps) => {
  return (
    <FilledCircle
      className={active ? "Carousel_Dot" : "Carousel_Dot__Inactive"}
      fill={active ? "var(--foodsteps-turquoise)" : "white"}
      width={10}
      handleClick={onClick}
    />
  );
};
