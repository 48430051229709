import classNames from "classnames";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ImpactCategory } from "../../../domain/impactCategories";
import assertNever from "../../../util/assertNever";
import { LinkButton } from "../../utils/Button";
import InlineTitleToggle from "../../utils/InlineTitleToggle";
import { useImpactCategory } from "../ImpactCategoryProvider";

import "./ChartsContainer.css";

interface ChartsContainerControllerProps {
  handleChangeChart: (e: string) => void;
  handleChangePeriod: (e: string) => void;
  selectedChart: string;
  availableCharts: string[];
  availablePeriods: { label: string; value: string }[];
  selectedPeriod: string;
}

const ChartsContainerController: React.FC<ChartsContainerControllerProps> = ({
  handleChangeChart,
  handleChangePeriod,
  selectedChart,
  availableCharts,
  availablePeriods,
  selectedPeriod,
}) => {
  const { impactCategory } = useImpactCategory();

  const intl = useIntl();

  const onChange = useCallback(
    (e) => {
      handleChangeChart(e);
    },
    [handleChangeChart]
  );

  const renderCharts = (chart: string) => {
    if (chart === "IMPACT_CATEGORY") {
      if (impactCategory === ImpactCategory.GHG) {
        return intl.formatMessage({
          defaultMessage: "GHG Emissions",
          id: "components/pages:GHGEmissionsReporting",
        });
      } else if (impactCategory === ImpactCategory.LAND_USE) {
        return intl.formatMessage({
          defaultMessage: "Land use",
          id: "domain/impactCategories:landUse",
        });
      } else if (impactCategory === ImpactCategory.WATER_USE) {
        return intl.formatMessage({
          defaultMessage: "Water use",
          id: "domain/impactCategories:waterUse",
        });
      } else {
        assertNever(impactCategory, "Unsupported impact category.");
      }
    } else if (chart === "RATING_DISTRIBUTION") {
      return "Rating Distribution";
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return intl.formatMessage({
        defaultMessage: "Water Use",
        id: "components/recipes/ImpactCategoryInlineTitleToggle:waterUse",
      });
    } else {
    }
  };

  const handlePeriodChange = useCallback(
    (period) => {
      handleChangePeriod(period);
    },
    [handleChangePeriod]
  );

  return (
    <div
      style={{
        display: "flex",
        height: "fit-content",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          textAlign: "center",
          height: "fit-content",
          alignItems: "center",
          gap: "8px",
          flexGrow: 1,
        }}
      >
        <InlineTitleToggle
          onChange={onChange}
          options={availableCharts}
          selectedOption={selectedChart}
          renderOption={renderCharts}
        />{" "}
        <h3 className="ChartsContainer_title_overtime mb-0">
          <FormattedMessage
            id="components/salesDashboard/chartsOverTime:title"
            defaultMessage="Over Time"
          />
        </h3>
      </div>
      <div className="ChartsContainer_period_options">
        {availablePeriods.map((period) => {
          return (
            <LinkButton
              key={period.value}
              className={classNames("ChartsContainer_period_option", {
                ChartsContainer_period_option_selected:
                  selectedPeriod === period.value,
              })}
              onClick={() => handlePeriodChange(period.value)}
            >
              {period.label}
            </LinkButton>
          );
        })}
      </div>
    </div>
  );
};

export default ChartsContainerController;
