import { useIntl } from "react-intl";

import { GREENCORE_ID } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import InlineTitleToggle from "../utils/InlineTitleToggle";

export enum Option {
  CATEGORY = "CATEGORY",
  CATEGORY_2 = "CATEGORY_2",
  CATEGORY_3 = "CATEGORY_3",
  FOOD_TYPE = "FOOD_TYPE",
  PROCURED_ITEM = "PROCURED_ITEM",
  SUPPLIER = "SUPPLIER",
  SITE = "SITE",
}

export type TableOption =
  | Option.CATEGORY
  | Option.CATEGORY_2
  | Option.CATEGORY_3
  | Option.PROCURED_ITEM
  | Option.SUPPLIER
  | Option.FOOD_TYPE
  | Option.SITE;

export const allTableOptions: Array<TableOption> = [
  Option.CATEGORY,
  Option.CATEGORY_2,
  Option.CATEGORY_3,
  Option.PROCURED_ITEM,
  Option.SUPPLIER,
  Option.FOOD_TYPE,
  Option.SITE,
];

interface TableOptionToggleProps {
  option: TableOption;
  options?: Array<TableOption>;
  onChange: (option: TableOption) => void;
}

export default function TableOptionToggle(props: TableOptionToggleProps) {
  let { option, options = allTableOptions, onChange } = props;

  const [organizationId] = useOrganizationId();
  if (organizationId !== GREENCORE_ID) {
    options = options.filter(
      (option) =>
        option !== Option.CATEGORY_2 &&
        option !== Option.CATEGORY_3 &&
        option !== Option.SITE
    );
  }

  const intl = useIntl();

  const renderOption = (option: TableOption) => {
    if (option === Option.CATEGORY) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/category",
        defaultMessage: "Category",
      });
    } else if (option === Option.CATEGORY_2) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/category2",
        defaultMessage: "Category 2",
      });
    } else if (option === Option.CATEGORY_3) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/category3",
        defaultMessage: "Category 3",
      });
    } else if (option === Option.FOOD_TYPE) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/foodType",
        defaultMessage: "Food Type",
      });
    } else if (option === Option.PROCURED_ITEM) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/procuredItem",
        defaultMessage: "Procured Item",
      });
    } else if (option === Option.SUPPLIER) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/supplier",
        defaultMessage: "Supplier",
      });
    } else if (option === Option.SITE) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/site",
        defaultMessage: "Site",
      });
    } else {
      assertNever(option, "Unsupported table type");
    }
  };

  return (
    <InlineTitleToggle<TableOption>
      options={options}
      onChange={onChange}
      selectedOption={option}
      renderOption={renderOption}
    />
  );
}
