import { useState, useEffect, useCallback, RefObject } from "react";

const useOverflowDetection = (ref: RefObject<HTMLElement>) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = useCallback(() => {
    if (ref.current) {
      return (
        ref.current.offsetHeight < ref.current.scrollHeight ||
        ref.current.offsetWidth < ref.current.scrollWidth
      );
    }
    return false;
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;

    setIsOverflow(checkOverflow());

    const handleResize = () => setIsOverflow(checkOverflow());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [checkOverflow, ref]);

  return isOverflow;
};

export default useOverflowDetection;
