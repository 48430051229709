import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Assessment,
  AssessmentSummary,
  Category,
  ProcuredItem,
  ReportTotals,
  Site,
  Supplier,
} from "../../data-store";
import useAssessmentSummary from "../../data-store/useAssessmentSummary";
import useCategories from "../../data-store/useCategories";
import useExportUrl from "../../data-store/useExportUrl";
import useFoodTypes from "../../data-store/useFoodstepsCategories";
import useProcuredItems from "../../data-store/useProcuredItems";
import useReportTotals from "../../data-store/useReportTotals";
import useSites from "../../data-store/useSites";
import useSuppliers from "../../data-store/useSuppliers";
import { ImpactCategory } from "../../domain/impactCategories";
import { useTracking } from "../../tracking";
import ProcurementFilterSelect from "../corporate-reporting-dashboards/ProcurementFilterSelect";
import Page from "../Page";
import { Scope3PageQueryParams } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { BreadcrumbPage } from "../utils/Breadcrumb";
import { PrimaryButton } from "../utils/Button";
import Select from "../utils/Select";
import { Download } from "../utils/Vectors";
import ReportContent from "./ReportContent";
import { TableOption, Option } from "./TableOptionToggle";
import "./ReportPage.css";
import "../Page.css";

interface ReportPageProps {
  assessments: Array<Assessment>;
  breadcrumb: BreadcrumbPage[];
  defaultAssessment: Assessment;
  impactCategory: ImpactCategory;
  queryParams: Scope3PageQueryParams;
  setQueryParams: (update: Partial<Scope3PageQueryParams>) => void;
  title: string;
}

export default function ReportPage(props: ReportPageProps) {
  const {
    assessments,
    breadcrumb,
    defaultAssessment,
    impactCategory,
    queryParams,
    setQueryParams,
    title,
  } = props;

  const tracking = {
    pageName: title,
    pageAttributes: {
      defaultAssessmentId: defaultAssessment?.id,
      defaultAssessmentName: defaultAssessment?.name,
    },
  };

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      {/*Content in its own component to avoid re-rendering the page tracking from all the queries*/}
      <ReportPageContent
        assessments={assessments}
        breadcrumb={breadcrumb}
        impactCategory={impactCategory}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        title={title}
      />
    </Page>
  );
}

interface ReportPageContentProps {
  assessments: Array<Assessment>;
  breadcrumb: BreadcrumbPage[];
  impactCategory: ImpactCategory;
  queryParams: Scope3PageQueryParams;
  setQueryParams: (update: Partial<Scope3PageQueryParams>) => void;
  title: string;
}

function ReportPageContent(props: ReportPageContentProps) {
  const {
    assessments,
    breadcrumb,
    impactCategory,
    queryParams,
    setQueryParams,
    title,
  } = props;

  const { trackAssessmentSelected, trackProcurementImpactsExported } =
    useTracking();

  const chosenAssessment = useMemo(
    () =>
      assessments.find(
        (assessment) => assessment.id === queryParams?.assessmentId
      ) || null,
    [assessments, queryParams?.assessmentId]
  );

  const [value, setValue] = useState({
    filterToSelectedCategories: [],
    filterToSelectedFoodTypes: [],
    filterToSelectedSuppliers: [],
    filterToSelectedSites: [],
  });
  const [queryString, setQueryString] = useState("");
  const assessmentId = queryParams.assessmentId;
  const [assessmentSummaryStatus] = useAssessmentSummary(
    assessmentId,
    queryString
  );
  const [reportTotalsStatus] = useReportTotals(assessmentId, queryString);
  const [categoriesStatus] = useCategories(assessmentId, queryString);
  // TODO: better to do all in 1 query
  const [exportUrlStatus] = useExportUrl(assessmentId);
  const [itemsStatus] = useProcuredItems({ assessmentId, queryString });
  const [foodTypesStatus] = useFoodTypes(assessmentId, queryString);
  const [suppliersStatus] = useSuppliers(assessmentId, queryString);
  const [sitesStatus] = useSites(assessmentId, queryString);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [tableOption, setTableOption] = useState<TableOption>(
    queryParams.viewHighestImpactItems ? Option.PROCURED_ITEM : Option.CATEGORY
  );

  const handleTableOption = useCallback((option) => {
    setTableOption(option);
  }, []);

  const onChange = (input: any) => {
    setValue(input);
  };

  useEffect(() => {
    const buildQueryString = (filters: Record<string, string[]>) => {
      filters = {
        ...filters,
        // The backend needs three separate lists of category ids, because it's possible that
        // e.g. the string "Beef" is both a category-2 and a category-3.
        // This was the easiest way to separate the lists to unblock me.  Feel free to change.
        // Example strings in filters.filterToSelectedCategories: "0/Beef", "0/Chicken", "1/UK-sourced", ...
        filterToSelectedCategories: filters.filterToSelectedCategories
          .filter((x) => x[0] === "0")
          .map((x) => x.slice(2)),
        filterToSelectedCategories2: filters.filterToSelectedCategories
          .filter((x) => x[0] === "1")
          .map((x) => x.slice(2)),
        filterToSelectedCategories3: filters.filterToSelectedCategories
          .filter((x) => x[0] === "2")
          .map((x) => x.slice(2)),
      };
      return Object.entries(filters)
        .filter(([, values]) => values.length > 0) // only include non-empty arrays
        .map(
          ([key, values]) =>
            `${encodeURIComponent(key)}=${values
              .map(encodeURIComponent)
              .join(",")}`
        )
        .join("&");
    };

    setQueryString(buildQueryString(value));
  }, [value]);

  return (
    <>
      <div className="StickyPageTitle ReportPage_Header">
        <Page.Title breadcrumb={breadcrumb} title={title} />
        <div className="ReportPage_Header_Controls">
          <Select<Assessment>
            className="ReportPage_AssessmentSelect"
            isClearable={false}
            onChange={(reportName) => {
              if (reportName !== null) {
                trackAssessmentSelected({
                  assessmentId: reportName.id,
                  assessmentName: reportName.name,
                  reportType: impactCategory,
                });
              }
              setQueryParams({ assessmentId: reportName?.id });
            }}
            optionKey={(reportName) => reportName.id}
            options={assessments}
            renderOption={(reportName) => reportName.name}
            dropdownArrow="upDown"
            value={chosenAssessment}
          />
          <div style={{ display: "flex", gap: 20 }}>
            <ProcurementFilterSelect
              containerStyle={{
                right: "0px",
                borderRadius: "8px",
              }}
              filterByParentCollections={false}
              showGeneralStatusSection
              showManageTagsButton
              setFilters={(
                filterToSelectedCategories: Array<string>,
                filterToSelectedFoodTypes: Array<string>,
                filterToSelectedSuppliers: Array<string>,
                filterToSelectedSites: Array<string>
              ) =>
                onChange({
                  filterToSelectedCategories,
                  filterToSelectedFoodTypes,
                  filterToSelectedSuppliers,
                  filterToSelectedSites,
                })
              }
              filters={{
                filterToSelectedCategories: value.filterToSelectedCategories,
                filterToSelectedFoodTypes: value.filterToSelectedFoodTypes,
                filterToSelectedSuppliers: value.filterToSelectedSuppliers,
                filterToSelectedSites: value.filterToSelectedSites,
              }}
              menuIsOpen={menuIsOpen}
              setMenuIsOpen={setMenuIsOpen}
              hideAttention
            />
            <StatusDisplay status={exportUrlStatus}>
              {(exportInfo) =>
                exportInfo !== null && (
                  <a
                    href={`${
                      (window as any).FOODSTEPS_PLATFORM_URI
                    }${exportInfo.downloadLink.substring(1)}`} // substring(1) to strip the extra '/'
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PrimaryButton
                      icon={<Download width={16} />}
                      onClick={() =>
                        trackProcurementImpactsExported({
                          assessmentId,
                          numItems: Number(exportInfo.numItems),
                        })
                      }
                    >
                      <FormattedMessage
                        id="components/scope-3/ReportPage:export"
                        defaultMessage="Export"
                      />
                    </PrimaryButton>
                  </a>
                )
              }
            </StatusDisplay>
          </div>
        </div>
      </div>
      <div className="ReportPage_ContentContainer">
        <StatusDisplay.Many<
          [
            AssessmentSummary | null,
            Array<Array<Category>> | null,
            Array<Category> | null,
            Array<ProcuredItem> | null,
            Array<Supplier> | null,
            Array<Site> | null,
            ReportTotals | null
          ]
        >
          statuses={[
            assessmentSummaryStatus,
            categoriesStatus,
            foodTypesStatus,
            itemsStatus,
            suppliersStatus,
            sitesStatus,
            reportTotalsStatus,
          ]}
        >
          {(
            assessmentSummary,
            categories,
            foodTypes,
            procuredItems,
            suppliers,
            sites,
            totals
          ) =>
            assessmentSummary !== null &&
            categories !== null &&
            foodTypes !== null &&
            procuredItems !== null &&
            suppliers !== null &&
            sites !== null &&
            totals !== null ? (
              <ReportContent
                assessmentSummary={assessmentSummary}
                categories={categories[0]}
                categories2={categories[1]}
                categories3={categories[2]}
                foodstepsCategories={foodTypes}
                impactCategory={impactCategory}
                procuredItems={procuredItems}
                suppliers={suppliers}
                sites={sites}
                tableOption={tableOption}
                handleTableOption={handleTableOption}
              />
            ) : (
              <FormattedMessage
                id="components/scope-3/ReportPage:noDataFound"
                defaultMessage="No report data found."
              />
            )
          }
        </StatusDisplay.Many>
      </div>
    </>
  );
}
