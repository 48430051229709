import { useCallbackOne } from "use-memo-one";

import { useDataStore } from ".";
import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";

export default function useAssessmentTotals(queryString?: string) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportAssessmentTotals(
      organizationId,
      queryString
    );
  }, [dataStore, organizationId, queryString]);

  return usePromise(load);
}
