import ChevronButton from "./ChevronButton";

const stories = {
  name: "ChevronButton",
  children: [
    {
      name: "Back",
      render: () => (
        <ChevronButton direction="back" onClick={() => alert("Back")} />
      ),
    },
    {
      name: "Forward",
      render: () => (
        <ChevronButton direction="forward" onClick={() => alert("Forward")} />
      ),
    },
  ],
};

export default stories;
