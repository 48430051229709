import classNames from "classnames";
import { IntlShape } from "react-intl";

import assertNever from "../../util/assertNever";

import "./PercentageLozenge.css";

type PercentageLozengeProps = {
  amount: number;
  intl: IntlShape;
  sentiment: "positive" | "negative" | "neutral";
};

export default function PercentageLozenge(props: PercentageLozengeProps) {
  // https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=3272-4108&node-type=frame&t=0a2g6ccv6h71tNl3-0
  const { amount, intl, sentiment } = props;

  let fill: string;

  // TODO: use variables for colour
  if (sentiment === "positive") {
    fill = "rgba(66, 190, 115, 1)";
  } else if (sentiment === "negative") {
    fill = "rgba(244, 67, 54, 1)";
  } else if (sentiment === "neutral") {
    fill = "rgba(0, 166, 143, 1)";
  } else {
    assertNever(sentiment, "Invalid sentiment");
  }

  const percentChange = intl.formatNumber(amount, {
    style: "percent",
    signDisplay: "exceptZero",
    maximumFractionDigits: 1,
  });

  function isPercentageZero(percentageString: string) {
    const numberPart = parseFloat(percentageString.replace("%", ""));
    return numberPart === 0;
  }

  return (
    <span
      className={classNames(
        "small-copy-medium",
        "percentage-lozenge",
        sentiment
      )}
    >
      {isPercentageZero(percentChange) ? null : (
        <svg
          className="percentage-lozenge__icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: amount >= 0 ? "rotate(180deg)" : "rotate(0)" }}
        >
          <path
            d="M8 3V12"
            stroke={fill}
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M3.5 9L8 13L12.5 9"
            stroke={fill}
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      {intl.formatMessage(
        {
          id: "components/utils/PercentageLozenge:amount",
          defaultMessage: "{percentChange}",
        },
        {
          percentChange,
        }
      )}
    </span>
  );
}
