import { FormattedMessage, useIntl } from "react-intl";

import { Site } from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { Table, TableColumn } from "../utils/Table";
import {
  impactMagnitude,
  impactPerKg,
  intlFormatNumber,
} from "./helperFunctions";

interface SiteTableProps {
  data: Array<Site>;
  impactCategory: ImpactCategory;
}

export default function SiteTable(props: SiteTableProps) {
  const { data, impactCategory } = props;

  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);

  const columns: Array<TableColumn<Site>> = [
    {
      key: "site",
      label: intl.formatMessage({
        id: "components/scope-3/SitesTable:site",
        defaultMessage: "Site",
      }),
      renderCell: (site) =>
        site.name ?? (
          <FormattedMessage
            id="components/scope-3/SitesTable:unspecifiedSite"
            defaultMessage="Unspecified site"
          />
        ),
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "spend",
      label: intl.formatMessage({
        id: "components/scope-3/SitesTable:spend",
        defaultMessage: "Spend",
      }),
      units: "£",
      renderCell: (site) => intlFormatNumber(intl, Number(site.spend)),
      sortComparator: comparators.map(
        (item) => parseFloat(item.spend),
        comparators.number
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/SitesTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (site) => intlFormatNumber(intl, site.massKg),
      sortComparator: comparators.map(
        (item) => item.massKg,
        comparators.number
      ),
    },
    {
      key: "impact",
      label: effectType.title(intl),
      units: effectType.unit(intl),
      renderCell: (site) =>
        intlFormatNumber(intl, impactMagnitude(site, impactCategory)),
      sortComparator: comparators.map(
        (site) => impactMagnitude(site, impactCategory),
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "impact-per-kg",
      label: effectTypePerKg.title(intl),
      units: effectTypePerKg.unit(intl),
      renderCell: (site) =>
        intlFormatNumber(intl, impactPerKg(site, impactCategory, site.massKg)),
      sortComparator: comparators.map(
        (site) => impactPerKg(site, impactCategory, site.massKg),
        comparators.nullsLast(comparators.number)
      ),
    },
  ];

  return (
    <Table<Site>
      fullWidth
      columns={columns}
      rowKey={(item) => item.name}
      rows={data}
    />
  );
}
