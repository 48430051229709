import React from "react";

import { Panel } from "../utils/Panel";

interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
}

export function DashboardCard(props: DashboardCardProps) {
  const { children, className } = props;
  return <Panel className={className}>{children}</Panel>;
}
