import VectorProps from "./VectorProps";

export default function SalesDashboardIcon(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      width={width}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3883_2338)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.999951 0.200012C1.44178 0.200012 1.79995 0.558184 1.79995 1.00001V17.5C1.79995 17.8866 2.11335 18.2 2.49995 18.2H19C19.4418 18.2 19.7999 18.5582 19.7999 19C19.7999 19.4418 19.4418 19.8 19 19.8H2.49995C1.2297 19.8 0.199951 18.7703 0.199951 17.5V1.00001C0.199951 0.558184 0.558123 0.200012 0.999951 0.200012Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.434266 4.43433C0.746685 4.12191 1.25322 4.12191 1.56564 4.43433L5.99995 8.86864L9.59126 5.27733C10.0881 4.78049 10.8898 4.76834 11.4015 5.24991L19.5482 12.9175L18.4517 14.0826L10.5168 6.6145L6.91919 10.2121C6.41151 10.7198 5.5884 10.7198 5.08071 10.2121L0.434266 5.5657C0.121846 5.25328 0.121846 4.74675 0.434266 4.43433Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 8.70001C19.4418 8.70001 19.8 9.05818 19.8 9.50001V13.5C19.8 13.9418 19.4418 14.3 19 14.3H15C14.5581 14.3 14.2 13.9418 14.2 13.5C14.2 13.0582 14.5581 12.7 15 12.7H18.2V9.50001C18.2 9.05818 18.5581 8.70001 19 8.70001Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3883_2338">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
