import classNames from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Transition } from "react-transition-group";

import {
  UseCollections_AddCollection,
  UseCollections_AddCollectionVariables,
} from "../../data-store/useCollections.graphql";
import { useTracking } from "../../tracking";
import { MutationOptions } from "../graphql/useMutation";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import ActionModal from "../utils/ActionModal";
import { PrimaryButton } from "../utils/Button";
import Form from "../utils/Form";
import RowActions from "../utils/RowActions";
import ScrollTable, { Column } from "../utils/ScrollTable";
import * as TextField from "../utils/TextField";
import { Plus, Tag as TagIcon } from "../utils/Vectors";
import { ManageTagsModal_Collection as Collection } from "./ManageTagsModal.graphql";
import { Tag } from "./Tag";
import "./ManageTagsModal.css";

const transitionDuration = 300;

const newTagEditorTransitionDefaultStyle = {
  transition: `all ${transitionDuration}ms ease-in-out`,
  padding: 0,
};

const newTagEditorTransitionStyles = {
  entering: { height: "50%" },
  entered: { height: "108px" },
  exiting: { height: "50%" },
  exited: { height: 0, marginBottom: "-24px" },
  unmounted: { height: 0 },
};

interface ManageTagsModalProps {
  addTag: (
    options: MutationOptions<UseCollections_AddCollectionVariables>
  ) => Promise<UseCollections_AddCollection>;
  collections: Array<Collection>;
  onClose: () => void;
  onDeleteTag: (collection: Collection) => void;
  show: boolean;
  updateRecipeCollection: any;
}

export default function ManageTagsModal(props: ManageTagsModalProps) {
  const {
    addTag,
    collections,
    onClose,
    onDeleteTag,
    show,
    updateRecipeCollection,
  } = props;

  const { trackManageTagsCompleted, trackTagCreated, trackTagRenamed } =
    useTracking();

  const [showNewTagEditor, setShowNewTagEditor] = useState<boolean>(false);
  const [editingTagId, setEditingTagId] = useState<number | null>(null);

  const handleClose = () => {
    onClose();
    trackManageTagsCompleted();
  };

  const handleNewTagClicked = () => {
    setEditingTagId(null);
    setShowNewTagEditor(true);
  };

  const handleTagCreated = async ({
    tagName,
    tagId,
  }: {
    tagName: string;
    tagId: number;
  }) => {
    trackTagCreated({ tagName, tagId, workflow: "Manage Tags" });
    setShowNewTagEditor(false);
  };

  const handleClickEditTag = (collection: Collection) => {
    setShowNewTagEditor(false);
    setEditingTagId(collection.id);
  };

  const handleCancelEditTag = () => setEditingTagId(null);

  const handleTagUpdated = async ({
    newTagName,
    previousTagName,
    tagId,
  }: {
    newTagName: string;
    previousTagName: string;
    tagId: number;
  }) => {
    await updateRecipeCollection({
      variables: {
        input: {
          id: tagId,
          name: newTagName,
        },
      },
    });
    trackTagRenamed({ newTagName, previousTagName, tagId });
    setEditingTagId(null);
  };

  return (
    <ActionModal
      className="ManageTagsModal"
      onExited={onClose}
      show={show}
      title={
        <h2>
          <FormattedMessage
            id="components/tags/ManageTagsModal:title"
            defaultMessage="Manage Tags"
          />
        </h2>
      }
      size="xl"
      // TODO: Remove these two header attributes (and can likely delete the headerContent prop) when we
      //  add in the search bar, but these serve to reduce whitespace at the top in the meantime
      headerClassName="pb-4"
      headerContent={
        <div className="ManageTagsModal_Controls">
          <NewTagButton
            onClick={handleNewTagClicked}
            disabled={showNewTagEditor}
          />
        </div>
      }
    >
      <ActionModal.Body>
        <div className="ManageTagsModal_Body">
          <Transition in={showNewTagEditor} timeout={0}>
            {(state) => (
              <div
                style={{
                  ...newTagEditorTransitionDefaultStyle,
                  ...newTagEditorTransitionStyles[state],
                  zIndex: 0, // send to back so that it doesn't overlap
                }}
              >
                <NewTagEditor
                  addTag={addTag}
                  onCancel={() => setShowNewTagEditor(false)}
                  onComplete={handleTagCreated}
                />
              </div>
            )}
          </Transition>
          <TagsTable
            collections={collections}
            editingTagId={editingTagId}
            emptyState={
              !showNewTagEditor && (
                <EmptyState onNewTagClicked={handleNewTagClicked} />
              )
            }
            onCancelEditTag={handleCancelEditTag}
            onClickEditTag={handleClickEditTag}
            onDeleteTag={onDeleteTag}
            onSaveTag={handleTagUpdated}
          />
        </div>
      </ActionModal.Body>
      <ActionModal.Footer>
        <PrimaryButton
          className="ManageTagsModal_PrimaryButton"
          onClick={handleClose}
        >
          <FormattedMessage
            id="components/tags/ManageTagsModal:done"
            defaultMessage="Done"
          />
        </PrimaryButton>
      </ActionModal.Footer>
    </ActionModal>
  );
}

interface TagsTableProps {
  collections: Array<Collection>;
  editingTagId: number | null;
  emptyState: React.ReactNode;
  onCancelEditTag: () => void;
  onClickEditTag: (collection: Collection) => void;
  onDeleteTag: (collection: Collection) => void;
  onSaveTag: ({
    newTagName,
    previousTagName,
    tagId,
  }: {
    newTagName: string;
    previousTagName: string;
    tagId: number;
  }) => Promise<void>;
}

function TagsTable(props: TagsTableProps) {
  const {
    collections,
    editingTagId,
    emptyState,
    onCancelEditTag,
    onClickEditTag,
    onDeleteTag,
    onSaveTag,
  } = props;

  const [hoverRow, setHoverRow] = useState<Collection | null>(null);

  const columns: Array<Column<Collection>> = [
    {
      key: "tagName",
      header: (
        <FormattedMessage
          id="components/tags/ManageTagsModal:tagNameColumnHeader"
          defaultMessage="{tagCount} tags"
          values={{ tagCount: collections.length }}
        />
      ),
      renderCell: (collection) => (
        <div className="ManageTagsModal__TagName">
          <Tag text={collection.name} />
          {editingTagId === collection.id ? (
            <ExistingTagEditor
              onCancel={onCancelEditTag}
              onComplete={onSaveTag}
              tag={collection}
            />
          ) : null}
        </div>
      ),
    },
    {
      key: "productCount",
      header: (
        <FormattedMessage
          id="components/tags/ManageTagsModal:productCountColumnHeader"
          defaultMessage="Products with tag"
        />
      ),
      renderCell: (collection) => (
        <div
          className={classNames("pt-1", {
            ManageTagsModal__RecipeCount: editingTagId === collection.id,
          })}
        >
          {collection.recipeCount}
        </div>
      ),
      align: "center",
    },
    {
      key: "delete",
      header: null,
      renderCell: (collection) => (
        <RowActions<Collection>
          editingRow={editingTagId === collection.id}
          hoverRow={hoverRow}
          onClickEditRow={onClickEditTag}
          onDelete={onDeleteTag}
          row={collection}
        />
      ),
      align: "right",
      width: "135px",
    },
  ];

  return (
    <ScrollTable<Collection>
      emptyState={emptyState}
      columns={columns}
      rows={collections}
      rowKey={(collection) => collection.id}
      hoverRow={hoverRow}
      setHoverRow={setHoverRow}
    />
  );
}

interface EmptyStateProps {
  onNewTagClicked: () => void;
}

function EmptyState(props: EmptyStateProps) {
  const { onNewTagClicked } = props;
  return (
    <div className="ManageTagsModal_EmptyState_Container">
      <div className="ManageTagsModal_EmptyState_TextContainer">
        <span className="ManageTagsModal_EmptyState_Heading">
          <TagIcon width={20} />
          <FormattedMessage
            id="components/tags/ManageTagsModal:emptyStateHeading"
            defaultMessage="Create your first tag"
          />
        </span>
        <div className="ManageTagsModal_EmptyState_Instructions">
          <FormattedMessage
            id="components/tags/ManageTagsModal:emptyStateInstructions"
            defaultMessage="Use tags to categorise your products"
          />
        </div>
      </div>
      <NewTagButton disabled={false} onClick={onNewTagClicked} />
    </div>
  );
}

interface NewTagButtonProps {
  disabled: boolean;
  onClick: () => void;
}

function NewTagButton(props: NewTagButtonProps) {
  const { disabled, onClick } = props;

  return (
    <PrimaryButton
      className="ManageTagsModal_PrimaryButton"
      disabled={disabled}
      onClick={onClick}
      icon={<Plus width={20} />}
    >
      <FormattedMessage
        id="components/tags/ManageTagsModal:newTag"
        defaultMessage="New tag"
      />
    </PrimaryButton>
  );
}

interface TagEditorProps {
  className: string;
  loadingLabel: React.ReactNode;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  placeholder?: string;
  setTagNameValue: (value: TextField.Value) => void;
  submitLabel: React.ReactNode;
  tagNameValue: TextField.Value;
}

function TagEditor(props: TagEditorProps) {
  const {
    className,
    loadingLabel,
    onCancel,
    onSubmit,
    placeholder = "",
    setTagNameValue,
    submitLabel,
    tagNameValue,
  } = props;

  const valid = !TextField.read(tagNameValue).hasError;

  return (
    <Form className={className} onSubmit={onSubmit}>
      <div className="ManageTagsModal_TagEditor_TagNameField">
        <label htmlFor="tagName" className="medium-font mb-0">
          <FormattedMessage
            id="components/tags/ManageTagsModal:TagEditor/tagName"
            defaultMessage="Tag name"
          />
        </label>
        <div className="ManageTagsModal_TagEditor_Form">
          <TextField.TextField
            id="tagName"
            className="ManageTagsModal_TagEditor_TextField"
            onChange={setTagNameValue}
            value={tagNameValue}
            placeholder={placeholder}
            autoFocus
          />
        </div>
      </div>
      <div className="ManageTagsModal_TagEditor_Buttons">
        <Form.SecondaryButton onClick={onCancel}>
          <FormattedMessage
            id="components/tags/ManageTagsModal:TagEditor/cancel"
            defaultMessage="Cancel"
          />
        </Form.SecondaryButton>
        <Form.SubmitButton
          disabled={!valid}
          submitLabel={submitLabel}
          loadingLabel={loadingLabel}
        />
      </div>
    </Form>
  );
}

interface NewTagEditorProps {
  addTag: (
    options: MutationOptions<UseCollections_AddCollectionVariables>
  ) => Promise<UseCollections_AddCollection>;
  onCancel: () => void;
  onComplete: ({
    tagName,
    tagId,
  }: {
    tagName: string;
    tagId: number;
  }) => Promise<void>;
}

function NewTagEditor(props: NewTagEditorProps) {
  const { onCancel, onComplete, addTag } = props;

  const [organizationId] = useOrganizationId();
  const intl = useIntl();

  const [tagNameValue, setTagNameValue] = useState<TextField.Value>(
    TextField.initialValue("")
  );

  const valid = !TextField.read(tagNameValue).hasError;

  const handleSubmit = async () => {
    if (valid) {
      const newCollection = await addTag({
        variables: {
          input: {
            name: tagNameValue.text,
            ownerOrganizationId: organizationId,
          },
        },
      });
      await onComplete({
        tagName: newCollection.addRecipeCollection.recipeCollection.name,
        tagId: newCollection.addRecipeCollection.recipeCollection.id,
      });
      setTagNameValue(TextField.initialValue(""));
    }
  };

  return (
    <TagEditor
      className="ManageTagsModal_NewTagEditor"
      loadingLabel={
        <FormattedMessage
          id="components/tags/ManageTagsModal:NewTagEditor/creatingTag"
          defaultMessage="Creating tag"
        />
      }
      onCancel={onCancel}
      onSubmit={handleSubmit}
      placeholder={intl.formatMessage({
        id: "components/tags/ManageTagsModal:NewTagEditor/placeholder",
        defaultMessage: "e.g. Finger food",
      })}
      setTagNameValue={(value) => setTagNameValue(value)}
      submitLabel={
        <FormattedMessage
          id="components/tags/ManageTagsModal:NewTagEditor/createTag"
          defaultMessage="Create tag"
        />
      }
      tagNameValue={tagNameValue}
    />
  );
}

interface ExistingTagEditorProps {
  onCancel: () => void;
  onComplete: ({
    newTagName,
    previousTagName,
    tagId,
  }: {
    newTagName: string;
    previousTagName: string;
    tagId: number;
  }) => Promise<void>;
  tag: Collection;
}

function ExistingTagEditor(props: ExistingTagEditorProps) {
  const { onCancel, onComplete, tag } = props;

  const [tagNameValue, setTagNameValue] = useState<TextField.Value>(
    TextField.initialValue(tag.name)
  );

  const valid = !TextField.read(tagNameValue).hasError;

  const previousTagName = tag.name;

  const handleSubmit = async () => {
    if (valid) {
      await onComplete({
        newTagName: tagNameValue.text,
        previousTagName,
        tagId: tag.id,
      });
      setTagNameValue(TextField.initialValue(""));
    }
  };

  return (
    <TagEditor
      className="ManageTagsModal_ExistingTagEditor"
      loadingLabel={
        <FormattedMessage
          id="components/tags/ManageTagsModal:ExistingTagEditor/savingTag"
          defaultMessage="Saving"
        />
      }
      onCancel={onCancel}
      onSubmit={handleSubmit}
      setTagNameValue={(value) => setTagNameValue(value)}
      submitLabel={
        <FormattedMessage
          id="components/tags/ManageTagsModal:ExistingTagEditor/saveTag"
          defaultMessage="Save"
        />
      }
      tagNameValue={tagNameValue}
    />
  );
}

ManageTagsModal.fragments = {
  collection: gql`
    fragment ManageTagsModal_Collection on RecipeCollection {
      id
      name
      recipeCount
    }
  `,
};
