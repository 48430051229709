import { EffectType, ImpactRating } from "../../__generated__/globalTypes";
import {
  AssessmentSummary,
  Category,
  ProcuredItem,
  Site,
  Supplier,
} from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import CategoriesTable from "./CategoriesTable";
import ImpactRatingProportionsChart from "./ImpactRatingProportionsChart";
import ProcuredItemsTable from "./ProcuredItemsTable";
import ReportContent from "./ReportContent";

const assessmentSummaryDummyData: AssessmentSummary = {
  ghg: { value: 16512, proportionalChange: 0.11 },
  waterUse: { value: 122934, proportionalChange: -0.22 },
  landUse: { value: 12234, proportionalChange: 0.33 },
  spend: { value: 100000, proportionalChange: null },
  ghgPerSpend: { value: 0.1, proportionalChange: 0.01 },
  landUsePerSpend: { value: 0.2, proportionalChange: 0.02 },
  waterUsePerSpend: { value: 3, proportionalChange: null },
  spendCurrency: "USD",
  ghgIntensity: { value: 4.44, proportionalChange: -0.12 },
  impactRatingGhg: ImpactRating.VHIGH,
  landUseIntensity: { value: 5.55, proportionalChange: 0.12 },
  impactRatingLandUse: ImpactRating.MEDIUM,
  impactRatingWaterUse: ImpactRating.LOW,
  impactRatingProportions: {
    ghg: {
      veryLow: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.1, proportionalChange: 0.01 },
      },
      low: {
        itemCount: 2,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.02 },
      },
      medium: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.3, proportionalChange: 0.03 },
      },
      high: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.3, proportionalChange: 0.03 },
      },
      veryHigh: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.1, proportionalChange: 0.01 },
      },
    },
    landUse: {
      veryLow: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.01 },
      },
      low: {
        itemCount: 2,
        valueWithProportionalChange: { value: 0.1, proportionalChange: 0.02 },
      },
      medium: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.03 },
      },
      high: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.03 },
      },
      veryHigh: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.3, proportionalChange: 0.01 },
      },
    },
    waterUse: {
      veryLow: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.3, proportionalChange: 0.01 },
      },
      low: {
        itemCount: 2,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.02 },
      },
      medium: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.1, proportionalChange: 0.03 },
      },
      high: {
        itemCount: 3,
        valueWithProportionalChange: { value: 0.1, proportionalChange: 0.03 },
      },
      veryHigh: {
        itemCount: 1,
        valueWithProportionalChange: { value: 0.2, proportionalChange: 0.01 },
      },
    },
  },
  waterUseIntensity: { value: 6.66, proportionalChange: 0.22 },
  comparisonAssessmentName: "Greenest Assessment",
  procuredItemsCount: { value: 100, proportionalChange: 0.1 },
  totalMass: { value: 1000, proportionalChange: 0.2 },
};

const categoriesDummyData: Array<Category> = [...Array(100).keys()].map((i) => {
  return {
    name: `Category ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const itemsDummyData: Array<ProcuredItem> = [...Array(100).keys()].map((i) => {
  return {
    categoryName: "Category",
    category2Name: "Category 2",
    category3Name: "Category 3",
    ghg: 1,
    impactRatingGhg: "VLOW",
    impactRatingLandUse: "MEDIUM",
    impactRatingWaterUse: "HIGH",
    landUse: 2,
    internalId: i.toString(),
    name: `Item ${i}`,
    massKg: 2,
    quantity: 3,
    site: "Site",
    spend: "4.50",
    supplier: "Supplier",
    foodType: "Food Type",
    unit: "kg",
    waterUse: 3,
  };
});

const suppliersDummyData: Array<Supplier> = [...Array(100).keys()].map((i) => {
  return {
    name: `Supplier ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const sitesDummyData: Array<Site> = [...Array(100).keys()].map((i) => {
  return {
    name: `Site ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const proportionsDummyData = {
  veryLow: { proportion: 0.1, productCount: 1 },
  low: { proportion: 0.2, productCount: 2 },
  medium: { proportion: 0.3, productCount: 3 },
  high: { proportion: 0.3, productCount: 3 },
  veryHigh: { proportion: 0.1, productCount: 1 },
};

const impactRatingInfos = {
  impactRatingInfos: [
    {
      effectType: EffectType.GHG_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 0.8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 1.2,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 1.6,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 2.0,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 2.4,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
    {
      effectType: EffectType.LAND_USE_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 0.8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 1.2,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 1.6,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 2.0,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 2.4,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
    {
      effectType: EffectType.WATER_USE_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 12,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 16,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 20,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 24,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
  ],
};

const stories = {
  name: "Reports",
  children: [
    {
      name: "ReportContent",
      render: () => (
        <ReportContent
          assessmentSummary={assessmentSummaryDummyData}
          impactCategory={ImpactCategory.GHG}
          categories={categoriesDummyData}
          categories2={categoriesDummyData}
          categories3={categoriesDummyData}
          foodstepsCategories={categoriesDummyData}
          procuredItems={itemsDummyData}
          suppliers={suppliersDummyData}
          sites={sitesDummyData}
          tableOption={"CATEGORY" as any}
          handleTableOption={(e: any) => {}}
        />
      ),
    },
    {
      name: "CategoriesTable",
      render: () => (
        <CategoriesTable
          data={categoriesDummyData}
          categoryColumnLabel={"Category"}
          impactCategory={ImpactCategory.GHG}
          unspecifiedCategoryLabel="Unspecified"
        />
      ),
    },
    {
      name: "ProcuredItemsTable",
      render: () => (
        <ProcuredItemsTable
          data={itemsDummyData}
          impactCategory={ImpactCategory.GHG}
        />
      ),
    },
    {
      name: "ImpactRatingProportionsChart - Products Dashboard",
      render: () => (
        <div className="m-4">
          <ImpactRatingProportionsChart
            dashboardType="product"
            impactCategory={ImpactCategory.GHG}
            impactRatingInfos={impactRatingInfos}
            proportions={proportionsDummyData}
          />
        </div>
      ),
    },
    {
      name: "ImpactRatingProportionsChart - Procurement Dashboard",
      render: () => (
        <div className="m-4">
          <ImpactRatingProportionsChart
            dashboardType="procurement"
            impactCategory={ImpactCategory.GHG}
            impactRatingInfos={impactRatingInfos}
            proportions={proportionsDummyData}
          />
        </div>
      ),
    },
  ],
};

export default stories;
