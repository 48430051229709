import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
} from "../../services/useOrganizationFeatures";
import OrganizationProvider from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import ImpactCategoryToggle from "../recipes/ImpactCategoryToggle";
import "./SalesDashboardPage.css";
import "../Page.css";
import ChartsContainer from "./ChartsContainer";
import {
  ImpactCategoryProvider,
  useImpactCategory,
} from "./ImpactCategoryProvider";

const tracking = { pageName: "salesDashboard" };

export default function SalesDashboardPage() {
  const pages = usePages();

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      <OrganizationProvider>
        <ImpactCategoryProvider>
          <div className="StickyPageTitle SalesDashboardPage_Header">
            <Page.Title
              breadcrumb={pages.SalesDashboard.breadcrumb()}
              title={pages.SalesDashboard.title}
              inlineComponentEnd={
                <div className="d-flex" style={{ gap: 16 }}>
                  <SalesDashboardPageControls />
                </div>
              }
              rightAlignEndComponent={true}
            />
          </div>
          <SalesDashboardPageContent />
        </ImpactCategoryProvider>
      </OrganizationProvider>
    </Page>
  );
}

export function SalesDashboardPageControls() {
  const { impactCategory, setImpactCategory } = useImpactCategory();

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();

  const impactCategoryToggle = additionalImpactCategories ? (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={availableImpactCategories}
      selectedImpactCategory={impactCategory}
    />
  ) : null;

  return (
    <div className="SalesDashboardPageControls">{impactCategoryToggle}</div>
  );
}

export function SalesDashboardPageContent() {
  return (
    <div className="SalesDashboardPageContent">
      <ChartsContainer />
    </div>
  );
}
