import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { Assessment, AssessmentTotals } from "../../data-store";
import useAssessmentTotals from "../../data-store/useAssessmentTotals";
import {
  EffectType,
  impactCategoryToEffectType,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import BarChart2 from "../graphs/BarChart2";
import { impactMagnitude } from "../scope-3/helperFunctions";
import StatusDisplay from "../StatusDisplay";
import PercentageLozenge from "../utils/PercentageLozenge";
import { DashboardCard } from "./DashboardCard";
import "./ImpactByAssessmentChartCard.css";

interface TooltipProps {
  percentChangeSincePreviousAssessment: number | null;
  itemsInAssessment: number;
  assessmentName: string;
  previousAssessmentName: string | null;
  impact: number;
  intl: IntlShape;
  effectType: EffectType;
}

function Tooltip(props: TooltipProps) {
  const {
    percentChangeSincePreviousAssessment,
    itemsInAssessment,
    assessmentName,
    previousAssessmentName,
    impact,
    effectType,
    intl,
  } = props;

  const itemCountString =
    itemsInAssessment === 1
      ? intl.formatMessage({
          id: "components/ImpactByAssessmentChartCard:procuredItem",
          defaultMessage: "procured item",
        })
      : intl.formatMessage({
          id: "components/ImpactByAssessmentChartCard:procuredItems",
          defaultMessage: "procured items",
        });
  return (
    <div className="ImpactByAssessmentChartCard_Tooltip">
      <div>
        <div className="body-copy-medium">{assessmentName}</div>
        <div className="ImpactByAssessmentChartCard_Tooltip_Row">
          <div className="Number">
            {intl.formatNumber(impact, { maximumFractionDigits: 0 })}
          </div>
          <div className="small-copy">{effectType.largeUnit(intl)}</div>
        </div>
        <div className="ImpactByAssessmentChartCard_Tooltip_Row">
          <div className="small-copy-medium">
            {intl.formatNumber(itemsInAssessment)}
          </div>
          <div className="small-graph-legends">{itemCountString}</div>
        </div>
      </div>
      {percentChangeSincePreviousAssessment !== null &&
        previousAssessmentName !== null && (
          <div className="ImpactByAssessmentChartCard_Tooltip_Comparison_Row">
            <PercentageLozenge
              amount={percentChangeSincePreviousAssessment}
              intl={intl}
              sentiment="neutral"
            />
            <span className="small-graph-legends">
              {intl.formatMessage(
                {
                  id: "components/ImpactByAssessmentChartCard:since",
                  defaultMessage: "Since {previousAssessmentName}",
                },
                { previousAssessmentName }
              )}
            </span>
          </div>
        )}
    </div>
  );
}

interface ImpactByAssessmentChartCardProps {
  sortedAssessments: Assessment[];
  impactCategory: ImpactCategory;
  assessmentIdToColourMap: Map<string, string>;
  filters?: {
    filterToSelectedCategories: string[];
    filterToSelectedFoodTypes: string[];
    filterToSelectedSuppliers: string[];
    filterToSelectedSites: string[];
  };
  queryString?: string;
}

export function ImpactByAssessmentChartCard(
  props: ImpactByAssessmentChartCardProps
) {
  const {
    sortedAssessments,
    assessmentIdToColourMap,
    impactCategory,
    queryString,
  } = props;
  const [assessmentTotalsStatus] = useAssessmentTotals(queryString);
  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);

  const impact = (assessmentTotals: AssessmentTotals): number | null => {
    const totalImpact = impactMagnitude(assessmentTotals, impactCategory);
    return totalImpact === null
      ? null
      : totalImpact / effectType.largeUnitConversionFactor;
  };

  const assessmentData = (allAssessmentTotals: Array<AssessmentTotals>) => {
    return allAssessmentTotals
      .filter((total) => assessmentIdToColourMap.has(total.assessmentId))
      .map(
        (
          assessmentTotals,
          currentAssessmentTotalIndex,
          allAssessmentTotals
        ) => {
          const currentAssessmentTotalImpact = impact(assessmentTotals)!;
          const previousAssessmentTotals =
            currentAssessmentTotalIndex < allAssessmentTotals.length - 1
              ? allAssessmentTotals[currentAssessmentTotalIndex + 1]
              : null;
          const previousAssessmentTotalsImpact = previousAssessmentTotals
            ? impact(previousAssessmentTotals)!
            : null;
          let percentChangeSincePreviousAssessment = null;
          if (
            previousAssessmentTotalsImpact !== null &&
            previousAssessmentTotalsImpact !== 0
          ) {
            percentChangeSincePreviousAssessment =
              currentAssessmentTotalImpact / previousAssessmentTotalsImpact - 1;
          }
          const currentAssessmentIndex = sortedAssessments.findIndex(
            (assessment) => assessment.id === assessmentTotals.assessmentId
          );
          const itemsInAssessment =
            sortedAssessments[currentAssessmentIndex].number_of_items_assessed;
          return {
            value: [currentAssessmentTotalImpact],
            color: assessmentIdToColourMap.get(assessmentTotals.assessmentId)!,
            id: assessmentTotals.assessmentId,
            label: assessmentTotals.name,
            // assessmentTotals.numItems varies with filter, but is null if no filter.
            itemsInAssessment: assessmentTotals.numItems ?? itemsInAssessment,
            percentChangeSincePreviousAssessment,
            previousAssessmentName: previousAssessmentTotals?.name ?? null,
          };
        }
      );
  };

  const dependentAxisLabel = `${effectType.title(intl)} ${effectType.largeUnit(
    intl
  )}`;

  return (
    <DashboardCard className="ImpactByAssessmentChartCard">
      <h3>
        <FormattedMessage
          id="components/corporate-reporting-dashboards/ImpactByAssessmentChartCard:title"
          defaultMessage="Total Impact by Assessment"
        />
      </h3>
      <StatusDisplay status={assessmentTotalsStatus}>
        {(assessmentTotals) => {
          const data = assessmentData(assessmentTotals);

          return (
            <BarChart2
              bars={data}
              tooltipExtraData={data}
              dependentAxisLabel={dependentAxisLabel}
              // The grace property does not seem to be working as expected, but this gives us the desired effect for now.
              // It seems that setting grace to anything below 16% results in a small amount of space between the
              // largest bar and the edge of the chart, while anything above 15% results in a larger space.
              // But the spaces do not change continuously as the percentage changes, only either side of 15%.
              grace={"16%"}
              horizontal
              height={170}
              maintainAspectRatio={false}
              customTooltip={(
                tooltip: { dataPoints: Array<any> },
                tooltipExtraData
              ) => {
                if (tooltip.dataPoints.length !== 1) {
                  throw Error(
                    `Got > 1 data point in tooltip. Expected 1, got ${tooltip.dataPoints.length}`
                  );
                }
                const dataPoint = tooltip.dataPoints[0];
                const {
                  label: tooltipLabel,
                  previousAssessmentName,
                  itemsInAssessment,
                  percentChangeSincePreviousAssessment,
                  value,
                } = tooltipExtraData![dataPoint.dataIndex];
                if (value.length !== 1) {
                  throw Error(
                    `Got > 1 value in tooltip. Expected 1, got ${value.length}`
                  );
                }
                if (dataPoint.label !== tooltipLabel) {
                  throw Error(
                    `Labels for tooltip do not match, assessment. Tooltip label: '${dataPoint.label}', chart label: '${tooltipLabel}'`
                  );
                }
                if (dataPoint.raw !== value[0]) {
                  throw Error(
                    `Impacts for tooltip do not match. Tooltip impact: '${dataPoint.raw}', chart impact: '${value[0]}'`
                  );
                }
                return (
                  <Tooltip
                    percentChangeSincePreviousAssessment={
                      percentChangeSincePreviousAssessment
                    }
                    itemsInAssessment={itemsInAssessment}
                    assessmentName={tooltipLabel}
                    previousAssessmentName={previousAssessmentName}
                    impact={value[0]}
                    intl={intl}
                    effectType={effectType}
                  />
                );
              }}
            />
          );
        }}
      </StatusDisplay>
    </DashboardCard>
  );
}
