import { useCallbackOne } from "use-memo-one";

import { useOrganization } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useSalesDashboardData(filterParams = "") {
  const dataStore = useDataStore();
  const [dashboardOrganization] = useOrganization();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchSalesDashboardData(
      dashboardOrganization.id,
      filterParams
    );
  }, [dataStore, dashboardOrganization, filterParams]);

  return usePromise(load);
}
