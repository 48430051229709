import { Carousel } from "./Carousel";
import SummaryCard from "./SummaryCard";

const items = [
  <SummaryCard>1</SummaryCard>,
  <SummaryCard>2</SummaryCard>,
  <SummaryCard>3</SummaryCard>,
  <SummaryCard>4</SummaryCard>,
  <SummaryCard>5</SummaryCard>,
  <SummaryCard>6</SummaryCard>,
];

const stories = {
  name: "Carousel",
  render: () => <Carousel items={items} />,
};

export default stories;
