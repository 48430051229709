import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";

import { useFlag } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import FilterButton from "../filter-dropdown/FilterButton";
import "./ProcurementFilterSelect.css";
import { SegmentedProductFilterMenuList } from "./SegmentProcurementFilterMenuList";

interface ProductFilterSelectProps {
  filterByParentCollections?: boolean;
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  setFilters: (
    filterToSelectedCategories: Array<string>,
    filterToSelectedFoodTypes: Array<string>,
    filterToSelectedSuppliers: Array<string>,
    filterToSelectedSites: Array<string>
  ) => void;
  filters?: {
    filterToSelectedCategories: Array<string>;
    filterToSelectedFoodTypes: Array<string>;
    filterToSelectedSuppliers: Array<string>;
    filterToSelectedSites: Array<string>;
  };
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
  containerStyle?: React.CSSProperties;
  hideAttention?: boolean;
}

const filtersToNumberOfFilters = (filters: {
  filterToSelectedCategories: Array<string>;
  filterToSelectedFoodTypes: Array<string>;
  filterToSelectedSuppliers: Array<string>;
  filterToSelectedSites: Array<string>;
}) => {
  // TODO: tidy when filterToSelectedCollectionIds isn't nullable
  let numberOfFilters = 0;

  numberOfFilters += filters.filterToSelectedCategories.length;
  numberOfFilters += filters.filterToSelectedFoodTypes.length;
  numberOfFilters += filters.filterToSelectedSuppliers.length;
  numberOfFilters += filters.filterToSelectedSites.length;

  return numberOfFilters;
};

export default function ProductFilterSelect(props: ProductFilterSelectProps) {
  const {
    filterByParentCollections,
    showGeneralStatusSection,
    showManageTagsButton,
    setFilters,
    filters,
    menuIsOpen,
    setMenuIsOpen,
    containerStyle,
    hideAttention,
  } = props;

  const [currentNumberOfFilters, setCurrentNumberOfFilters] = useState(
    filters ? filtersToNumberOfFilters(filters) : 0
  );

  useEffect(() => {
    setCurrentNumberOfFilters(filters ? filtersToNumberOfFilters(filters) : 0);
  }, [filters]);

  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    filters?.filterToSelectedCategories ?? []
  );

  const [selectedFoodTypes, setSelectedFoodTypes] = useState<Array<string>>(
    filters?.filterToSelectedFoodTypes ?? []
  );

  const [selectedSuppliers, setSelectedSuppliers] = useState<Array<string>>(
    filters?.filterToSelectedSuppliers ?? []
  );

  const [selectedSites, setSelectedSites] = useState<Array<string>>(
    filters?.filterToSelectedSites ?? []
  );

  const flag = useFlag();

  const productFilterSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        productFilterSelectRef.current &&
        !productFilterSelectRef.current.contains(event.target as Node) &&
        // stop modals triggered by ManageTagsButton from hiding when clicked
        // ActionModal adds this class to body when it's in use
        // https://getbootstrap.com/docs/4.0/components/modal/#usage
        // > "it also adds .modal-open to the <body>"
        !document.body.classList.contains("modal-open")
      ) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  const removeAppliedFiltersRef = React.createRef<HTMLDivElement>();

  const { trackFilterProductsStarted } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    if (
      removeAppliedFiltersRef.current == null ||
      (removeAppliedFiltersRef.current &&
        !removeAppliedFiltersRef.current.contains(e.target as Node))
      // todo: we should store filters and selected options together with some kind of reset state
      // for easier to read code
    ) {
      if (!menuIsOpen) {
        trackFilterProductsStarted();
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  };

  const onRemoveAppliedFiltersClick = () => {
    setFilters([], [], [], []);
  };

  useEffect(() => {
    if (!menuIsOpen) {
      // whenever the menu is closed,
      // sync the visible filter state in the menu to the currently applied filters
      setSelectedCategories(filters?.filterToSelectedCategories || []);
      setSelectedFoodTypes(filters?.filterToSelectedFoodTypes || []);
      setSelectedSuppliers(filters?.filterToSelectedSuppliers || []);
      setSelectedSites(filters?.filterToSelectedSites || []);
    }
  }, [menuIsOpen, filters]);

  if (!flag) return null;

  return (
    <div
      style={{ position: "relative" }}
      ref={productFilterSelectRef}
      className="react-select-container"
    >
      {menuIsOpen && (
        <SegmentedProductFilterMenuList
          containerStyle={containerStyle}
          filterByParentCollections={filterByParentCollections}
          showGeneralStatusSection={showGeneralStatusSection}
          showManageTagsButton={showManageTagsButton}
          setFilters={setFilters}
          onMenuClose={() => setMenuIsOpen(false)}
          hideAttention={hideAttention}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedFoodTypes={selectedFoodTypes}
          setSelectedFoodTypes={setSelectedFoodTypes}
          selectedSuppliers={selectedSuppliers}
          setSelectedSuppliers={setSelectedSuppliers}
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
        />
      )}
      <FilterButton
        currentNumberOfFilters={currentNumberOfFilters}
        handleClick={handleClick}
        menuIsOpen={menuIsOpen}
        onRemoveAppliedFiltersClick={onRemoveAppliedFiltersClick}
        removeAppliedFiltersRef={removeAppliedFiltersRef}
      />
    </div>
  );
}

ProductFilterSelect.fragments = {
  collection: gql`
    fragment ProductFilterSelect_Collection on RecipeCollection {
      id
      name
    }
  `,
};
