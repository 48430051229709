import classNames from "classnames";
import React, { forwardRef } from "react";
import "./Tag.css";

interface TagProps {
  text: React.ReactNode;
  textClassName?: string;
  containerClassName?: string;
  arrowClassName?: string;
}

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ text, textClassName, containerClassName, arrowClassName }, ref) => {
    return (
      <div className={classNames("TagContainer", containerClassName)}>
        <div
          ref={ref}
          className={classNames(
            "TagTextContainer",
            "small-copy",
            textClassName
          )}
        >
          {text}
        </div>
        <div className={classNames("TagArrow", arrowClassName)}></div>
      </div>
    );
  }
);
