import { useCallback, useRef, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { ImpactCategory } from "../../../domain/impactCategories";
import { highD, lowB, mediumC, veryHighE, veryLowA } from "../colors";
import AreaChartWithLineOverlay from "./AreaChart";

import "./AreaWrapper.css";

interface AreaWrapperProps {}

const data = [
  {
    temp: true,
    id: 0,
    label: "Category 0",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "transparent",
    borderColor: "transparent",
    fill: 0,
    pointRadius: 0,
  },
  {
    id: 1,
    label: "Category 1",
    data: [50, 50, 40, 45, 30, 35, 25, 30, 20, 25, 30],
    fill: 0,

    backgroundColor: veryLowA,
    borderColor: veryLowA,
    hoverBackgroundColor: veryLowA,
    pointBackgroundColor: veryLowA,
    pointBorderColor: "white",
    pointBorderWidth: 4,
    hoverBorderColor: veryLowA,
    pointHoverBorderColor: "white",
    pointHoverRadius: 6,
    pointRadius: 0,
  },
  {
    temp: true,
    id: 2,
    label: "Category 1-2",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    backgroundColor: veryLowA,
    borderColor: veryLowA,
    fill: 1,
    pointRadius: 0,
  },
  {
    id: 3,
    label: "Category 2",
    data: [50, 50, 90, 75, 60, 75, 95, 130, 120, 125, 130],
    fill: 1,

    backgroundColor: lowB,
    borderColor: lowB,
    hoverBackgroundColor: lowB,
    pointBackgroundColor: lowB,
    pointBorderColor: "white",
    pointBorderWidth: 4,
    hoverBorderColor: lowB,
    pointHoverBorderColor: "white",
    pointHoverRadius: 6,
    pointRadius: 0,
  },
  {
    temp: true,
    id: 4,
    label: "Category 2-1",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    backgroundColor: lowB,
    borderColor: lowB,
    fill: 3,
    pointRadius: 0,
  },
  {
    id: 5,
    label: "Category 3",
    data: [50, 50, 70, 75, 60, 65, 55, 50, 60, 70, 75],
    fill: 3,

    backgroundColor: mediumC,
    borderColor: mediumC,
    hoverBackgroundColor: mediumC,
    pointBackgroundColor: mediumC,
    pointBorderColor: "white",
    pointBorderWidth: 4,
    hoverBorderColor: mediumC,
    pointHoverBorderColor: "white",
    pointHoverRadius: 6,
    pointRadius: 0,
  },
  {
    temp: true,
    id: 6,
    label: "Category 3-1",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    backgroundColor: lowB,
    borderColor: lowB,
    fill: 5,
    pointRadius: 0,
  },
  {
    id: 7,
    label: "Category 4",
    data: [40, 80, 70, 75, 60, 65, 55, 50, 60, 70, 75],
    fill: 5,

    backgroundColor: highD,
    borderColor: highD,
    hoverBackgroundColor: highD,
    pointBackgroundColor: highD,
    pointBorderColor: "white",
    pointBorderWidth: 4,
    hoverBorderColor: highD,
    pointHoverBorderColor: "white",
    pointHoverRadius: 6,
    pointRadius: 0,
  },
  {
    temp: true,
    id: 8,
    label: "Category 4-1",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    backgroundColor: highD,
    borderColor: highD,
    pointRadius: 0,
    fill: 5,
  },

  {
    id: 9,
    label: "Category 5",
    data: [90, 90, 85, 80, 75, 70, 65, 60, 80, 85, 75],

    fill: 7,

    backgroundColor: veryHighE,
    borderColor: veryHighE,
    hoverBackgroundColor: veryHighE,
    pointBackgroundColor: veryHighE,
    pointBorderColor: "white",
    pointBorderWidth: 4,
    hoverBorderColor: veryHighE,
    pointHoverBorderColor: "white",
    pointHoverRadius: 6,
    pointRadius: 0,
    hitRadius: 20,
  },
];

export function AreaWrapper(props: AreaWrapperProps) {
  const intl = useIntl();

  const [selected, setSelected] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [xAxisMin, setXAxisMin] = useState(0);
  const chartRef = useRef(null);
  const [selectedLabelPos, setSelectedLabelPos] = useState({
    x: null,
    y: null,
  });

  const handleSelectedLabelIndex = useCallback(
    (selectedLabel: any, selectedPos: any) => {
      setSelected(
        selectedLabel == null ? selectedLabel : selectedLabel + xAxisMin
      );
      if (selectedLabel == null) return;
      const rect = (chartRef.current as any)?.getBoundingClientRect();
      setSelectedLabelPos({
        x: rect.x + selectedPos.x + 10,
        y: rect.y + 80,
      });
    },
    [xAxisMin]
  );

  return (
    <>
      <div className="AverageImpactIntensityByTag">
        <h4 className="m-0">
          <FormattedMessage
            id="components/AverageImpactIntensityByTag/title"
            defaultMessage="Average Impact Intensity by Tag"
          />
        </h4>
        {selected != null && selectedLabelPos.x && selectedLabelPos.y ? (
          <div
            style={{
              position: "absolute",
              left: selectedLabelPos.x,
              top: selectedLabelPos.y,
            }}
          >
            <Tooltip
              dataPoints={data
                .filter((el) => !el.temp)
                .map((el, id) => {
                  return {
                    value: el?.data?.[selected],
                    color: el.backgroundColor,
                  };
                })}
              impactCategory={ImpactCategory.GHG}
              intl={intl}
              isSelectedLabel={selected == null ? false : true}
            />
          </div>
        ) : null}
        <div style={{ height: 500, maxHeight: 500 }} ref={chartRef}>
          <AreaChartWithLineOverlay
            xAxisMin={xAxisMin}
            xAxisMax={12}
            labels={[
              "Dec",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ]}
            data={data}
            dependentAxisLabel="GHG Emissions (tonnes CO₂e)"
            height={400}
            maintainAspectRatio={false}
            handleSelectedLabelIndex={handleSelectedLabelIndex}
            customTooltip={(tooltip: any) => {
              let dataPoints: any;
              dataPoints = data
                .filter(
                  (el) => el.id === tooltip?.dataPoints?.[0]?.datasetIndex
                )
                .map((l) => ({
                  value: l?.data?.[tooltip?.dataPoints?.[0]?.dataIndex],
                  color: l.backgroundColor,
                }));
              return (
                <Tooltip
                  dataPoints={dataPoints}
                  impactCategory={ImpactCategory.GHG}
                  intl={intl}
                  isSelectedLabel={selected == null ? false : true}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

interface DataPoint {
  value: number;
  color: string;
}
interface TooltipProps {
  dataPoints: Array<DataPoint>;
  impactCategory: ImpactCategory;
  intl: IntlShape;
  isSelectedLabel?: boolean;
}

export function Tooltip(props: TooltipProps) {
  const { dataPoints } = props;
  if (!dataPoints.length) return null;

  return (
    <div
      style={{ background: "black", borderRadius: 10 }}
      className="ImpactByRating_Tooltip"
    >
      <div>
        <div className="ImpactByRating_Tooltip_date_title">Month, 2025</div>
        <div className="d-flex" style={{ gap: 4, alignItems: "baseline" }}>
          <div className="ImpactByRating_Tooltip_total">xxx.x</div>
          <div className="ImpactByRating_Tooltip_total_unit">tonnes CO2e</div>
        </div>

        <div className="d-flex" style={{ gap: 4, alignItems: "baseline" }}>
          <div className="ImpactByRating_Tooltip_sales">xxx.x</div>
          <div className="ImpactByRating_Tooltip_sales_unit">sales</div>
        </div>

        {dataPoints.map((datapoint, id) => {
          if (datapoint.value == null) return "";
          return (
            <div
              key={id}
              className="d-flex ImpactByRating_Tooltip_color_container"
            >
              <div
                className="ImpactByRating_Tooltip_color"
                style={{ background: datapoint.color }}
              ></div>
              <div className="ImpactByRating_Tooltip_color_Label_value">
                {datapoint?.value}
              </div>
              <div className="ImpactByRating_Tooltip_color_Label_unit">
                tonnes CO2e E - Very high
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
