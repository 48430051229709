import { FormattedMessage, useIntl } from "react-intl";

import { Category } from "../../data-store";
import useFoodTypes from "../../data-store/useFoodstepsCategories";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import BarChart2, { Bar } from "../graphs/BarChart2";
import { impactMagnitude } from "../scope-3/helperFunctions";
import StatusDisplay from "../StatusDisplay";
import { DashboardCard } from "./DashboardCard";

interface ImpactByFoodTypeProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
  filters?: {
    filterToSelectedCategories: string[];
    filterToSelectedFoodTypes: string[];
    filterToSelectedSuppliers: string[];
    filterToSelectedSites: string[];
  };
  queryString?: string;
}

export function ImpactByFoodType(props: ImpactByFoodTypeProps) {
  const { assessmentId, impactCategory, queryString } = props;

  const intl = useIntl();
  const [foodTypesStatus] = useFoodTypes(assessmentId, queryString);

  const effectType = impactCategoryToEffectType(impactCategory);
  const dependentAxisLabel = `${effectType.title(intl)} (${effectType.largeUnit(
    intl
  )})`;

  const bars = (foodTypes: Category[]): Array<Bar> => {
    return foodTypes.map((foodType) => {
      const foodTypeImpact = impactMagnitude(foodType, impactCategory);
      const convertedFoodTypeImpact =
        foodTypeImpact === null
          ? null
          : foodTypeImpact / effectType.largeUnitConversionFactor;
      return { label: foodType.name, value: [convertedFoodTypeImpact] };
    });
  };

  return (
    <DashboardCard className="ImpactByFoodTypeCard">
      <h4 className="m-0">
        <FormattedMessage
          id="components/corporate-reporting-dashboards/ImpactByFoodType:title"
          defaultMessage="Impact by Food Type"
        />
      </h4>
      <StatusDisplay status={foodTypesStatus}>
        {(foodTypes) =>
          foodTypes ? (
            <BarChart2
              bars={bars(foodTypes)}
              dependentAxisLabel={dependentAxisLabel}
              height={430}
              horizontal
              sorted
              maintainAspectRatio={false}
              truncateLabels={false}
            />
          ) : (
            <FormattedMessage
              id="components/corporate-reporting-dashboards/ImpactByFoodType:noData"
              defaultMessage="No data available"
            />
          )
        }
      </StatusDisplay>
    </DashboardCard>
  );
}
