import { isEqual } from "lodash";
import { IntlShape } from "react-intl";

import assertNever from "./assertNever";

const massUnits = ["g", "kg", "t", "Mt", "Gt"] as const;
const landUnits = ["m2", "km2"] as const;
const waterUnits = ["L", "kL", "ML"] as const;
const otherUnits = ["co2e", "year"] as const;
const perOptions = [
  "kg",
  "t",
  "serving",
  "product",
  "EUR",
  "GBP",
  "USD",
] as const;

export type MassUnit = (typeof massUnits)[number];
export type LandUnit = (typeof landUnits)[number];
export type WaterUnit = (typeof waterUnits)[number];
export type OtherUnit = (typeof otherUnits)[number];
export type PerOption = (typeof perOptions)[number];

const isMassUnit = (unit: string): unit is MassUnit =>
  massUnits.includes(unit as MassUnit);
const isLandUnit = (unit: string): unit is LandUnit =>
  landUnits.includes(unit as LandUnit);
const isWaterUnit = (unit: string): unit is WaterUnit =>
  waterUnits.includes(unit as WaterUnit);

export type ConvertableUnit = MassUnit | LandUnit | WaterUnit;
export type Unit = ConvertableUnit | OtherUnit;

export type UnitType = "mass" | "land" | "water";

const convertToKG = (value: number, unit: MassUnit) => {
  const conversions = {
    g: value / 1_000,
    kg: value,
    t: value * 1_000,
    Mt: value * 1_000_000,
    Gt: value * 1_000_000_000,
  };
  return conversions[unit];
};

const convertToM2 = (value: number, unit: LandUnit) => {
  const conversions = {
    m2: value,
    km2: value * 1_000_000,
  };
  return conversions[unit];
};

const convertToL = (value: number, unit: WaterUnit) => {
  const conversions = {
    L: value,
    kL: value * 1_000,
    ML: value * 1_000_000,
  };
  return conversions[unit];
};

type ConvertedValueAndUnit = {
  convertedValue: number;
  unit: ConvertableUnit;
};

// Determine appropriate unit based on value
const determineAppropriateUnit = (
  baseValue: number,
  unit: ConvertableUnit
): ConvertedValueAndUnit => {
  if (isMassUnit(unit)) {
    // Disabling automatic use of Mt and Gt for now
    // if (baseValue >= 1_000_000_000)
    //   return { convertedValue: baseValue / 1_000_000_000, unit: "Gt" };
    // if (baseValue >= 1_000_000)
    //   return { convertedValue: baseValue / 1_000_000, unit: "Mt" };
    if (baseValue >= 1_000)
      return { convertedValue: baseValue / 1_000, unit: "t" };
    if (baseValue < 1) return { convertedValue: baseValue * 1_000, unit: "g" };
    return { convertedValue: baseValue, unit: "kg" };
  } else if (isLandUnit(unit)) {
    if (baseValue >= 1_000_000)
      return { convertedValue: baseValue / 1_000_000, unit: "km2" };
    return { convertedValue: baseValue, unit: "m2" };
  } else if (isWaterUnit(unit)) {
    if (baseValue >= 1_000_000)
      return { convertedValue: baseValue / 1_000_000, unit: "ML" };
    if (baseValue >= 1_000)
      return { convertedValue: baseValue / 1_000, unit: "kL" };
    return { convertedValue: baseValue, unit: "L" };
  }
  assertNever(unit, `Invalid unit: ${unit}`);
};

export const convertUnits = (
  value: number,
  unitToConvert: ConvertableUnit,
  otherBaseUnits: Unit[] = [],
  internalPer: PerOption | null = null
) => {
  let converted: ConvertedValueAndUnit;
  if (isMassUnit(unitToConvert)) {
    const kgValue = convertToKG(value, unitToConvert);
    converted = determineAppropriateUnit(kgValue, "kg");
  } else if (isLandUnit(unitToConvert)) {
    const m2Value = convertToM2(value, unitToConvert);
    converted = determineAppropriateUnit(m2Value, "m2");
  } else if (isWaterUnit(unitToConvert)) {
    const lValue = convertToL(value, unitToConvert);
    converted = determineAppropriateUnit(lValue, "L");
  } else {
    assertNever(
      unitToConvert,
      `Invalid unit: ${unitToConvert}. Other base units: ${otherBaseUnits}, per: ${internalPer}`
    );
  }
  return {
    convertedValue: converted.convertedValue,
    baseUnits: [converted.unit, ...otherBaseUnits],
  };
};

export const convertUnitsForDisplay = (
  intl: IntlShape,
  value: number,
  unitToConvert: ConvertableUnit,
  otherBaseUnits: Unit[] = [],
  internalPer: PerOption | null = null,
  decimals: number = 2
) => {
  // note that some places use this code and some use the unit display code from frontend/src/domain/EffectType.tsx
  const converted = convertUnits(
    value,
    unitToConvert,
    otherBaseUnits,
    internalPer
  );
  return {
    convertedValue: intl.formatNumber(converted.convertedValue, {
      maximumFractionDigits: decimals,
    }),
    unitString: complexUnitToIntlString(intl, converted.baseUnits, internalPer),
  };
};

export const complexUnitToIntlString = (
  intl: IntlShape,
  baseUnits: Unit[],
  per: string | null = null
) => {
  if (per === null) {
    if (isEqual(baseUnits, ["g"])) {
      return intl.formatMessage({
        id: "util/units:g",
        defaultMessage: `g`,
      });
    }
    if (isEqual(baseUnits, ["kg"])) {
      return intl.formatMessage({
        id: "util/units:kg",
        defaultMessage: `kg`,
      });
    }
    if (isEqual(baseUnits, ["t"])) {
      return intl.formatMessage({
        id: "util/units:t",
        defaultMessage: `t`,
      });
    }
    if (isEqual(baseUnits, ["Mt"])) {
      return intl.formatMessage({
        id: "util/units:Mt",
        defaultMessage: `Mt`,
      });
    }
    if (isEqual(baseUnits, ["Gt"])) {
      return intl.formatMessage({
        id: "util/units:Gt",
        defaultMessage: `Gt`,
      });
    }
    if (isEqual(baseUnits, ["m2"])) {
      return intl.formatMessage({
        id: "util/units:m2",
        defaultMessage: `m²`,
      });
    }
    if (isEqual(baseUnits, ["km2"])) {
      return intl.formatMessage({
        id: "util/units:km2",
        defaultMessage: `km²`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L",
        defaultMessage: "L",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL",
        defaultMessage: "kL",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML",
        defaultMessage: "ML",
      });
    }
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2e",
        defaultMessage: `g CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e",
        defaultMessage: `kg CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2e",
        defaultMessage: `t CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2e",
        defaultMessage: `Mt CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2e",
        defaultMessage: `Gt CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year",
        defaultMessage: `m²year`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year",
        defaultMessage: `km²year`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L",
        defaultMessage: "L",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL",
        defaultMessage: "kL",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML",
        defaultMessage: "ML",
      });
    }
  }
  if (per === "kg") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2eperkg",
        defaultMessage: `g CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2eperkg",
        defaultMessage: `kg CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2eperkg",
        defaultMessage: `t CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2eperkg",
        defaultMessage: `Mt CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2eperkg",
        defaultMessage: `Gt CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperkg",
        defaultMessage: `m²year / kg`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperkg",
        defaultMessage: `km²year / kg`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperkg",
        defaultMessage: "L / kg",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperkg",
        defaultMessage: "kL / kg",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperkg",
        defaultMessage: "ML / kg",
      });
    }
  }
  if (per === "t") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2epert",
        defaultMessage: `g CO₂e / t`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2epert",
        defaultMessage: `kg CO₂e / t`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2epert",
        defaultMessage: `t CO₂e / t`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2epert",
        defaultMessage: `Mt CO₂e / t`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2epert",
        defaultMessage: `Gt CO₂e / t`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearpert",
        defaultMessage: `m²year / t`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearpert",
        defaultMessage: `km²year / t`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lpert",
        defaultMessage: "L / t",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLpert",
        defaultMessage: "kL / t",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLpert",
        defaultMessage: "ML / t",
      });
    }
  }
  if (per === "serving") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2esperserving",
        defaultMessage: `g CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2esperserving",
        defaultMessage: `kg CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2esperserving",
        defaultMessage: `t CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2esperserving",
        defaultMessage: `Mt CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2esperserving",
        defaultMessage: `Gt CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperserving",
        defaultMessage: `m²year per serving`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperserving",
        defaultMessage: `km²year per serving`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperserving",
        defaultMessage: "L per serving",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperserving",
        defaultMessage: "kL per serving",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperserving",
        defaultMessage: "ML per serving",
      });
    }
  }
  if (per === "product") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2eperproduct",
        defaultMessage: `g CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2eperproduct",
        defaultMessage: `kg CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2eperproduct",
        defaultMessage: `t CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2eperproduct",
        defaultMessage: `Mt CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2eperproduct",
        defaultMessage: `Gt CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperproduct",
        defaultMessage: `m²year per product`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperproduct",
        defaultMessage: `km²year per product`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperproduct",
        defaultMessage: "L per product",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperproduct",
        defaultMessage: "kL per product",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperproduct",
        defaultMessage: "ML per product",
      });
    }
  }
  if (per === "EUR") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2e€",
        defaultMessage: `g CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e€",
        defaultMessage: `kg CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2e€",
        defaultMessage: `t CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2e€",
        defaultMessage: `Mt CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2e€",
        defaultMessage: `Gt CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year€",
        defaultMessage: `m²year / €`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year€",
        defaultMessage: `km²year / €`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L€",
        defaultMessage: "L / €",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL€",
        defaultMessage: "kL / €",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML€",
        defaultMessage: "ML / €",
      });
    }
  }

  if (per === "GBP") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2e£",
        defaultMessage: `g CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e£",
        defaultMessage: `kg CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2e£",
        defaultMessage: `t CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2e£",
        defaultMessage: `Mt CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2e£",
        defaultMessage: `Gt CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year£",
        defaultMessage: `m²year / £`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year£",
        defaultMessage: `km²year / £`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L£",
        defaultMessage: "L / £",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL£",
        defaultMessage: "kL / £",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML£",
        defaultMessage: "ML / £",
      });
    }
  }

  if (per === "USD") {
    if (isEqual(baseUnits, ["g", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:gco2e$",
        defaultMessage: `g CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e$",
        defaultMessage: `kg CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["t", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:tco2e$",
        defaultMessage: `t CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["Mt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Mtco2e$",
        defaultMessage: `Mt CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["Gt", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Gtco2e$",
        defaultMessage: `Gt CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year$",
        defaultMessage: `m²year / $`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year$",
        defaultMessage: `km²year / $`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L$",
        defaultMessage: "L / $",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL$",
        defaultMessage: "kL / $",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML$",
        defaultMessage: "ML / $",
      });
    }
  }

  throw new Error(`Invalid unit combination: ${baseUnits}, ${per}`);
};
