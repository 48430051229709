import { ChartConfiguration, ChartData } from "chart.js";
import classNames from "classnames";

import Number from "../typography/Number";
import Spinner from "../utils/Spinner";
import { Dash, DecreaseTriangle, IncreaseTriangle } from "../utils/Vectors";
import Chart from "./Chart";
import { boneCol, foodstepsTurquoiseCol } from "./colors";
import "./ButterflyChart.css";

// The ratio of the axis length to maximum bar length, depending on whether there is a non-null comparison value.
const graceNullComparison = 2;
const graceNonNullComparison = 1.6;

export type ComparisonValue =
  | {
      type: "success";
      value: number;
    }
  | { type: "none" }
  | { type: "loading" }
  | { type: "pending" }
  | { type: "unavailable" };

interface ButterflyRow {
  value1: number | null;
  value2: ComparisonValue;
  key: React.ReactNode;
  pendingMessage: React.ReactNode;
}

interface ButterflyChartProps {
  data: Array<ButterflyRow>;
  header: {
    left: React.ReactNode;
    center: React.ReactNode;
    right: React.ReactNode;
  };
  unavailableValueMessage: string;
}

export default function ButterflyChart(props: ButterflyChartProps) {
  const { data, header, unavailableValueMessage } = props;

  const maxValue = (value1: number, value2: ComparisonValue) => {
    if (value2.type === "success" && value2.value !== null) {
      return Math.max(value1, value2.value);
    } else {
      return value1;
    }
  };

  const headerClassname = "h-100 d-flex align-items-center";

  const percentageDifference = (value1: number, value2: ComparisonValue) => {
    return (
      value2.type === "success" &&
      value2.value !== null &&
      value2.value !== 0 && (
        <PercentageDifference value1={value1} value2={value2.value} />
      )
    );
  };

  const centerContent = (key: React.ReactNode) => (
    <div className="h-100 d-flex align-items-center justify-content-center">
      {key}
    </div>
  );

  const leftContent = (value1: number | null, value2: ComparisonValue) => {
    if (value1 === null) {
      return (
        <div className="d-flex align-items-center justify-content-end h-100 w-100">
          {unavailableValueMessage}
        </div>
      );
    } else {
      return (
        <div className="ButterflyChart_LeftContentContainer">
          <div className="ButterflyChart_PercentageDifferenceContainer">
            {percentageDifference(value1, value2)}
          </div>
          <div className="ButterflyChart_BarContainer">
            <Bar
              value={value1}
              blank={false}
              maxValue={maxValue(value1, value2)}
              direction="left"
              grace={
                value2.type === "success"
                  ? graceNonNullComparison
                  : graceNullComparison
              }
            />
          </div>
        </div>
      );
    }
  };

  const rightContent = (row: ButterflyRow) => {
    if (row.value2.type === "loading") {
      return null;
    } else if (row.value2.type === "none") {
      return (
        <Bar
          value={row.value1 ?? 0}
          blank={true}
          maxValue={maxValue(row.value1 ?? 0, row.value2)}
          direction="right"
          grace={graceNullComparison}
        />
      );
    } else if (row.value2.type === "pending") {
      return (
        <div className="h-100 w-100 d-flex align-items-center">
          {row.pendingMessage}
        </div>
      );
    } else if (row.value2.type === "unavailable") {
      return (
        <div className="h-100 w-100 d-flex align-items-center">
          {unavailableValueMessage}
        </div>
      );
    } else {
      return (
        <Bar
          value={row.value2.value}
          blank={false}
          maxValue={maxValue(row.value1 ?? 0, row.value2)}
          direction="right"
          grace={graceNonNullComparison}
        />
      );
    }
  };

  const headerRow = (
    <ButterflyChartRow
      left={
        <div className="ButterflyChart_HeaderCellContainer ButterflyChart_HeaderCellBorderBottom">
          <div className={classNames(headerClassname, "justify-content-end")}>
            {header.left}
          </div>
        </div>
      }
      center={
        <div className="ButterflyChart_HeaderCellContainer">
          <div
            className={classNames(headerClassname, "justify-content-center")}
          >
            {header.center}
          </div>
        </div>
      }
      right={
        <div className="ButterflyChart_HeaderCellContainer ButterflyChart_HeaderCellBorderBottom">
          <div className={classNames(headerClassname, "justify-content-start")}>
            {header.right}
          </div>
        </div>
      }
    />
  );

  return (
    <>
      {headerRow}
      {data.map((row, index) => (
        <div className="ButterflyChart_RowContainer" key={index}>
          {row.value2.type === "loading" ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              {index === 0 && <Spinner />}
            </div>
          ) : (
            <ButterflyChartRow
              key={index}
              left={leftContent(row.value1, row.value2)}
              right={rightContent(row)}
              center={centerContent(row.key)}
            />
          )}
        </div>
      ))}
    </>
  );
}

interface ButterflyChartRowProps {
  right: React.ReactNode;
  center: React.ReactNode;
  left: React.ReactNode;
}

function ButterflyChartRow(props: ButterflyChartRowProps) {
  const { left, center, right } = props;

  return (
    <div className="ButterflyChartRow_Container">
      <div className="ButterflyChartRow_Left">{left}</div>
      <div className="ButterflyChartRow_Center">{center}</div>
      <div className="ButterflyChartRow_Right">{right}</div>
    </div>
  );
}

const config = (
  data: ChartData,
  blank: boolean,
  direction: "left" | "right",
  maxValue: number,
  grace: number
): ChartConfiguration => {
  return {
    type: "bar",
    data,
    options: {
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value: number) => value.toFixed(2),
          display: !blank,
          align: direction === "left" ? "start" : "end",
          anchor: direction === "left" ? "start" : "end",
        },
      },
      indexAxis: "y",
      scales: {
        y: {
          display: false,
        },
        x: {
          display: false,
          reverse: direction === "left",
          max: maxValue * grace,
        },
      },
    },
  };
};

interface BarProps {
  blank: boolean;
  grace: number;
  direction: "left" | "right";
  maxValue: number;
  value: number;
}

function Bar(props: BarProps) {
  const { blank, direction, grace, maxValue, value } = props;

  const color = blank ? boneCol : foodstepsTurquoiseCol;

  const data: ChartData = {
    labels: [value],
    datasets: [
      {
        data: [value],
        backgroundColor: color,
        hoverBackgroundColor: color,
        borderRadius: 8,
        borderWidth: 0,
      },
    ],
  };

  const maxBarValue = maxValue === 0 ? 1 : maxValue;

  return (
    <Chart
      chartConfig={config(data, blank, direction, maxBarValue, grace)}
    ></Chart>
  );
}

interface PercentageDifferenceProps {
  value1: number;
  value2: number;
}

function PercentageDifference(props: PercentageDifferenceProps) {
  const { value1, value2 } = props;

  const percentageDifference = ((value1 - value2) / value2) * 100;

  const icon = () => {
    if (percentageDifference > 0) {
      return <IncreaseTriangle width={16} fill="var(--danger)" />;
    } else if (percentageDifference < 0) {
      return <DecreaseTriangle width={16} fill="var(--success)" />;
    } else {
      return <Dash width={16} fill="black" />;
    }
  };

  const sign = () => {
    if (percentageDifference > 0) {
      return "+";
    } else if (percentageDifference < 0) {
      return "-";
    } else {
      return null;
    }
  };

  return (
    <div className="d-flex h-100 flex-row">
      <div className="my-auto mr-2">{icon()}</div>
      <Number className="my-auto">
        {sign()} {Math.abs(percentageDifference).toFixed(0)}%
      </Number>
    </div>
  );
}
