import { useEffect, useRef, useState } from "react";
import { useCallbackOne } from "use-memo-one";

import * as statuses from "../../util/statuses";
import useIsUnmounted from "./useIsUnmounted";

export default function usePromise<T>(
  fetch: () => Promise<T>
): [statuses.Status<T>, () => Promise<void>] {
  const loadIdRef = useRef(1);
  const isUnmounted = useIsUnmounted();

  const [status, setStatus] = useState<statuses.Status<T>>(statuses.loading);

  const updateStatus = useCallbackOne(
    (loadId: number, status: statuses.Status<T>) => {
      if (!isUnmounted.current && loadId === loadIdRef.current) {
        setStatus(status);
      }
    },
    [isUnmounted]
  );

  const load = useCallbackOne(async () => {
    loadIdRef.current++;
    setStatus(statuses.loading);
    const loadId = loadIdRef.current;
    try {
      const value = await fetch();
      updateStatus(loadId, statuses.success(value));
    } catch (error) {
      updateStatus(loadId, statuses.error(error));
    }
  }, [fetch, updateStatus]);

  useEffect(() => {
    load();
  }, [load]);

  return [status, load];
}
