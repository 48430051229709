import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DashboardProduct, ProductsDashboardData } from "../../data-store";
import useProductsDashboardData from "../../data-store/useProductsDashboardData";
import { ImpactCategory } from "../../domain/impactCategories";
import useImpactRatingInfos from "../../services/useImpactRatingInfos";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
  useIsHeadOffice,
  useProductTags,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import Page from "../Page";
import { usePages } from "../pages";
import ProductFilterSelect from "../product-filter/ProductFilterSelect";
import ImpactCategoryToggle from "../recipes/ImpactCategoryToggle";
import { ProportionsChartImpactRatingInfos } from "../scope-3/ImpactRatingProportionsChart";
import StatusDisplay from "../StatusDisplay";
import "./ProductsDashboardPage.css";
import "../Page.css";
import { Panel } from "../utils/Panel";
import { AverageImpactIntensityByTag } from "./AverageImpactIntensityByTag";
import { DashboardOrganizationFilter } from "./DashboardOrganizationFilter";
import { DashboardOrganizationProvider } from "./DashboardOrganizationProvider";
import HighestAndLowestImpactProducts from "./HighestAndLowestImpactProducts";
import ProductsByDietChart from "./ProductsByDietChart";
import { ProductsByImpactRating } from "./ProductsByImpactRating";
import ProductsDashboardSummary from "./ProductsDashboardSummary";

const tracking = { pageName: "productsDashboard" };

export default function ProductsDashboardPage() {
  const pages = usePages();
  const [queryParams, setQueryParams] = pages.RecipesListPage.useQueryParams();
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [value, setValue] = useState(
    queryParams ?? {
      filterToSelectedCollectionIds: [],
      filterToRequiresClientAttention: false,
      intersectCollections: false,
      filterToSelectedGhgImpactRatings: [],
      filterToSelectedLandUseImpactRatings: [],
      filterToSelectedWaterUseImpactRatings: [],
      filterToSelectedDiets: [],
    }
  );
  const [filterParams, setFilterParams] = useState("");

  const onChange = (input: any) => {
    setValue(input);
  };

  function convertFiltersToQueryParams(filters: any) {
    const params = new URLSearchParams();

    // Key mappings for renaming parameters
    const keyMappings: any = {
      filterToSelectedCollectionIds: "collections",
      filterToSelectedGhgImpactRatings: "ghg",
      filterToSelectedLandUseImpactRatings: "land_use",
      filterToSelectedWaterUseImpactRatings: "water_use",
      filterToSelectedDiets: "diets",
      filterToRequiresClientAttention: "attentions",
      intersectCollections: "intersect_collections",
    };

    Object.entries(filters).forEach(([key, value]: any) => {
      const mappedKey = keyMappings[key];

      if (Array.isArray(value) && value.length > 0) {
        // Convert non-empty arrays to comma-separated strings
        params.append(mappedKey, value.join(","));
      } else if (typeof value === "boolean" || typeof value === "number") {
        if (value !== false) {
          // Exclude `false` but include `true` and numbers
          params.append(mappedKey, value as any);
        }
      }
    });

    return params.toString();
  }

  useEffect(() => {
    const params = convertFiltersToQueryParams(value);
    setFilterParams(params);
    setQueryParams(value);
  }, [value, setQueryParams]);

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      <DashboardOrganizationProvider>
        <div className="StickyPageTitle ProductsDashboardPage_Header">
          <Page.Title
            breadcrumb={pages.Dashboard().breadcrumb()}
            title={pages.Dashboard().title}
            inlineComponentEnd={
              <div className="d-flex" style={{ gap: 16 }}>
                <ProductFilterSelect
                  containerStyle={{
                    right: "0px",
                    borderRadius: "8px",
                  }}
                  filterByParentCollections={false}
                  showGeneralStatusSection
                  showManageTagsButton
                  setFilters={(
                    filterToSelectedCollectionIds: Array<number>,
                    filterToRequiresClientAttention: boolean,
                    intersectCollections: boolean,
                    filterToSelectedGhgImpactRatings: Array<string>,
                    filterToSelectedLandUseImpactRatings: Array<string>,
                    filterToSelectedWaterUseImpactRatings: Array<string>,
                    filterToSelectedDiets: Array<string>
                  ) =>
                    onChange({
                      filterToSelectedCollectionIds,
                      filterToRequiresClientAttention,
                      intersectCollections,
                      filterToSelectedGhgImpactRatings,
                      filterToSelectedLandUseImpactRatings,
                      filterToSelectedWaterUseImpactRatings,
                      filterToSelectedDiets,
                    })
                  }
                  filters={{
                    filterToSelectedCollectionIds:
                      value.filterToSelectedCollectionIds,
                    filterToRequiresClientAttention:
                      value.filterToRequiresClientAttention,
                    intersectCollections: value.intersectCollections,
                    filterToSelectedGhgImpactRatings:
                      value.filterToSelectedGhgImpactRatings,
                    filterToSelectedLandUseImpactRatings:
                      value.filterToSelectedLandUseImpactRatings,
                    filterToSelectedWaterUseImpactRatings:
                      value.filterToSelectedWaterUseImpactRatings,
                    filterToSelectedDiets: value.filterToSelectedDiets,
                  }}
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                  hideAttention
                />
                <ProductsDashboardPageControls
                  impactCategory={impactCategory}
                  setImpactCategory={setImpactCategory}
                />
              </div>
            }
            rightAlignEndComponent={true}
          />
        </div>
        <ProductsDashboardPageContent
          impactCategory={impactCategory}
          filterParams={filterParams}
        />
      </DashboardOrganizationProvider>
    </Page>
  );
}

interface ProductsDashboardPageControlsProps {
  impactCategory: ImpactCategory;
  setImpactCategory: (impactCategory: ImpactCategory) => void;
}

export function ProductsDashboardPageControls(
  props: ProductsDashboardPageControlsProps
) {
  const { impactCategory, setImpactCategory } = props;

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const isHeadOffice = useIsHeadOffice();

  const impactCategoryToggle = additionalImpactCategories ? (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={availableImpactCategories}
      selectedImpactCategory={impactCategory}
    />
  ) : null;

  return (
    <div className="ProductsDashboardPageControls">
      {isHeadOffice && <DashboardOrganizationFilter />}
      {impactCategoryToggle}
    </div>
  );
}

interface ProductsDashboardPageContentProps {
  impactCategory: ImpactCategory;
  filterParams: string;
}

export function ProductsDashboardPageContent(
  props: ProductsDashboardPageContentProps
) {
  const { impactCategory } = props;
  const [dashboardDataStatus] = useProductsDashboardData(props.filterParams);
  const { status: impactRatingInfosStatus } = useImpactRatingInfos();
  const canSeeProductTags = useProductTags();

  return (
    <StatusDisplay.Many<
      [ProductsDashboardData | null, ProportionsChartImpactRatingInfos]
    >
      statuses={[dashboardDataStatus, impactRatingInfosStatus]}
    >
      {(dashboardData, impactRatingInfos) => {
        return dashboardData !== null ? (
          <div className="ProductsDashboardPageContent">
            <ProductsDashboardSummary
              impacts={dashboardData}
              impactCategory={impactCategory}
              impactRatingProportions={dashboardData.impactRatingProportions}
              productsCount={dashboardData.numberOfProducts}
              numChildOrganizations={
                dashboardData.numberOfDescendantOrganizations
              }
            />
            <div className="ProductsDashboardCharts">
              <div className="ProductsByRating_And_HighestAndLowestImpactProducts_Container">
                <Panel className="ProductsByRatingChart ProductsDashboardPage_Panel">
                  <ProductsByImpactRating
                    impactCategory={impactCategory}
                    impactRatingInfos={impactRatingInfos}
                    impactRatingProportions={
                      dashboardData.impactRatingProportions
                    }
                  />
                </Panel>
                <Panel className="HighestAndLowestImpactProducts ProductsDashboardPage_Panel">
                  <HighestAndLowestImpactProducts
                    highestImpactProduct={getHighestOrLowestImpactProduct(
                      dashboardData,
                      impactCategory,
                      "highest"
                    )}
                    impactCategory={impactCategory}
                    lowestImpactProduct={getHighestOrLowestImpactProduct(
                      dashboardData,
                      impactCategory,
                      "lowest"
                    )}
                  />
                </Panel>
              </div>
              <div className="ProductsByDietChart_Container">
                <Panel className="ProductsByDietChart ProductsDashboardPage_Panel">
                  <ProductsByDietChart
                    dietaryCategories={dashboardData.dietaryCategories}
                  />
                </Panel>
              </div>
            </div>
            {canSeeProductTags && (
              <Panel className="AverageImpactIntensityByTag ProductsDashboardPage_Panel">
                <AverageImpactIntensityByTag
                  impactCategory={impactCategory}
                  tags={dashboardData.tags}
                />
              </Panel>
            )}
          </div>
        ) : (
          <FormattedMessage
            id="components/dashboard/ProductsDashboardPage:noDataFound"
            defaultMessage="No product data found."
          />
        );
      }}
    </StatusDisplay.Many>
  );
}

function getHighestOrLowestImpactProduct(
  data: {
    highestImpactProductGhg: DashboardProduct | null;
    lowestImpactProductGhg: DashboardProduct | null;
    highestImpactProductLandUse: DashboardProduct | null;
    lowestImpactProductLandUse: DashboardProduct | null;
    highestImpactProductWaterUse: DashboardProduct | null;
    lowestImpactProductWaterUse: DashboardProduct | null;
  },
  impactCategory: ImpactCategory,
  highestOrLowest: "highest" | "lowest"
) {
  if (impactCategory === ImpactCategory.GHG) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductGhg;
      case "lowest":
        return data.lowestImpactProductGhg;
    }
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductLandUse;
      case "lowest":
        return data.lowestImpactProductLandUse;
    }
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductWaterUse;
      case "lowest":
        return data.lowestImpactProductWaterUse;
    }
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}
