import VectorProps from "../VectorProps";

export default function Scales(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=3271-4085&t=WM0R8OnbLXzIl4YD-0

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3435_2383)">
        <path
          d="M10.48 20.96C16.2679 20.96 20.96 16.2679 20.96 10.48C20.96 4.69206 16.2679 0 10.48 0C4.69206 0 0 4.69206 0 10.48C0 16.2679 4.69206 20.96 10.48 20.96Z"
          fill="#67DBC1"
        />
        <path d="M19.8846 12.24H12.2046V16.24H19.8846V12.24Z" fill="#10675B" />
        <path
          d="M19.8847 17.12H12.2047C11.7183 17.12 11.3247 16.7264 11.3247 16.24V12.24C11.3247 11.7536 11.7183 11.36 12.2047 11.36H19.8847C20.3711 11.36 20.7647 11.7536 20.7647 12.24V16.24C20.7647 16.7264 20.3711 17.12 19.8847 17.12ZM13.0847 15.36H19.0047V13.12H13.0847V15.36Z"
          fill="black"
        />
        <path
          d="M23.2785 11.648C24.8897 11.648 26.1953 10.3424 26.1953 8.73118C26.1953 7.11998 24.8897 5.81438 23.2785 5.81438C23.1025 5.81438 22.9313 5.83198 22.7633 5.86238C22.6097 4.39678 21.3697 3.25278 19.8625 3.25278C18.5617 3.25278 17.4609 4.10558 17.0849 5.28158C16.5937 4.90238 15.9793 4.67518 15.3105 4.67518C15.2625 4.67518 15.2161 4.67998 15.1681 4.68158C14.8785 3.37598 13.7153 2.39838 12.3217 2.39838C10.9281 2.39838 9.69928 3.43358 9.45448 4.79678C9.19048 4.71838 8.91208 4.67518 8.62408 4.67518C7.01288 4.67518 5.70728 5.98078 5.70728 7.59198C5.70728 9.20318 7.01288 10.5088 8.62408 10.5088"
          fill="#00A68F"
        />
        <path
          d="M23.2801 12.528C22.7937 12.528 22.4001 12.1344 22.4001 11.648C22.4001 11.1616 22.7937 10.768 23.2801 10.768C24.4033 10.768 25.3169 9.85439 25.3169 8.73119C25.3169 7.60799 24.4033 6.69439 23.2801 6.69439C23.1681 6.69439 23.0497 6.70559 22.9201 6.72799C22.6801 6.77119 22.4321 6.71199 22.2369 6.56479C22.0417 6.41759 21.9169 6.19679 21.8913 5.95359C21.7825 4.91519 20.9121 4.13279 19.8657 4.13279C18.9761 4.13279 18.1969 4.70239 17.9265 5.54879C17.8401 5.82239 17.6241 6.03519 17.3505 6.12159C17.0769 6.20639 16.7793 6.15359 16.5521 5.97919C16.1921 5.70239 15.7633 5.55519 15.3137 5.55519L15.2145 5.56159C14.7873 5.58399 14.4049 5.29119 14.3121 4.87359C14.1073 3.95039 13.2721 3.27999 12.3249 3.27999C11.3777 3.27999 10.4977 3.98399 10.3233 4.95359C10.2785 5.19999 10.1313 5.41599 9.91846 5.54719C9.70566 5.67839 9.44646 5.71199 9.20646 5.64159C9.01606 5.58559 8.82086 5.55679 8.62566 5.55679C7.50246 5.55679 6.58886 6.47039 6.58886 7.59359C6.58886 8.71679 7.50246 9.63039 8.62566 9.63039C9.11206 9.63039 9.50566 10.024 9.50566 10.5104C9.50566 10.9968 9.11206 11.3904 8.62566 11.3904C6.53286 11.3904 4.82886 9.68639 4.82886 7.59359C4.82886 5.50079 6.53126 3.79679 8.62566 3.79679C8.69926 3.79679 8.77126 3.79839 8.84326 3.80319C9.43366 2.44159 10.7841 1.51999 12.3249 1.51999C13.8657 1.51999 15.2273 2.45759 15.8161 3.82879C16.1233 3.86879 16.4209 3.94719 16.7073 4.06079C17.3969 3.02239 18.5697 2.37279 19.8657 2.37279C21.5233 2.37279 22.9457 3.43039 23.4593 4.93759C25.4705 5.03039 27.0769 6.69599 27.0769 8.72959C27.0769 10.7632 25.3745 12.5264 23.2801 12.5264V12.528Z"
          fill="black"
        />
        <path
          d="M27.0449 17.56C26.8497 16.7024 25.9713 16 25.0913 16H6.99851C6.11851 16 5.24011 16.7024 5.04491 17.56L2.30731 29.6384C2.11211 30.496 2.67371 31.1984 3.55371 31.1984H28.5361C29.4161 31.1984 29.9777 30.496 29.7825 29.6384L27.0449 17.56Z"
          fill="#10675B"
        />
        <path
          d="M28.536 32.08H3.55364C2.86564 32.08 2.24004 31.792 1.84004 31.2912C1.44004 30.7904 1.29764 30.1168 1.44964 29.4448L4.18724 17.3664C4.47204 16.1072 5.70724 15.12 6.99844 15.12H25.0912C26.3824 15.12 27.6176 16.1072 27.9024 17.3664L30.64 29.4448C30.792 30.1168 30.6496 30.7888 30.2496 31.2912C29.8496 31.7936 29.224 32.08 28.536 32.08ZM6.99844 16.88C6.52964 16.88 6.00644 17.2976 5.90244 17.7552L3.16484 29.8336C3.13124 29.9824 3.14884 30.1104 3.21444 30.192C3.28004 30.2736 3.40004 30.32 3.55364 30.32H28.536C28.688 30.32 28.8096 30.2752 28.8752 30.192C28.9408 30.1104 28.9584 29.9824 28.9248 29.8336L26.1872 17.7552C26.0832 17.2976 25.5616 16.88 25.0912 16.88H6.99844Z"
          fill="black"
        />
        <mask
          id="mask0_3435_2383"
          maskUnits="userSpaceOnUse"
          x="1"
          y="8"
          width="16"
          height="7"
        >
          <path
            d="M16.0703 8.056H1.83032V14.3936H16.0703V8.056Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3435_2383)">
          <path
            d="M3.5264 8.93602C2.7328 8.93602 2.40799 9.96162 3.06079 10.4112C5.56319 12.1328 9.656 13.512 16.0096 13.512C22.3632 13.512 28.1136 12.1696 29.992 10.3184C30.5056 9.81122 30.1376 8.93442 29.416 8.93442H3.5264V8.93602Z"
            fill="#DCFFD1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.45253 8.05747C1.86614 8.12027 1.20345 10.2005 2.56163 11.136L2.56199 11.1362C5.25271 12.9874 9.53606 14.392 16.0096 14.392C19.2436 14.392 22.3365 14.0508 24.8761 13.467C27.3656 12.8948 29.4843 12.0543 30.6097 10.9452L30.6103 10.9446C31.6968 9.87164 30.9057 8.05441 29.416 8.05441H3.52637C3.50151 8.05441 3.47688 8.05544 3.45253 8.05747ZM3.75015 9.81441C6.08569 11.3503 9.94391 12.632 16.0096 12.632C19.1292 12.632 22.0882 12.302 24.4818 11.7518C26.7909 11.221 28.4321 10.5165 29.2412 9.81441H3.75015Z"
            fill="black"
          />
        </g>
        <mask
          id="mask1_3435_2383"
          maskUnits="userSpaceOnUse"
          x="16"
          y="8"
          width="15"
          height="7"
        >
          <path
            d="M30.3103 8.056H16.0703V14.3936H30.3103V8.056Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_3435_2383)">
          <path
            d="M28.616 8.93602C29.4096 8.93602 29.7344 9.96162 29.0816 10.4112C26.5792 12.1328 22.4864 13.512 16.1328 13.512C9.77923 13.512 4.02883 12.1696 2.15043 10.3184C1.63683 9.81122 2.00483 8.93442 2.72643 8.93442H28.6176L28.616 8.93602Z"
            fill="#DCFFD1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.90105 9.81441C3.71017 10.5165 5.35142 11.221 7.66046 11.7518C10.0541 12.302 13.0131 12.632 16.1327 12.632C22.1984 12.632 26.0566 11.3503 28.3921 9.81441H2.90105ZM28.692 8.05756C30.2768 8.12225 30.9382 10.201 29.5807 11.136L29.5803 11.1362C26.8896 12.9874 22.6062 14.392 16.1327 14.392C12.8987 14.392 9.80577 14.0508 7.26617 13.467C4.77667 12.8948 2.65803 12.0543 1.53262 10.9452L1.53197 10.9446C0.445515 9.87163 1.23656 8.05441 2.72632 8.05441H28.6175C28.6425 8.05441 28.6674 8.05547 28.692 8.05756Z"
            fill="black"
          />
        </g>
        <path
          d="M25.7086 28.0448C25.4142 22.9632 21.2014 18.9328 16.0446 18.9328C10.8878 18.9328 6.67662 22.9632 6.38062 28.0448H25.7086Z"
          fill="#DCFFD1"
        />
        <path
          d="M25.7087 28.9248H6.38068C6.13908 28.9248 5.90708 28.8256 5.74068 28.648C5.57428 28.472 5.48788 28.2352 5.50228 27.9936C5.82548 22.4192 10.4559 18.0528 16.0447 18.0528C21.6335 18.0528 26.2639 22.4192 26.5871 27.9936C26.6015 28.2352 26.5151 28.472 26.3487 28.648C26.1823 28.824 25.9503 28.9248 25.7087 28.9248ZM7.36308 27.1648H24.7263C24.0335 22.9744 20.3855 19.8128 16.0447 19.8128C11.7039 19.8128 8.05748 22.9744 7.36308 27.1648Z"
          fill="black"
        />
        <path
          d="M15.9936 28.9248C15.6624 28.9248 15.3456 28.736 15.1952 28.416L12.5648 22.7792C12.36 22.3392 12.5504 21.8144 12.9904 21.6096C13.432 21.4048 13.9552 21.5952 14.16 22.0352L16.7904 27.672C16.9952 28.112 16.8048 28.6368 16.3648 28.8416C16.2448 28.8976 16.1184 28.9248 15.9936 28.9248Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
