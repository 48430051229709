import classNames from "classnames";
import { gql } from "graphql-tag";
import { ReactElement, useCallback, useMemo } from "react";
import { useState, useEffect, useRef } from "react";
import FlipMove from "react-flip-move";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useUserInfo from "../../data-store/useUserInfo";
import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import useOverflowDetection from "../../hooks/useOverflowDetection";
import { useLabels } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import { RecipeCollection } from "../dashboard/useDashboardPageData";
import { interfaceGrey, whiteCol } from "../graphs/colors";
import Label from "../labels/Label";
import { RecipesListPageQueryParams } from "../pages";
import { Tag } from "../tags/Tag";
import Card from "../utils/Card";
import Checkbox from "../utils/Checkbox";
import LoadMoreButton from "../utils/LoadMoreButton";
import Spinner from "../utils/Spinner";
import TooltipOverlay from "../utils/TooltipOverlay";
import useId from "../utils/useId";
import {
  AttentionTriangle,
  ErrorTriangle,
  ImpactUnavailableDash,
  Sharing,
} from "../utils/Vectors";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";
import * as RecipeStatus from "./RecipeStatus";
import { ResultsGrid_Recipe as Recipe } from "./ResultsGrid.graphql";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";
import "./ResultsGrid.css";

type RecipeImpact = Recipe["impact"];

interface TooltipTagProps {
  name: string;
  containerClassName?: string;
  textClassName?: string;
  arrowClass?: string;
}

const TooltipTag: React.FC<TooltipTagProps> = (props) => {
  const tagRef = useRef<HTMLDivElement>(null);
  const isOverflow = useOverflowDetection(tagRef);

  return isOverflow ? (
    <TooltipOverlay
      delay={100}
      spanStyle={{ maxWidth: "100%" }}
      id={props.name}
      overlay={props.name}
      placement="top"
    >
      <Tag
        ref={tagRef}
        containerClassName={props.containerClassName}
        textClassName={props.textClassName}
        arrowClassName={props.arrowClass}
        key={props.name}
        text={props.name}
      />
    </TooltipOverlay>
  ) : (
    <Tag
      ref={tagRef}
      containerClassName={props.containerClassName}
      textClassName={props.textClassName}
      arrowClassName={props.arrowClass}
      key={props.name}
      text={props.name}
    />
  );
};

interface TagsListProps {
  tags: RecipeCollection[];
  isSelected: boolean;
}

const TagsList: React.FC<TagsListProps> = (props) => {
  const { tags, isSelected } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState(tags);
  const [hiddenCount, setHiddenCount] = useState(0);

  const counterId = useId();

  useEffect(() => {
    const COUNTER_BUTTON = 32;

    const updateTagsVisibility = () => {
      const container = containerRef.current;
      if (!container) return;

      const maxWidth = container.offsetWidth * 2;
      let totalWidth = 0;
      let visible = [];
      let hidden = 0;

      for (let tag of tags) {
        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.visibility = "hidden";
        tempDiv.style.whiteSpace = "nowrap";
        tempDiv.style.fontSize = "12px";
        tempDiv.style.maxWidth = "150px";
        tempDiv.style.padding = "6px";
        tempDiv.style.paddingRight = "20px";
        tempDiv.innerText = tag.name;
        container.appendChild(tempDiv);

        if (
          tempDiv.offsetWidth + totalWidth > maxWidth / 2 &&
          totalWidth < maxWidth / 2
        ) {
          // add the new tag to the second line when it does not fit in the first line
          totalWidth = maxWidth / 2 + tempDiv.offsetWidth;
        } else {
          totalWidth += tempDiv.offsetWidth;
        }

        if (totalWidth <= maxWidth - COUNTER_BUTTON) {
          visible.push(tag);
        } else {
          hidden++;
        }

        if (container.contains(tempDiv)) {
          container.removeChild(tempDiv);
        }
      }

      setVisibleTags(visible);
      setHiddenCount(hidden);
    };

    updateTagsVisibility();

    window.addEventListener("resize", updateTagsVisibility);
    return () => window.removeEventListener("resize", updateTagsVisibility);
  }, [tags]);

  const containerClassName = useCallback(
    (indexId: number) => {
      return hiddenCount > 0 && visibleTags.length - 1 === indexId
        ? "TagContainer--limited"
        : "TagContainer--full";
    },
    [hiddenCount, visibleTags.length]
  );

  const textClassName = useMemo(
    () =>
      classNames("TagTextContainer", {
        "TagTextContainer--selected": isSelected,
      }),
    [isSelected]
  );

  const arrowClass = useMemo(
    () =>
      classNames("TagArrow", {
        "TagArrow--selected": isSelected,
      }),
    [isSelected]
  );

  const TagTextContainerCounter = useMemo(
    () =>
      classNames("TagTextContainerCounter", {
        "TagTextContainer--selected": isSelected,
      }),
    [isSelected]
  );

  return (
    <div
      ref={containerRef}
      className={classNames("d-flex", "flex-wrap", "TagsContainer")}
    >
      {visibleTags.map((tag, indexId: number) => (
        <TooltipTag
          name={tag.name}
          containerClassName={containerClassName(indexId)}
          arrowClass={arrowClass}
          textClassName={textClassName}
        />
      ))}
      {hiddenCount > 0 && (
        <TooltipOverlay
          delay={100}
          id={counterId}
          overlay={
            <div>
              {tags
                .slice(-1 * hiddenCount)
                .map((tag) => tag.name)
                .join(", ")}
            </div>
          }
          placement="top"
        >
          <Tag
            textClassName={TagTextContainerCounter}
            arrowClassName={arrowClass}
            text={`+${hiddenCount}`}
          />
        </TooltipOverlay>
      )}
    </div>
  );
};

interface GridItemProps {
  impactCategory: ImpactCategory;
  item: Recipe;
  onSelect: (id: number, newSelected: boolean) => void;
  queryParams: RecipesListPageQueryParams;
  recipeUrl: (recipe: Recipe) => string;
  selected: boolean;
  showSharedIcon: boolean;
  waitingForFreshImpacts: boolean;
}

function GridItem(props: GridItemProps) {
  const {
    impactCategory,
    item,
    onSelect,
    queryParams,
    recipeUrl,
    selected,
    showSharedIcon,
    waitingForFreshImpacts,
  } = props;

  const nameRef = useRef<HTMLDivElement>(null);
  const isOverflow = useOverflowDetection(nameRef);
  const intl = useIntl();
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);

  const handleSelect = (selected: boolean) => {
    onSelect(item.id, selected);
  };

  const getValue = () => {
    if (recipeImpactIsUnavailable(item.impact, impactCategory)) {
      return null;
    }
    return effectType.get({
      effects: item.impact.effects,
    });
  };

  const value = getValue();

  const isStale = item.impact.isStale;

  return (
    <Link
      className="d-block text-decoration-none h-100"
      to={RecipesListPageLocationState.queryParamsToLocationDescriptor(
        recipeUrl(item),
        queryParams
      )}
    >
      <Card
        className="grid-card result-grid"
        style={{ padding: 12 }}
        fullHeight
        selected={selected}
        styleCardOnHover={false}
        shadow={false}
      >
        <div className="d-flex flex-column h-100">
          <div style={{ alignItems: "center", gap: 2 }} className="d-flex mb-2">
            <Checkbox
              checked={selected}
              propagateOnClick={false}
              onChange={handleSelect}
            />
            {showSharedIcon && (
              <Sharing
                className="sharing-icon"
                fill={selected ? whiteCol : interfaceGrey}
                width={22}
                height={22}
              />
            )}
          </div>
          <div className="flex-grow-1 d-flex h-100 flex-column">
            <div className="d-flex mb-2 flex-row">
              {isOverflow ? (
                <TooltipOverlay
                  delay={100}
                  id={`${item.id}`}
                  overlay={item.name}
                  placement="top"
                >
                  <div ref={nameRef} className="medium-font truncate-text">
                    {item.name}
                  </div>
                </TooltipOverlay>
              ) : (
                <div ref={nameRef} className="medium-font truncate-text">
                  {item.name}
                </div>
              )}
            </div>
            <TagsList isSelected={selected} tags={[...item.collections]} />
          </div>
          <div
            className="d-flex"
            style={{
              opacity: isStale && waitingForFreshImpacts ? 0.5 : 1,
              position: "relative",
            }}
          >
            <div className="d-flex effect-container">
              <h3 className="effect-value">
                {value?.toFixed(effectType.decimals)}
              </h3>{" "}
              {value ? (
                <span className="effect-unit">{effectType.unit(intl)}</span>
              ) : null}
            </div>
            <div
              className={classNames("mt-auto", {
                ResultsGrid_StaleImpact: isStale,
              })}
            >
              <RecipeStatusDisplay
                impact={item.impact}
                impactCategory={impactCategory}
              />
            </div>
          </div>
          {isStale && waitingForFreshImpacts && (
            <div className="ResultsGrid_GridItem_SpinnerContainer">
              <Spinner className="ResultsGrid_GridItem_Spinner" />
            </div>
          )}
        </div>
      </Card>
    </Link>
  );
}

interface RecipeStatusDisplayProps {
  impact: RecipeImpact;
  impactCategory: ImpactCategory;
}

function RecipeStatusDisplay(
  props: RecipeStatusDisplayProps
): ReactElement | null {
  const { impact, impactCategory } = props;

  const status = RecipeStatus.useRecipeStatus(impact, impactCategory);
  const hasFeatureLabels = useLabels();
  const [{ locale }] = useUserInfo();

  const ICON_STYLE_SIZE = { width: 32, height: 32 };

  if (status.type === "internalError") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={() => ErrorTriangle(ICON_STYLE_SIZE)}
        paragraphClassName="recipe-message internal-error"
        text={
          <FormattedMessage
            defaultMessage="Unknown Error!"
            id="components/recipes/ResultsGrid:UnknownError"
          />
        }
      />
    );
  } else if (status.type === "requiresClientAttention") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={() => AttentionTriangle(ICON_STYLE_SIZE)}
        paragraphClassName="recipe-message needs-attention"
        text={
          <FormattedMessage
            defaultMessage="Needs attention!"
            id="components/recipes/ResultsGrid:NeedsAttention"
          />
        }
      />
    );
  } else if (status.type === "pending") {
    return (
      <RecipeStatusBaseDisplayNew
        paragraphClassName="recipe-message pending"
        text={
          <FormattedMessage
            defaultMessage="Impact pending..."
            id="components/recipes/ResultsGrid:ImpactPending"
          />
        }
      />
    );
  } else if (status.type === "noImpactRating") {
    return null;
  } else if (status.type === "hasImpactRating") {
    return hasFeatureLabels ? (
      <div className="ml-1">
        <Label
          colourSetting="colour"
          type="letterRating"
          impactCategory={impactCategory}
          impactRating={status.impactRating}
          width={46}
          locale={locale}
        />
      </div>
    ) : null;
  } else if (status.type === "unavailable") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={() => ImpactUnavailableDash(ICON_STYLE_SIZE)}
        paragraphClassName="recipe-message unavailable"
        text={
          <FormattedMessage
            defaultMessage="Impact Unavailable"
            id="components/recipes/ResultsGrid:ImpactUnavailable"
          />
        }
      />
    );
  } else {
    return assertNever(status, "unhandled status");
  }
}

interface RecipeStatusBaseDisplayNewProps {
  icon?: ({
    className,
    width,
  }: {
    className: string;
    width: string;
  }) => ReactElement;
  paragraphClassName: string;
  text: ReactElement;
}

function RecipeStatusBaseDisplayNew(props: RecipeStatusBaseDisplayNewProps) {
  const { icon, paragraphClassName, text } = props;

  return (
    <div className="grid-recipe-status ml-3">
      <div className="icon-wrapper">
        {icon?.({ className: "icon", width: "100%" })}
      </div>
      <p className={classNames("Graphik-Medium-Web", paragraphClassName)}>
        {text}
      </p>
    </div>
  );
}

interface ResultsGridProps {
  impactCategory: ImpactCategory;
  onRecipesLoadMore: null | (() => Promise<void>);
  onSelect: (id: number, selected: boolean) => void;
  selectedRecipeIds: number[];
  queryParams: RecipesListPageQueryParams;
  recipes: Array<Recipe>;
  recipeUrl: (recipe: Recipe) => string;
  showSharedIcon: boolean;
  waitingForFreshImpacts: boolean;
}

export default function ResultsGrid(props: ResultsGridProps) {
  const {
    impactCategory,
    onRecipesLoadMore,
    onSelect,
    queryParams,
    recipes,
    recipeUrl,
    selectedRecipeIds,
    showSharedIcon,
    waitingForFreshImpacts,
  } = props;

  return (
    <>
      <FlipMove
        duration={200}
        staggerDelayBy={40}
        appearAnimation="elevator"
        enterAnimation="elevator"
        leaveAnimation="fade"
        className="mb-3"
        style={{
          display: "grid",
          gridGap: "24px 24px",
          gridTemplateColumns: "repeat(auto-fill, minmax(12.5rem, 1fr))",
          maxWidth: "100%",
          minWidth: 0, // Avoid reflows when animations are in progress
        }}
      >
        {recipes.map((x) => (
          <div style={{ width: "100%", maxWidth: 250 }} key={x.id}>
            <GridItem
              impactCategory={impactCategory}
              item={x}
              onSelect={onSelect}
              queryParams={queryParams}
              recipeUrl={recipeUrl}
              selected={selectedRecipeIds.includes(x.id)}
              showSharedIcon={showSharedIcon}
              waitingForFreshImpacts={waitingForFreshImpacts}
            />
          </div>
        ))}
      </FlipMove>
      <LoadMoreButton onClick={onRecipesLoadMore} />
    </>
  );
}

ResultsGrid.fragments = {
  recipes: gql`
    fragment ResultsGrid_Recipe on Recipe {
      id
      ownerOrganizationId
      impact(excludePackaging: false, fetchStaleImpacts: $fetchStaleImpacts) {
        effects {
          ghgPerKg
          ghgPerRootRecipeServing
          landUsePerKg
          landUsePerRootRecipeServing
          waterUsePerKg
          waterUsePerRootRecipeServing
        }
        isStale
        weightKgPerServing
        ...RecipeStatus_RecipeImpact
      }
      name
      collections {
        name
        id
      }
    }
    ${RecipeStatus.fragments.recipeImpact}
  `,
};
