import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  ActiveElement,
  ChartEvent,
  TooltipModel,
  ChartType,
  ChartDataset,
  Plugin,
  ChartOptions,
} from "chart.js";
import { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";

import { darkGreyCol, midGreyCol } from "../colors";
import { createClickableXAxisPlugin } from "../plugins/clickableXAxisPlugin";
import { createCustomLabelBackgroundPlugin } from "../plugins/customLabelBackgroundPlugin";
import { createHoverLinePlugin } from "../plugins/hoverLinePlugin";
import { externalTooltipHandler } from "../utils/TooltipUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

interface CustomDataset extends ChartDataset<"line"> {
  temp?: boolean;
}

interface AreaChartWithLineOverlayProps<T> {
  data: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    fill: number;
    pointRadius?: number;
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointBorderWidth?: number;
    borderWidth?: number;
    order?: number;
    id: number;
  }[];
  labels: string[];
  dependentAxisLabel: string;
  height?: number;
  maintainAspectRatio?: boolean;
  customTooltip?: (
    tooltip: { dataPoints: Array<any> },
    tooltipExtraData?: T
  ) => JSX.Element | null;
  xAxisMin: number;
  xAxisMax?: number;
  handleSelectedLabelIndex: (
    selectedLabelIndex: number | null,
    selectedLabelIndexPos: { x: number | null; y: number | null }
  ) => void;
  isPercentage?: boolean;
}

export default function AreaChartWithLineOverlay<T = undefined>({
  data,
  labels,
  dependentAxisLabel,
  height = 800,
  maintainAspectRatio = false,
  customTooltip,
  xAxisMin,
  xAxisMax,
  handleSelectedLabelIndex,
  isPercentage,
}: AreaChartWithLineOverlayProps<T>) {
  const [selectedLabelIndex, setSelectedLabelIndex] = useState<number | null>(
    null
  );
  const [selectedLabelIndexPos, setSelectedLabelIndexPos] = useState<{
    x: number | null;
    y: number | null;
  }>({
    x: null,
    y: null,
  });
  const [hoveredDatasetIndex, setHoveredDatasetIndex] = useState<number | null>(
    null
  );
  const [chartKey, setChartKey] = useState(0); // Force re-render

  const selectedLabelIndexRef = useRef<number | null>(null); // New ref to store the state

  const chartRef = useRef<ChartJS<"line"> | null>(null);
  const xAxisMinRef = useRef<number | null>(null);

  useEffect(() => {
    selectedLabelIndexRef.current = selectedLabelIndex;
    handleSelectedLabelIndex(selectedLabelIndex, {
      x: selectedLabelIndexPos.x,
      y: selectedLabelIndexPos.y,
    });
  }, [
    selectedLabelIndex,
    handleSelectedLabelIndex,
    selectedLabelIndexPos?.x,
    selectedLabelIndexPos?.y,
  ]);

  useEffect(() => {
    setChartKey((prev) => prev + 1);
  }, [data]);

  useEffect(() => {
    xAxisMinRef.current = xAxisMin;

    if (chartRef.current) {
      const chart = chartRef.current;
      // @ts-ignore
      chart.options.scales.x.min = xAxisMin; // Update x-axis min
      // @ts-ignore
      chart.options.scales.x.max = xAxisMax; // Update x-axis min

      chart.update(); // Re-render chart
    }
    setChartKey((prev) => prev + 1);
  }, [xAxisMin, xAxisMax]);

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio,
    hover: {
      mode: "dataset",
      intersect: false,
    },
    onHover: (event: ChartEvent, chartElements: ActiveElement[]) => {
      if (chartElements.length > 0) {
        const datasetIndex = chartElements[0].datasetIndex;
        setHoveredDatasetIndex(datasetIndex);
      } else {
        setHoveredDatasetIndex(null);
      }
    },
    plugins: {
      stripedBackground: true,
      legend: { display: false },
      datalabels: { display: false },
      ...(customTooltip !== null
        ? {
            tooltip: {
              enabled: false,
              external: (context: {
                tooltip: TooltipModel<"line">;
                chart: ChartType;
              }) => {
                const dataset = context?.tooltip?.dataPoints?.[0]
                  ?.dataset as CustomDataset;
                if (dataset?.temp) return;

                if (!context.tooltip || !context.chart) return;

                return externalTooltipHandler(context, true, customTooltip);
              },
            },
          }
        : {}),
    } as ChartOptions<"line">["plugins"],
    scales: {
      x: {
        border: {
          display: false,
        },
        stacked: true,
        grid: { display: false },
        ticks: {
          padding: 20,
          color: "transparent",
          backdropColor: "transparent",
          showLabelBackdrop: true,
          backdropPadding: 10, // Add padding around label background
          font: {
            weight: (ctx) =>
              ctx.index === selectedLabelIndex ? 500 : "normal",
          },
          maxTicksLimit:
            xAxisMax && xAxisMinRef.current
              ? xAxisMax - xAxisMinRef.current + 1
              : undefined, // Limits number of visible labels
        },
        offset: true,
        min: xAxisMinRef.current as number,
        max: xAxisMax,
      },
      y: {
        border: {
          display: false,
        },
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: dependentAxisLabel,
          color: darkGreyCol,
        },
        grid: { drawTicks: false },
        ticks: {
          padding: 16,
          color: midGreyCol,
          callback: (value) => {
            return isPercentage ? `${value}%` : value;
          },
        },
        max: isPercentage ? 100 : undefined,
      },
    },
  };

  const clickableXAxisPlugin = createClickableXAxisPlugin<"line">({
    id: "clickableXAxis",
    selectedLabelIndexRef,
    setSelectedLabelIndex,
    setSelectedLabelIndexPos,
    dataLabels: labels,
    xAxisMin,
    xAxisMax,
  });

  const customLabelBackgroundPlugin = createCustomLabelBackgroundPlugin<"line">(
    {
      selectedLabelIndexRef,
      backgroundColor: darkGreyCol,
    }
  );

  const hoverLinePlugin = createHoverLinePlugin<"line">({
    color: midGreyCol,
    lineWidth: 1,
    selectedLabelIndexRef,
  });

  const adjustOpacity = (rgbaString: string, alpha: number) => {
    const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?\)/;
    const match = rgbaString.match(rgbaRegex);

    if (match) {
      const [, r, g, b] = match;
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return rgbaString;
  };

  const hoverDatasetPlugin: Plugin<"line"> = {
    id: "hoverDataset",
    afterEvent(chart: ChartJS<"line">, args: any) {
      const { event } = args;
      if (!event) return;

      const { chartArea } = chart;
      const elements = chart.getElementsAtEventForMode(
        event,
        "dataset",
        { intersect: false },
        true
      );

      // If no dataset is hovered and the cursor is inside the chart but outside datasets
      if (elements.length === 0) {
        if (
          event.x < chartArea.left || // Left of chart area
          event.x > chartArea.right || // Right of chart area
          event.y < chartArea.top || // Above chart area
          event.y > chartArea.bottom // Below chart area (inside axes but not datasets)
        ) {
          setHoveredDatasetIndex(null); // Reset hover effect
        }
      }
    },
  };

  return (
    <div
      onMouseLeave={() => {
        setSelectedLabelIndex(null);
        setHoveredDatasetIndex(null);
        chartRef.current?.update();
      }}
      style={{ width: "80%", height: `${height}px` }}
    >
      <Line
        key={chartKey}
        ref={chartRef} // Attach ref to chart
        data={{
          labels,

          datasets: data.map((dataset, index) => ({
            ...dataset,
            id: dataset.id,
            backgroundColor:
              hoveredDatasetIndex !== null && hoveredDatasetIndex !== index
                ? adjustOpacity(dataset.backgroundColor, 0.5) // adjust opacity
                : dataset.backgroundColor,
            borderColor:
              hoveredDatasetIndex !== null && hoveredDatasetIndex !== index
                ? adjustOpacity(dataset.borderColor, 0.1)
                : dataset.borderColor,
          })),
        }}
        options={options}
        plugins={[
          clickableXAxisPlugin,
          hoverLinePlugin,
          hoverDatasetPlugin,
          customLabelBackgroundPlugin,
        ]}
      />
    </div>
  );
}
