import { FormattedMessage, useIntl } from "react-intl";

import { Supplier } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { Table, TableColumn } from "../utils/Table";
import { intlFormatNumber } from "./helperFunctions";

interface SuppliersTableProps {
  data: Array<Supplier>;
  impactCategory: ImpactCategory;
}

export default function SuppliersTable(props: SuppliersTableProps) {
  const { data } = props;

  const intl = useIntl();

  const columns: Array<TableColumn<Supplier>> = [
    {
      key: "supplier",
      label: intl.formatMessage({
        id: "components/scope-3/SuppliersTable:supplier",
        defaultMessage: "Supplier",
      }),
      renderCell: (supplier) =>
        supplier.name ?? (
          <FormattedMessage
            id="components/scope-3/SuppliersTable:unspecifiedSupplier"
            defaultMessage="Unspecified supplier"
          />
        ),
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "landUseChangeCO2",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:landUseChangeCO2",
        defaultMessage: "Land Use Change - CO2",
      }),
      units: "kg CO2e",
      renderCell: (supplier) =>
        intlFormatNumber(intl, Number(supplier.landUseChangeCO2)),
      sortComparator: comparators.map(
        (item) => item.landUseChangeCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCO2",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:landManagementCO2",
        defaultMessage: "Land Management - CO2",
      }),
      units: "kg CO2e",
      renderCell: (supplier) =>
        intlFormatNumber(intl, Number(supplier.landManagementCO2)),
      sortComparator: comparators.map(
        (item) => item.landManagementCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCH4",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:landManagementCH4",
        defaultMessage: "Land Management - CH4",
      }),
      units: "kg CO2e",
      renderCell: (supplier) =>
        intlFormatNumber(intl, Number(supplier.landManagementCH4)),
      sortComparator: comparators.map(
        (item) => item.landManagementCH4 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementN2O",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:landManagementN2O",
        defaultMessage: "Land Management - N20",
      }),
      units: "kg CO2e",
      renderCell: (supplier) =>
        intlFormatNumber(intl, Number(supplier.landManagementN2O)),
      sortComparator: comparators.map(
        (item) => item.landManagementN2O ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "flagTotal",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:flagTotal",
        defaultMessage: "FLAG Total",
      }),
      renderCell: (supplier) =>
        intlFormatNumber(intl, Number(supplier.flagTotal)),
      sortComparator: comparators.map(
        (item) => item.flagTotal ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "energy",
      label: intl.formatMessage({
        id: "components/flag/SuppliersTable:energyIndustry",
        defaultMessage: "Energy and Industry",
      }),
      renderCell: (supplier) => intlFormatNumber(intl, Number(supplier.energy)),
      sortComparator: comparators.map(
        (item) => item.energy ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/SuppliersTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (supplier) => intlFormatNumber(intl, supplier.massKg),
      sortComparator: comparators.map(
        (item) => item.massKg,
        comparators.number
      ),
    },
  ];

  return (
    <Table<Supplier>
      fullWidth
      columns={columns}
      rowKey={(item) => item.name}
      rows={data}
    />
  );
}
