import { gql } from "graphql-tag";
import { compact } from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { RecipeFilter } from "../../__generated__/globalTypes";
import { useDataStore } from "../../data-store";
import useUserInfo from "../../data-store/useUserInfo";
import { ImpactCategory } from "../../domain/impactCategories";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
} from "../../services/useOrganizationFeatures";
import { RecipePageName, useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import { combineFiltersAnd } from "../../util/filters";
import * as statuses from "../../util/statuses";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import usePagedQuery, {
  extractNodesFromPagedQueryResult,
} from "../graphql/usePagedQuery";
import useQuery from "../graphql/useQuery";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { RecipesListPageQueryParams, usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import DeleteModal from "../utils/DeleteModal";
import {
  GridListToggle,
  State as GridListToggleState,
} from "../utils/GridListToggle";
import SelectAllCheckbox, {
  SelectAllCheckboxState,
} from "../utils/select-all-checkbox/SelectAllCheckbox";
import Spinner from "../utils/Spinner";
import Toast, { ErrorToast } from "../utils/Toast";
import TooltipOverlay from "../utils/TooltipOverlay";
import { FilledCheckmark, Plus, Products, Share } from "../utils/Vectors";
import ActionBar from "./ActionBar";
import {
  CopySuccessToastState,
  MultipleRecipeCopyToButton,
} from "./CopyToButton";
import DeleteRecipeModal from "./DeleteRecipeModal";
import ExportRecipesDropdown from "./ExportRecipesDropdown";
import ImpactCategoryToggle from "./ImpactCategoryToggle";
import RecipeQueryControls from "./RecipeQueryControls";
import RecipesDropdown, { RecipesDropdownItem } from "./RecipesDropdown";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";
import {
  RecipesPanel_DeleteRecipes,
  RecipesPanel_DeleteRecipesVariables,
  RecipesPanel_Recipe,
  RecipesPanel_Recipe as Recipe,
  RecipesPanel_RecipesPanelQuery,
  RecipesPanel_RecipesPanelQuery as RecipesPanelQuery,
  RecipesPanel_RecipesPanelQueryVariables as RecipesPanelQueryVariables,
  RecipesPanel_RecipesQuery as RecipesQuery,
  RecipesPanel_RecipesQueryVariables as RecipesQueryVariables,
} from "./RecipesPanel.graphql";
import RecipesTable from "./RecipesTable";
import ResultsGrid from "./ResultsGrid";
import useRecipeLabel from "./useRecipeLabel";

import "./RecipesPanel.css";

export enum RecipeSelectionType {
  ALL_INCLUDING_UNSEEN = "All",
  PARTIAL = "PARTIAL",
  NONE = "NONE",
}

export type RecipeSelection =
  | {
      type: RecipeSelectionType.ALL_INCLUDING_UNSEEN;
      excludedIds: Array<number>;
    }
  | { type: RecipeSelectionType.PARTIAL; ids: Array<number> };

interface RecipesPanelProps {
  filterByParentCollections?: boolean;
  newRecipeUrl?: string;
  noRecipesMessage: React.ReactNode;
  noRecipesDueToSearchMessage: React.ReactNode;
  pageName: RecipePageName;
  recipeFilter: RecipeFilter;
  recipeUrl: (recipe: { id: number }) => string;
  showSearch?: boolean;
  showAddDropdown: boolean;
  showCopyToButton: boolean;
  showExportDropdown: boolean;
  showFilterToRequiresAttention: boolean;
  showManageTagsButton: boolean;
  includeRecipesUsedAsIngredient: boolean;
  includeRecipesOnlyAccessibleViaCollection: boolean;
}

export default function RecipesPanel(props: RecipesPanelProps) {
  const {
    filterByParentCollections,
    newRecipeUrl,
    noRecipesMessage,
    noRecipesDueToSearchMessage,
    pageName,
    recipeFilter: recipeFilterProp,
    recipeUrl,
    showAddDropdown,
    showCopyToButton,
    showExportDropdown,
    showManageTagsButton,
    showSearch = true,
    showFilterToRequiresAttention,
    includeRecipesUsedAsIngredient,
    includeRecipesOnlyAccessibleViaCollection,
  } = props;

  const pages = usePages();
  const recipeLabel = useRecipeLabel();
  const [queryParams, setQueryParams] = pages.RecipesListPage.useQueryParams();
  const [organizationId] = useOrganizationId();
  const [{ isReadonly: userIsReadonly }] = useUserInfo();
  const intl = useIntl();

  const emptySelection: RecipeSelection = {
    type: RecipeSelectionType.PARTIAL,
    ids: [],
  };
  const [recipeSelection, setRecipeSelection] =
    useState<RecipeSelection>(emptySelection);

  const [viewState, setViewState] = useState<GridListToggleState>(
    queryParams.view
  );
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const [copyToastState, setCopyToastState] =
    useState<CopySuccessToastState | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const selectionIsEmpty =
    recipeSelection.type === RecipeSelectionType.PARTIAL &&
    recipeSelection.ids.length === 0;

  const searchTerm =
    queryParams.searchTerm === "" ? undefined : queryParams.searchTerm;

  const recipeFilter = useMemo(() => {
    const collectionsFilter =
      queryParams.filterToSelectedCollectionIds.length !== 0
        ? queryParams.filterToSelectedCollectionIds.map((id) => ({
            collections: { ids: [id] },
          }))
        : undefined;

    const cachedImpactRatingGhgFilter =
      queryParams.filterToSelectedGhgImpactRatings.length !== 0
        ? queryParams.filterToSelectedGhgImpactRatings.map((impactRating) => ({
            cachedImpactRatingGhg: impactRating,
          }))
        : undefined;
    const cachedImpactRatingLandUseFilter =
      queryParams.filterToSelectedLandUseImpactRatings.length !== 0
        ? queryParams.filterToSelectedLandUseImpactRatings.map(
            (impactRating) => ({ cachedImpactRatingLandUse: impactRating })
          )
        : undefined;
    const cachedImpactRatingWaterUseFilter =
      queryParams.filterToSelectedWaterUseImpactRatings.length !== 0
        ? queryParams.filterToSelectedWaterUseImpactRatings.map(
            (impactRating) => ({ cachedImpactRatingWaterUse: impactRating })
          )
        : undefined;

    const cachedImpactRatingFilter = [
      ...(cachedImpactRatingGhgFilter !== undefined
        ? [{ anyOf: cachedImpactRatingGhgFilter }]
        : []),
      ...(cachedImpactRatingLandUseFilter !== undefined
        ? [{ anyOf: cachedImpactRatingLandUseFilter }]
        : []),
      ...(cachedImpactRatingWaterUseFilter !== undefined
        ? [{ anyOf: cachedImpactRatingWaterUseFilter }]
        : []),
    ];

    const dietsFilter =
      queryParams.filterToSelectedDiets.length !== 0
        ? queryParams.filterToSelectedDiets.map((diet) => ({
            cachedDietaryCategory: diet,
          }))
        : undefined;

    const intersectCollections = queryParams.intersectCollections;

    const allOfFilter = [
      ...cachedImpactRatingFilter,
      ...(dietsFilter !== undefined ? [{ anyOf: dietsFilter }] : []),
      ...(intersectCollections && collectionsFilter !== undefined
        ? collectionsFilter
        : []),
    ];
    const anyOfFilter = [
      ...(!intersectCollections && collectionsFilter !== undefined
        ? collectionsFilter
        : []),
    ];

    const ourFilter = {
      allOf: allOfFilter.length > 0 ? allOfFilter : undefined,
      anyOf: anyOfFilter.length > 0 ? anyOfFilter : undefined,
      cachedRequiresClientAttention: queryParams.filterToRequiresClientAttention
        ? true
        : undefined,
      searchCodeOrName: searchTerm,
      usedAsIngredient: !includeRecipesUsedAsIngredient ? false : undefined,
      onlyAccessibleViaCollection: !includeRecipesOnlyAccessibleViaCollection
        ? false
        : undefined,
    };
    return combineFiltersAnd(recipeFilterProp, ourFilter);
  }, [
    includeRecipesOnlyAccessibleViaCollection,
    includeRecipesUsedAsIngredient,
    queryParams.filterToSelectedCollectionIds,
    queryParams.filterToRequiresClientAttention,
    queryParams.intersectCollections,
    queryParams.filterToSelectedGhgImpactRatings,
    queryParams.filterToSelectedLandUseImpactRatings,
    queryParams.filterToSelectedWaterUseImpactRatings,
    queryParams.filterToSelectedDiets,
    recipeFilterProp,
    searchTerm,
  ]);

  const jsonRecipeFilter = JSON.stringify(recipeFilter);

  useEffect(() => {
    setRecipeSelection({ type: RecipeSelectionType.PARTIAL, ids: [] });
  }, [jsonRecipeFilter, queryParams.orderBy]);

  const handleSelectAllCheckboxChange = () => {
    if (selectionIsEmpty) {
      setRecipeSelection({
        type: RecipeSelectionType.PARTIAL,
        ids: displayedRecipes.map((recipe) => recipe.id),
      });
    } else {
      setRecipeSelection(emptySelection);
    }
  };

  const handleSelectChange = (id: number, selected: boolean) => {
    if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      setRecipeSelection({
        ...recipeSelection,
        ids: selected
          ? [...recipeSelection.ids, id]
          : recipeSelection.ids.filter((selectedId) => selectedId !== id),
      });
    } else if (
      recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN
    ) {
      setRecipeSelection({
        ...recipeSelection,
        excludedIds: selected
          ? recipeSelection.excludedIds.filter(
              (selectedId) => selectedId !== id
            )
          : [...recipeSelection.excludedIds, id],
      });
    } else {
      assertNever(recipeSelection, "Unsupported RecipeSelectionType");
    }
  };

  const {
    fetchNextPage,
    status: recipesStatus,
    refresh: refreshRecipesQuery,
  } = usePagedQuery<
    RecipesQuery,
    RecipesQueryVariables,
    RecipesQuery["recipes"]["edges"][0]["node"]
  >(
    recipesQuery,
    {
      after: null,
      recipeFilter,
      first: 60,
      orderBy: queryParams.orderBy,
      fetchStaleImpacts: true,
    },
    recipesQueryGetRecipes
  );

  const handleRecipesLoadMore = (fetchNextPage: () => Promise<void>) => {
    return fetchNextPage();
  };

  const { status: recipesPanelQueryStatus, refresh: refreshPanel } = useQuery<
    RecipesPanelQuery,
    RecipesPanelQueryVariables
  >(recipesPanelQuery, { organizationFilter: { id: organizationId } });

  const addDropdown = showAddDropdown
    ? (
        organization: {
          hasReachedRecipeCountLimit: boolean;
          recipeCountLimit: number | null;
        } | null
      ) => (
        <AddDropdown
          hasReachedRecipeCountLimit={
            !!organization?.hasReachedRecipeCountLimit
          }
          newRecipeUrl={newRecipeUrl}
          queryParams={queryParams}
          recipeCountLimit={organization ? organization.recipeCountLimit : null}
          recipeType={recipeLabel.pluralUppercase}
        />
      )
    : () => null;

  const requiresClientAttentionRecipeIds = (
    recipes: Array<Recipe>
  ): Array<number> => {
    return recipes
      .filter((recipe) => recipe.impact.requiresClientAttention)
      .map((recipe) => recipe.id);
  };

  const selectedRecipesFilter: RecipeFilterWithExclude =
    recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN
      ? {
          ...recipeFilter,
          excludedIds: recipeSelection.excludedIds,
        }
      : { ...recipeFilter, ids: recipeSelection.ids };

  const mapEmptyIdsArrayToUndefined = (filter: RecipeFilterWithExclude) =>
    filter.ids?.length === 0 ? { ...filter, ids: undefined } : filter;

  const exportDropdown = () =>
    showExportDropdown ? (
      <ExportRecipesDropdown
        defaultImpactCategory={impactCategory}
        disabled={!selectionIsEmpty}
        pageName={pageName}
        recipeFilter={mapEmptyIdsArrayToUndefined(selectedRecipesFilter)}
      />
    ) : (
      () => null
    );

  const handleGridListToggleChange = (state: GridListToggleState) => {
    setViewState(state);
    setQueryParams({ view: state });
  };

  const gridListToggle = (
    <GridListToggle setState={handleGridListToggleChange} state={viewState} />
  );

  const [possiblyStaleRecipes, setPossiblyStaleRecipes] = useState<
    Array<Recipe>
  >([]);
  const [recalculatedRecipes, setRecalculatedRecipes] = useState<Array<Recipe>>(
    []
  );
  const [recalculatedRecipesFailed, setRecalculatedRecipesFailed] =
    useState<boolean>(false);
  const [displayedRecipes, setDisplayedRecipes] = useState<Array<Recipe>>([]);
  const [calculationState, setCalculationState] = useState<
    | "calculating - show toast"
    | "calculating - hide toast"
    | "calculation complete - show toast"
    | "calculation - failed"
    | "not calculating"
  >("not calculating");

  const hasStaleRecipe = (recipes: Array<Recipe>) =>
    recipes.some((recipe) => recipe.impact.isStale);

  const hasStaleDisplayedRecipe = hasStaleRecipe(displayedRecipes);

  useEffect(() => {
    if (
      recalculatedRecipesFailed &&
      calculationState !== "calculating - hide toast"
    ) {
      setCalculationState("calculation - failed");
      return;
    }
    // Use a stale recipe unless it has been recalculated, then use the recalculated recipe.
    const updatedRecipesToDisplay = possiblyStaleRecipes.map(
      (possiblyStaleRecipe) =>
        recalculatedRecipes.find(
          (recalculatedRecipe) =>
            recalculatedRecipe.id === possiblyStaleRecipe.id
        ) || possiblyStaleRecipe
    );
    if (hasStaleDisplayedRecipe && !hasStaleRecipe(updatedRecipesToDisplay)) {
      setCalculationState("calculation complete - show toast");
    }
    if (hasStaleRecipe(updatedRecipesToDisplay)) {
      if (calculationState !== "calculating - hide toast") {
        setCalculationState("calculating - show toast");
      }
    }
    setDisplayedRecipes(updatedRecipesToDisplay);
  }, [
    possiblyStaleRecipes,
    recalculatedRecipes,
    hasStaleDisplayedRecipe,
    calculationState,
    recalculatedRecipesFailed,
  ]);

  const setPossiblyStaleRecipesFromQueryRecipes = (recipes: Array<Recipe>) => {
    setPossiblyStaleRecipes(recipes);
    return null;
  };

  const handleRecalculatedRecipes = (
    newlyRecalculatedRecipes: Array<Recipe>
  ) => {
    setRecalculatedRecipes([
      ...recalculatedRecipes,
      ...newlyRecalculatedRecipes,
    ]);
  };

  function setIncludingUnseen() {
    return () => {
      if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
        setRecipeSelection(emptySelection);
      } else if (selectionIsEmpty) {
        setRecipeSelection({
          type: RecipeSelectionType.ALL_INCLUDING_UNSEEN,
          excludedIds: [],
        });
      } else {
        setRecipeSelection({
          type: RecipeSelectionType.ALL_INCLUDING_UNSEEN,
          excludedIds: displayedRecipes
            .map((recipe) => recipe.id)
            .filter((id) => !recipeSelection.ids.includes(id)),
        });
      }
    };
  }

  const allRecipesInViewSelected =
    recipeSelection.type === RecipeSelectionType.PARTIAL &&
    displayedRecipes.length > 0 &&
    displayedRecipes.length === recipeSelection.ids.length;

  const allRecipesSelected = (recipeCount: number) =>
    (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN &&
      recipeSelection.excludedIds.length === 0) ||
    (recipeSelection.type === RecipeSelectionType.PARTIAL &&
      recipeSelection.ids.length === recipeCount);

  const selectAllCheckboxState = (recipeCount: number) => {
    if (recipeCount === 0) {
      return SelectAllCheckboxState.DISABLED;
    } else if (allRecipesSelected(recipeCount) || allRecipesInViewSelected) {
      return SelectAllCheckboxState.CHECKED;
    } else if (selectionIsEmpty) {
      return SelectAllCheckboxState.EMPTY;
    } else {
      return SelectAllCheckboxState.INDETERMINATE;
    }
  };

  const handleCollectionsDeleted = (
    deletedCollectionIds: Set<number>,
    collectionIdsRemovedFromRecipes: Set<number>
  ) => {
    const selectedAndNotDeletedCollections =
      queryParams.filterToSelectedCollectionIds.filter(
        (selectedId) => !deletedCollectionIds.has(selectedId)
      );
    const removedFromRecipesAndFilteredFor =
      queryParams.filterToSelectedCollectionIds.filter((selectedId) =>
        collectionIdsRemovedFromRecipes.has(selectedId)
      );
    if (
      selectedAndNotDeletedCollections.length !==
      queryParams.filterToSelectedCollectionIds.length
    ) {
      setQueryParams({
        filterToSelectedCollectionIds: [...selectedAndNotDeletedCollections],
        filterToRequiresClientAttention:
          queryParams.filterToRequiresClientAttention,
        intersectCollections: queryParams.intersectCollections,
      });
    } else if (removedFromRecipesAndFilteredFor.length > 0) {
      refreshRecipesQuery();
    }
  };

  const handleDeleteClicked = () => {
    setShowDeleteModal(true);
  };

  const getDisplayedRecipeName = (recipeId: number) => {
    const recipe = displayedRecipes.find((recipe) => recipe.id === recipeId);
    if (recipe === undefined) {
      return ""; // We need to return something in case the recipe has been recently deleted.
    }
    return recipe.name;
  };

  const [deleteRecipes] = useMutation<
    RecipesPanel_DeleteRecipes,
    RecipesPanel_DeleteRecipesVariables
  >(deleteRecipesMutation);

  const handleConfirmDelete = async () => {
    const filter: RecipeFilter = getRecipeFilter();
    try {
      await deleteRecipes({ variables: { input: { filter } } });
    } catch (error) {
      handleDeleteError();
    }
    await Promise.all([refreshPanel(), refreshRecipesQuery()]);

    setShowDeleteModal(false);
    setRecipeSelection(emptySelection);
  };

  const getRecipeFilter = (): RecipeFilter => {
    if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      return { ids: recipeSelection.ids };
    }

    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return { ...recipeFilter, excludedIds: recipeSelection.excludedIds };
    }

    if (recipeSelection === RecipeSelectionType.NONE) {
      return {};
    }

    assertNever(recipeSelection, "Unsupported recipe selection type");
  };

  const getRecipeLabel = (): string => {
    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return recipeLabel.pluralLowercase;
    }

    return recipeSelection.ids.length > 1
      ? recipeLabel.pluralLowercase
      : recipeLabel.singularLowercase;
  };

  const handleDeleteError = () => {
    throw new UserVisibleError(
      intl.formatMessage(
        {
          id: "components/recipes/RecipesPanel:recipeDeletionErrorMessage",
          defaultMessage: "Could not delete the {recipeLabel}",
        },
        { recipeLabel: getRecipeLabel() }
      )
    );
  };

  const processedRecipesStatus = statuses.map(recipesStatus, (data) => ({
    recipeCount: data.firstPage.recipeCount,
    recipes: extractNodesFromPagedQueryResult(data),
  }));

  type ProcessedRecipesQuery = {
    recipeCount: number;
    recipes: Array<RecipesPanel_Recipe>;
  };

  const someFilterApplied =
    (searchTerm !== undefined && searchTerm !== "") ||
    queryParams.filterToRequiresClientAttention ||
    queryParams.filterToSelectedCollectionIds.length > 0 ||
    queryParams.filterToSelectedGhgImpactRatings.length > 0 ||
    queryParams.filterToSelectedLandUseImpactRatings.length > 0 ||
    queryParams.filterToSelectedWaterUseImpactRatings.length > 0 ||
    queryParams.filterToSelectedDiets.length > 0;

  return (
    <div className="RecipesPanel">
      <StatusDisplay.Many<
        [RecipesPanel_RecipesPanelQuery, ProcessedRecipesQuery]
      >
        statuses={[recipesPanelQueryStatus, processedRecipesStatus]}
      >
        {(
          { organization },
          { recipeCount, recipes: possiblyStaleQueryRecipes }
        ) => (
          <>
            {setPossiblyStaleRecipesFromQueryRecipes(possiblyStaleQueryRecipes)}
            <RecipesPanelControls
              addDropdown={addDropdown(organization)}
              filterByParentCollections={filterByParentCollections}
              disableRecipeQueryControls={hasStaleRecipe(displayedRecipes)}
              exportDropdown={exportDropdown()}
              fetchNextPage={fetchNextPage}
              gridListToggle={gridListToggle}
              impactCategory={impactCategory}
              recipeFilter={selectedRecipesFilter}
              numberOfExcludedRecipes={
                recipeSelection.type ===
                RecipeSelectionType.ALL_INCLUDING_UNSEEN
                  ? recipeSelection.excludedIds.length
                  : 0
              }
              numberOfRecipesOnPage={displayedRecipes.length}
              onSelectAllCheckboxChange={handleSelectAllCheckboxChange}
              pageName={pageName}
              queryParams={queryParams}
              selectAllCheckboxState={selectAllCheckboxState(recipeCount)}
              selectAllIncludingUnseen={
                recipeSelection.type ===
                RecipeSelectionType.ALL_INCLUDING_UNSEEN
              }
              setImpactCategory={setImpactCategory}
              setIncludingUnseen={setIncludingUnseen()}
              setQueryParams={setQueryParams}
              setToastState={setCopyToastState}
              showCopyToButton={showCopyToButton}
              showFilterToRequiresAttention={showFilterToRequiresAttention}
              showManageTagsButton={showManageTagsButton}
              showSearch={showSearch}
              totalRecipesCount={recipeCount}
            />
            {displayedRecipes.length > 0 ? (
              <RecipesDisplay
                impactCategory={impactCategory}
                onRecipesLoadMore={
                  fetchNextPage === null
                    ? null
                    : () => handleRecipesLoadMore(fetchNextPage)
                }
                recipes={displayedRecipes}
                onSelect={(id, selected) => handleSelectChange(id, selected)}
                selectedRecipeIds={
                  recipeSelection.type ===
                  RecipeSelectionType.ALL_INCLUDING_UNSEEN
                    ? displayedRecipes
                        .map((_) => _.id)
                        .filter(
                          (id) => !recipeSelection.excludedIds.includes(id)
                        )
                    : recipeSelection.ids
                }
                showCollectionsColumn={pageName === "Shared Products"}
                showSharedIcon={pageName === "Shared Products"}
                queryParams={queryParams}
                recipeUrl={recipeUrl}
                refresh={refreshRecipesQuery}
                table={viewState === "list"}
                onRecipesRecalculationComplete={handleRecalculatedRecipes}
                onRecipesRecalculationFailed={() => {
                  setRecalculatedRecipesFailed(true);
                }}
              />
            ) : (
              <p>
                {recipeCount === 0 ? (
                  someFilterApplied ? (
                    noRecipesDueToSearchMessage
                  ) : (
                    noRecipesMessage
                  )
                ) : (
                  <FormattedMessage
                    id="components/recipes/RecipesPanel:noRecipeFoundMessage"
                    defaultMessage="No {recipeLabel} found."
                    values={{ recipeLabel: recipeLabel.pluralLowercase }}
                  />
                )}
              </p>
            )}
            <Toast
              show={calculationState === "calculating - show toast"}
              title={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculatingImpactsToast/title"
                  defaultMessage="Calculating Impacts."
                />
              }
              onClose={() => setCalculationState("calculating - hide toast")}
              message={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculatingImpactsToast/message"
                  defaultMessage="Foodsteps is busy calculating the impact of your products. It may take a few minutes to see changes."
                />
              }
              symbol={
                <Spinner className="RecipesPanel_CalculatingImpactsSpinner" />
              }
            />
            <Toast
              show={calculationState === "calculation complete - show toast"}
              title={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculationsCompletedToast/title"
                  defaultMessage="Calculations Completed."
                />
              }
              onClose={() => setCalculationState("not calculating")}
              message={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculationsCompletedMessage/message"
                  defaultMessage="Foodsteps has finished calculating the impact of your products."
                />
              }
              symbol={<FilledCheckmark width={20} />}
            />
            <ErrorToast
              show={calculationState === "calculation - failed"}
              title={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculationsFailedToast/title"
                  defaultMessage="Calculations failed."
                />
              }
              onClose={() => setCalculationState("not calculating")}
              message={
                <FormattedMessage
                  id="components/recipes/RecipesPanel:CalculationsFailedMessage/message"
                  defaultMessage="An error occurred while calculating the new impacts of your products. Try refreshing the page."
                />
              }
            />
            {!selectionIsEmpty &&
              !(pageName === "Shared Products" && userIsReadonly) && (
                <div className="RecipesPanel_ActionBar">
                  <ActionBar
                    allProductsIncludingUnseenSelected={allRecipesSelected(
                      recipeCount
                    )}
                    allProductsInViewSelected={allRecipesInViewSelected}
                    impactCategory={impactCategory}
                    onDelete={handleDeleteClicked}
                    onCollectionsDeleted={handleCollectionsDeleted}
                    onSelectAllProducts={setIncludingUnseen()}
                    pageName={pageName}
                    recipeFilter={recipeFilter}
                    recipeSelection={recipeSelection}
                    requiresAttentionIds={requiresClientAttentionRecipeIds(
                      displayedRecipes
                    )}
                    setToastState={setCopyToastState}
                    totalRecipeCount={recipeCount}
                  />
                </div>
              )}
            {copyToastState && (
              <Toast
                message={copyToastState.message}
                onClose={() => setCopyToastState(null)}
                show
                title={copyToastState.title}
                symbol={<FilledCheckmark width={20} />}
              />
            )}
            <DeleteProductsModal
              getRecipeName={getDisplayedRecipeName}
              show={showDeleteModal}
              onDelete={handleConfirmDelete}
              onHide={() => setShowDeleteModal(false)}
              recipeSelection={recipeSelection}
              totalRecipeCount={recipeCount}
            />
          </>
        )}
      </StatusDisplay.Many>
    </div>
  );
}

interface DeleteProductsModalProps {
  getRecipeName: (recipeId: number) => string;
  show: boolean;
  onDelete: () => Promise<void>;
  onHide: () => void;
  recipeSelection: RecipeSelection;
  totalRecipeCount: number;
}

function DeleteProductsModal(props: DeleteProductsModalProps) {
  const {
    getRecipeName,
    onHide,
    onDelete,
    show,
    recipeSelection,
    totalRecipeCount,
  } = props;

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();

  interface DeleteProductsModalText {
    message: ReactNode;
    title: string;
  }

  const deleteSingleText = (id: number): DeleteProductsModalText => {
    return {
      title: intl.formatMessage(
        {
          id: "components/recipes/RecipesPanel:deleteModalTitle/single",
          defaultMessage: "Delete {recipeLabel}?",
        },
        {
          recipeLabel: recipeLabel.singularLowercase,
        }
      ),
      message: intl.formatMessage(
        {
          id: "components/recipes/RecipesPanel:deleteModalMessage/partial/single",
          defaultMessage: "Deleting <b>{name}</b> will remove it permanently.",
        },
        {
          name: getRecipeName(id),
          b: (chunks: ReactNode) => <b>{chunks}</b>,
        }
      ),
    };
  };

  const deleteMultipleText = (count: number) => {
    return {
      title: intl.formatMessage(
        {
          id: "components/recipes/RecipesPanel:deleteModalTitle/multiple",
          defaultMessage: "Delete {recipeLabel}?",
        },
        {
          recipeLabel: recipeLabel.pluralLowercase,
        }
      ),
      message: intl.formatMessage(
        {
          id: "components/recipes/RecipesPanel:deleteModalMessage/partial/multiple",
          defaultMessage:
            "Deleting these <b>{count} {recipeLabel}</b> will remove them permanently.",
        },
        {
          count,
          recipeLabel: recipeLabel.pluralLowercase,
          b: (chunks: ReactNode) => <b>{chunks}</b>,
        }
      ),
    };
  };

  const text = (): { message: ReactNode; title: string } => {
    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return deleteMultipleText(
        totalRecipeCount - recipeSelection.excludedIds.length
      );
    } else if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      if (recipeSelection.ids.length === 1) {
        return deleteSingleText(recipeSelection.ids[0]);
      } else {
        return deleteMultipleText(recipeSelection.ids.length);
      }
    } else {
      assertNever(recipeSelection, "Unsupported recipe selection type");
    }
  };

  return (
    <DeleteModal
      name={""}
      show={show}
      onDelete={onDelete}
      onHide={onHide}
      message={text().message}
      title={text().title}
    />
  );
}

interface AddDropdownProps {
  className?: string;
  hasReachedRecipeCountLimit: boolean;
  newRecipeUrl: string | undefined;
  queryParams: RecipesListPageQueryParams;
  recipeCountLimit: number | null;
  recipeType: string;
}

function AddDropdown(props: AddDropdownProps) {
  const {
    className,
    hasReachedRecipeCountLimit,
    newRecipeUrl,
    queryParams,
    recipeCountLimit,
    recipeType,
  } = props;
  const pages = usePages();

  const history = useHistory();
  const { trackRecipeEditStarted } = useTracking();

  if (newRecipeUrl === undefined) {
    return null;
  }

  const addRecipeButton: false | RecipesDropdownItem = newRecipeUrl !==
    null && {
    action: {
      type: "button",
      onClick: () => {
        const location =
          RecipesListPageLocationState.queryParamsToLocationDescriptor(
            newRecipeUrl,
            queryParams
          );
        history.push(location);
        trackRecipeEditStarted({ type: "add" });
      },
    },
    Icon: Products,
    render: pages.addRecipeTitle,
  };

  const recipeUploadUrl = pages.RecipesUpload.url;

  const uploadRecipesButton: false | RecipesDropdownItem = recipeUploadUrl !==
    null && {
    action: {
      type: "link",
      to: recipeUploadUrl,
    },
    Icon: Share,
    render: (
      <FormattedMessage
        id="components/recipes/RecipesPanel:upload"
        defaultMessage="Upload {recipeType}"
        values={{
          recipeType,
        }}
      />
    ),
  };

  const dropdown = (
    <RecipesDropdown
      className={className}
      disabled={hasReachedRecipeCountLimit}
      Icon={Plus}
      items={compact([addRecipeButton, uploadRecipesButton])}
      title={
        <FormattedMessage
          id="components/recipes/RecipesPanel:addDropdownButtonTitle"
          defaultMessage="Add"
        />
      }
      variant="primary"
    />
  );

  return hasReachedRecipeCountLimit ? (
    <TooltipOverlay
      overlay={
        <FormattedMessage
          defaultMessage="You have reached your product limit of {recipeCountLimit} products."
          id="components/recipes/RecipesPanel:recipeCountLimitReachedMessage"
          values={{ recipeCountLimit }}
        />
      }
      id={`components/recipes/RecipesPanel:addRecipeButtonTooltip`}
    >
      {dropdown}
    </TooltipOverlay>
  ) : (
    dropdown
  );
}

interface RecipesDisplayProps {
  impactCategory: ImpactCategory;
  onRecipesLoadMore: null | (() => Promise<void>);
  onSelect: (id: number, selected: boolean) => void;
  queryParams: RecipesListPageQueryParams;
  recipes: Array<Recipe>;
  recipeUrl: (recipe: { id: number }) => string;
  refresh: () => Promise<void>;
  selectedRecipeIds: number[];
  showCollectionsColumn: boolean;
  showSharedIcon: boolean;
  table: boolean;
  onRecipesRecalculationComplete: (
    newlyRecalculatedRecipes: Array<Recipe>
  ) => void;
  onRecipesRecalculationFailed: () => void;
}

function RecipesDisplay(props: RecipesDisplayProps) {
  const {
    impactCategory,
    onRecipesLoadMore,
    onSelect,
    queryParams,
    recipes,
    recipeUrl,
    refresh,
    selectedRecipeIds,
    showCollectionsColumn,
    showSharedIcon,
    table,
    onRecipesRecalculationComplete,
    onRecipesRecalculationFailed,
  } = props;

  const dataStore = useDataStore();

  const [deletingRecipeId, setDeletingRecipeId] = useState<number | null>(null);

  const staleRecipes = recipes.filter((recipe) => recipe.impact.isStale);

  const deletingRecipe =
    deletingRecipeId === null
      ? null
      : recipes.find((recipe) => recipe.id === deletingRecipeId);

  function handleDeleteRecipe(item: Pick<Recipe, "id">) {
    setDeletingRecipeId(item.id);
  }

  const [fetchingUpdatedRecipes, setFetchingUpdatedRecipes] =
    useState<boolean>(false);
  const [fetchingUpdatedRecipesFailed, setFetchingUpdatedRecipesFailed] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchUpdatedRecipes(): Promise<Array<Recipe>> {
      setFetchingUpdatedRecipes(true);
      const data = await dataStore.fetchGraphQL<
        RecipesQuery,
        RecipesQueryVariables
      >({
        query: recipesQuery,
        variables: {
          recipeFilter: { ids: staleRecipes.map((recipe) => recipe.id) },
          first: 1000, // TODO: How many should we fetch here? Could we use this to batch updates
          orderBy: queryParams.orderBy,
          fetchStaleImpacts: false,
        },
      });
      setFetchingUpdatedRecipes(false);
      if (data.recipes === null) {
        throw new UserVisibleError("Could not fetch updated recipes.");
      }
      return data.recipes.edges.map((edge) => edge.node);
    }

    async function updateRecipesIfStale() {
      if (staleRecipes.length > 0 && !fetchingUpdatedRecipes) {
        let updatedRecipes = null;
        try {
          updatedRecipes = await fetchUpdatedRecipes();
        } catch (error) {
          reportError(error);
          setFetchingUpdatedRecipesFailed(true);
          onRecipesRecalculationFailed();
        }
        if (updatedRecipes) {
          onRecipesRecalculationComplete(updatedRecipes);
        }
      }
    }
    updateRecipesIfStale();
  }, [
    dataStore,
    fetchingUpdatedRecipes,
    queryParams.orderBy,
    staleRecipes,
    recipes,
    onRecipesRecalculationComplete,
    onRecipesRecalculationFailed,
  ]);

  return (
    <>
      {table ? (
        <RecipesTable
          impactCategory={impactCategory}
          onRecipesLoadMore={onRecipesLoadMore}
          recipes={recipes}
          queryParams={queryParams}
          recipeUrl={recipeUrl}
          deleteRecipe={handleDeleteRecipe}
          selectedRecipeIds={selectedRecipeIds}
          onSelectChange={onSelect}
          showCollectionsColumn={showCollectionsColumn}
          showSharedIcon={showSharedIcon}
          waitingForFreshImpacts={
            fetchingUpdatedRecipes && !fetchingUpdatedRecipesFailed
          }
        />
      ) : (
        <ResultsGrid
          impactCategory={impactCategory}
          onSelect={onSelect}
          onRecipesLoadMore={onRecipesLoadMore}
          recipes={recipes}
          selectedRecipeIds={selectedRecipeIds}
          queryParams={queryParams}
          recipeUrl={recipeUrl}
          showSharedIcon={showSharedIcon}
          waitingForFreshImpacts={
            fetchingUpdatedRecipes && !fetchingUpdatedRecipesFailed
          }
        />
      )}
      {deletingRecipe && deletingRecipeId !== null && (
        <DeleteRecipeModal
          recipe={deletingRecipe}
          onDeleted={refresh}
          onHide={() => setDeletingRecipeId(null)}
        />
      )}
    </>
  );
}

interface RecipesPanelControlsProps {
  addDropdown: JSX.Element | any;
  filterByParentCollections?: boolean;
  disableRecipeQueryControls: boolean;
  exportDropdown: JSX.Element | (() => null);
  fetchNextPage: (() => Promise<void>) | null;
  gridListToggle: JSX.Element;
  impactCategory: ImpactCategory;
  recipeFilter: RecipeFilterWithExclude;
  numberOfExcludedRecipes: number;
  numberOfRecipesOnPage: number;
  onSelectAllCheckboxChange: (checked: boolean) => void;
  pageName: string;
  queryParams: RecipesListPageQueryParams;
  selectAllCheckboxState: SelectAllCheckboxState;
  selectAllIncludingUnseen: boolean;
  setImpactCategory: (impactCategory: ImpactCategory) => void;
  setIncludingUnseen: () => void;
  setQueryParams: (update: Partial<RecipesListPageQueryParams>) => void;
  setToastState: (state: CopySuccessToastState) => void;
  showCopyToButton: boolean;
  showFilterToRequiresAttention: boolean;
  showManageTagsButton: boolean;
  showSearch: boolean;
  totalRecipesCount: any;
}

function RecipesPanelControls(props: RecipesPanelControlsProps) {
  const {
    addDropdown,
    filterByParentCollections,
    disableRecipeQueryControls,
    exportDropdown,
    gridListToggle,
    impactCategory,
    recipeFilter,
    onSelectAllCheckboxChange,
    pageName,
    queryParams,
    selectAllCheckboxState,
    setImpactCategory,
    setQueryParams,
    setToastState,
    showCopyToButton,
    showFilterToRequiresAttention,
    showManageTagsButton,
    showSearch,
  } = props;

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const { trackImpactCategorySet } = useTracking();

  const recipeQueryControls = () => (
    <RecipeQueryControls
      filterByParentCollections={filterByParentCollections}
      disabled={disableRecipeQueryControls}
      impactCategory={impactCategory}
      onChange={setQueryParams}
      selectAllCheckbox={
        <SelectAllCheckbox
          value={selectAllCheckboxState}
          onChange={(checked) => onSelectAllCheckboxChange(checked)}
        />
      }
      showFilterToRequiresAttention={showFilterToRequiresAttention}
      showManageTagsButton={showManageTagsButton}
      showSearch={showSearch}
      value={queryParams}
    />
  );

  const handleImpactCategoryChange = (impactCategory: ImpactCategory) => {
    setImpactCategory(impactCategory);
    trackImpactCategorySet({ impactCategory, pageName });
  };

  return (
    <div className="row mx-0 mb-2" style={{ justifyContent: "space-between" }}>
      <div>{recipeQueryControls()}</div>
      {/* position: static is needed so the tool tip in toast in MultipleRecipeCopyToButton displays correctly */}
      <div className="d-flex flex-column" style={{ position: "static" }}>
        <div
          className="d-flex justify-content-end"
          style={{ gap: "14px", height: "40px" }}
        >
          {addDropdown}
          {showCopyToButton && (
            <MultipleRecipeCopyToButton
              recipeFilter={recipeFilter}
              setToastState={setToastState}
            />
          )}
          {exportDropdown}
        </div>
        <div className="d-flex justify-content-end mt-3 mb-3">
          {additionalImpactCategories && (
            <ImpactCategoryToggle
              onChange={handleImpactCategoryChange}
              options={availableImpactCategories}
              selectedImpactCategory={impactCategory}
            />
          )}
          <div className="d-flex align-items-center pl-3">{gridListToggle}</div>
        </div>
      </div>
    </div>
  );
}

const recipeFragment = gql`
  fragment RecipesPanel_Recipe on Recipe {
    id
    impact(excludePackaging: false, fetchStaleImpacts: $fetchStaleImpacts) {
      isStale
    }
    ...DeleteRecipeModal_Recipe
    ...RecipesTable_Recipe
    ...ResultsGrid_Recipe
  }

  ${DeleteRecipeModal.fragments.recipe}
  ${RecipesTable.fragments.recipes}
  ${ResultsGrid.fragments.recipes}
`;

const recipesQuery = gql`
  query RecipesPanel_RecipesQuery(
    $after: String
    $recipeFilter: RecipeFilter!
    $first: Int!
    $orderBy: RecipeOrder!
    $fetchStaleImpacts: Boolean!
  ) {
    recipes(
      after: $after
      first: $first
      filter: $recipeFilter
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...RecipesPanel_Recipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    recipeCount(filter: $recipeFilter)
  }

  ${recipeFragment}
`;

function recipesQueryGetRecipes(data: RecipesQuery) {
  return data.recipes;
}

const recipesPanelQuery = gql`
  query RecipesPanel_RecipesPanelQuery(
    $organizationFilter: OrganizationFilter!
  ) {
    organization(filter: $organizationFilter) {
      recipeCountLimit
      hasReachedRecipeCountLimit
    }
  }
`;

const deleteRecipesMutation = gql`
  mutation RecipesPanel_DeleteRecipes($input: DeleteRecipesInput!) {
    deleteRecipes(input: $input) {
      success
    }
  }
`;
