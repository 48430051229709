import { sum } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

import {
  AssessmentSummary,
  Category,
  ProcuredItem,
  Site,
  Supplier,
} from "../../data-store";
import useUserInfo from "../../data-store/useUserInfo";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import { convertUnitsForDisplay } from "../../util/units";
import {
  getTotalImpactPerSpendAndProportionalChange,
  impactCategoryIcon,
  impactIntensityAndProportionChange,
  Scope3CarouselFootnote,
} from "../corporate-reporting-dashboards/AssessmentOverviewSummary";
import {
  getCurrencySymbol,
  getImpactCategoryImpactRatingProportion,
} from "../corporate-reporting-dashboards/helperFunctions";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import { Carousel } from "../utils/Carousel";
import { Panel } from "../utils/Panel";
import { Apple, Scales, Spend } from "../utils/Vectors/illustrations";
import { impactRatingToName } from "../utils/Vectors/labels/Translations";
import CategoriesTable from "./CategoriesTable";
import { impactMagnitudeAndProportionChange } from "./helperFunctions";
import ProcuredItemsTable from "./ProcuredItemsTable";
import "./ReportContent.css";
import ReportSummaryCard from "./ReportSummaryCard";
import SiteTable from "./SiteTable";
import SuppliersTable from "./SuppliersTable";
import TableOptionToggle, { TableOption, Option } from "./TableOptionToggle";

interface ReportContentProps {
  assessmentSummary: AssessmentSummary;
  categories: Array<Category>;
  categories2: Array<Category>;
  categories3: Array<Category>;
  foodstepsCategories: Array<Category>;
  impactCategory: ImpactCategory;
  procuredItems: Array<ProcuredItem>;
  suppliers: Array<Supplier>;
  sites: Array<Site>;
  tableOption: TableOption;
  handleTableOption: (option: TableOption) => void;
}

export default function ReportContent(props: ReportContentProps) {
  const {
    assessmentSummary,
    categories,
    categories2,
    categories3,
    foodstepsCategories,
    impactCategory,
    procuredItems,
    suppliers,
    sites,
    tableOption,
    handleTableOption,
  } = props;

  const { trackReportBreakdownSet } = useTracking();

  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);
  const intl = useIntl();
  // const pages = usePages();
  // const [queryParams] = pages.Scope3Page.useQueryParams();
  const [{ locale }] = useUserInfo();
  const impactRatingToLongName = useImpactRatingToLongName;

  // const [tableOption, setTableOption] = useState<TableOption>(
  //   queryParams.viewHighestImpactItems ? Option.PROCURED_ITEM : Option.CATEGORY
  // );

  const table = () => {
    if (tableOption === Option.CATEGORY) {
      return (
        <CategoriesTable
          data={categories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:category",
            defaultMessage: "Category",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedCategory",
            defaultMessage: "Unspecified category",
          })}
        />
      );
    } else if (tableOption === Option.CATEGORY_2) {
      return (
        <CategoriesTable
          data={categories2}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:category2",
            defaultMessage: "Category 2",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedCategory",
            defaultMessage: "Unspecified category",
          })}
        />
      );
    } else if (tableOption === Option.CATEGORY_3) {
      return (
        <CategoriesTable
          data={categories3}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:category3",
            defaultMessage: "Category 3",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedCategory",
            defaultMessage: "Unspecified category",
          })}
        />
      );
    } else if (tableOption === Option.FOOD_TYPE) {
      return (
        <CategoriesTable
          data={foodstepsCategories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:foodType",
            defaultMessage: "Food Type",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedFoodType",
            defaultMessage: "Unspecified food type",
          })}
        />
      );
    } else if (tableOption === Option.PROCURED_ITEM) {
      return (
        <ProcuredItemsTable
          data={procuredItems}
          impactCategory={impactCategory}
        />
      );
    } else if (tableOption === Option.SUPPLIER) {
      return (
        <SuppliersTable data={suppliers} impactCategory={impactCategory} />
      );
    } else if (tableOption === Option.SITE) {
      return <SiteTable data={sites} impactCategory={impactCategory} />;
    } else {
      assertNever(tableOption, "Unsupported table type");
    }
  };

  const handleTableTypeChange = (selectedTableType: TableOption | null) => {
    if (selectedTableType === null) {
      throw new Error("Cannot have null table type");
    }
    trackReportBreakdownSet({
      breakdown: selectedTableType,
      reportType: impactCategory,
    });
    handleTableOption(selectedTableType);
  };

  const totalImpactMagnitudeAndProportionChange =
    impactMagnitudeAndProportionChange(assessmentSummary, impactCategory);

  const impactInAppropriateUnits =
    totalImpactMagnitudeAndProportionChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          totalImpactMagnitudeAndProportionChange.value,
          effectType.internalBaseUnits.unitToConvert,
          effectType.internalBaseUnits.otherBaseUnits
        );
  const totalMassInAppropriateUnits = convertUnitsForDisplay(
    intl,
    sum(procuredItems.map((item) => item.massKg)),
    "kg"
  );

  const averageImpactIntensityAndProportionChange =
    impactIntensityAndProportionChange(assessmentSummary, impactCategory);

  const averageImpactInAppropriateUnits =
    averageImpactIntensityAndProportionChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          averageImpactIntensityAndProportionChange.value,
          effectTypePerKg.internalBaseUnits.unitToConvert,
          effectTypePerKg.internalBaseUnits.otherBaseUnits,
          "kg"
        );

  const averageImpactIntensityImpactRating = getImpactCategoryRating(
    assessmentSummary,
    impactCategory
  );
  const proportionVeryLowRatedItemsAndProportionChange =
    getImpactCategoryImpactRatingProportion(
      assessmentSummary,
      impactCategory,
      "VLOW"
    );
  const totalImpactPerSpendAndProportionalChange =
    getTotalImpactPerSpendAndProportionalChange(
      assessmentSummary,
      impactCategory
    );
  const totalImpactPerSpendInAppropriateUnits =
    totalImpactPerSpendAndProportionalChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          totalImpactPerSpendAndProportionalChange.value,
          effectType.internalBaseUnits.unitToConvert,
          effectType.internalBaseUnits.otherBaseUnits,
          assessmentSummary.spendCurrency
        );

  const reportSummaryCards = [
    ...(impactInAppropriateUnits !== null
      ? [
          <ReportSummaryCard
            key="totalImpact"
            icon={impactCategoryIcon(impactCategory)}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:total"
                defaultMessage="Total Impact"
              />
            }
            value={impactInAppropriateUnits.convertedValue}
            unit={impactInAppropriateUnits.unitString}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              totalImpactMagnitudeAndProportionChange.proportionalChange
            }
          />,
        ]
      : []),
    ...(averageImpactIntensityAndProportionChange.value !== null &&
    averageImpactInAppropriateUnits !== null
      ? [
          <ReportSummaryCard
            key="impactIntensity"
            icon={
              averageImpactIntensityImpactRating !== null ? (
                <ImpactRatingDisplay
                  value={averageImpactIntensityImpactRating}
                  text={impactRatingToLongName(
                    averageImpactIntensityImpactRating
                  )}
                />
              ) : (
                impactCategoryIcon(impactCategory)
              )
            }
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:impactIntensity"
                defaultMessage="Impact Intensity*"
              />
            }
            value={averageImpactInAppropriateUnits.convertedValue}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              averageImpactIntensityAndProportionChange.proportionalChange
            }
            unit={averageImpactInAppropriateUnits.unitString}
          />,
        ]
      : []),
    ...(totalImpactPerSpendAndProportionalChange.value !== null &&
    assessmentSummary.spend !== null &&
    assessmentSummary.spendCurrency !== null &&
    totalImpactPerSpendInAppropriateUnits !== null
      ? [
          <ReportSummaryCard
            key="impactPerSpend"
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:impactPerSpend"
                defaultMessage="Impact per Spend**"
              />
            }
            value={totalImpactPerSpendInAppropriateUnits.convertedValue}
            unit={totalImpactPerSpendInAppropriateUnits.unitString}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              totalImpactPerSpendAndProportionalChange.proportionalChange
            }
          />,
        ]
      : []),
    ...(proportionVeryLowRatedItemsAndProportionChange !== null &&
    proportionVeryLowRatedItemsAndProportionChange.value !== null
      ? [
          <ReportSummaryCard
            key="veryLowRatedItems"
            icon={
              <ImpactRatingDisplay
                value="VLOW"
                text={impactRatingToName("VLOW", locale)}
              />
            }
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:veryLowRatedItems"
                defaultMessage="Very Low Rated Items"
              />
            }
            value={intl.formatNumber(
              proportionVeryLowRatedItemsAndProportionChange.value,
              {
                style: "percent",
              }
            )}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              proportionVeryLowRatedItemsAndProportionChange.proportionalChange
            }
          />,
        ]
      : []),
    <ReportSummaryCard
      key="procuredItems"
      icon={<Apple width={32} />}
      title={
        <FormattedMessage
          id="components/scope-3/ReportContent:procuredItems"
          defaultMessage="Procured Items"
        />
      }
      value={intl.formatNumber(assessmentSummary.procuredItemsCount.value)}
      unit={null}
      comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
      proportionChange={assessmentSummary.procuredItemsCount.proportionalChange}
    />,
    <ReportSummaryCard
      key="totalMass"
      icon={<Scales width={32} />}
      title={
        <FormattedMessage
          id="components/scope-3/ReportContent:totalMass"
          defaultMessage="Total Mass*"
        />
      }
      value={totalMassInAppropriateUnits.convertedValue}
      unit={totalMassInAppropriateUnits.unitString}
      comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
      proportionChange={assessmentSummary.totalMass.proportionalChange}
    />,
    ...(assessmentSummary.spend.value !== null &&
    assessmentSummary.spendCurrency !== null
      ? [
          <ReportSummaryCard
            key="totalSpend"
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:totalSpend"
                defaultMessage="Total Spend**"
              />
            }
            value={intl.formatNumber(assessmentSummary.spend.value)}
            unit={getCurrencySymbol(assessmentSummary.spendCurrency)}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={assessmentSummary.spend.proportionalChange}
          />,
        ]
      : []),
  ];

  return (
    <div className="ReportContent">
      <Panel className="ReportContent_Panel">
        <h3>
          <FormattedMessage
            id="components/scope-3/ReportContent:summary"
            defaultMessage="Summary"
          />
        </h3>
        <Carousel
          items={reportSummaryCards}
          footnote={<Scope3CarouselFootnote />}
        />
      </Panel>
      <div>
        <Panel>
          <div className="ReportContent_TableTypeSelect_Container">
            <h3 className="medium-font ReportContent_BreakdownBy">
              <FormattedMessage
                id="components/scope-3/ReportContent:breakdownBy"
                defaultMessage="Breakdown by"
              />
            </h3>
            <TableOptionToggle
              onChange={handleTableTypeChange}
              option={tableOption}
            />
          </div>
          {table()}
        </Panel>
      </div>
    </div>
  );
}
