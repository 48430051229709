import { FormattedMessage, useIntl } from "react-intl";

import {
  AssessmentSummary,
  ValueWithProportionalChange,
} from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import { convertUnitsForDisplay } from "../../util/units";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import { impactMagnitudeAndProportionChange } from "../scope-3/helperFunctions";
import ReportSummaryCard from "../scope-3/ReportSummaryCard";
import { Carousel } from "../utils/Carousel";
import {
  Apple,
  GhgEmissions,
  LandUse,
  Scales,
  Spend,
  WaterUse,
} from "../utils/Vectors/illustrations";
import {
  getCurrencySymbol,
  getImpactCategoryImpactRatingProportion,
} from "./helperFunctions";

interface AssessmentOverviewSummaryProps {
  assessmentSummary: AssessmentSummary;
  impactCategory: ImpactCategory;
}

export function AssessmentOverviewSummary(
  props: AssessmentOverviewSummaryProps
) {
  const { assessmentSummary, impactCategory } = props;
  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);
  const impactRatingToLongName = useImpactRatingToLongName;

  const totalImpactAndProportionalChange = impactMagnitudeAndProportionChange(
    assessmentSummary,
    impactCategory
  );
  const totalImpactInAppropriateUnits =
    totalImpactAndProportionalChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          totalImpactAndProportionalChange.value,
          effectType.internalBaseUnits.unitToConvert,
          effectType.internalBaseUnits.otherBaseUnits
        );
  const averageImpactIntensityAndProportionChange =
    impactIntensityAndProportionChange(assessmentSummary, impactCategory);
  const averageImpactIntensityImpactRating = getImpactCategoryRating(
    assessmentSummary,
    impactCategory
  );
  const averageImpactInAppropriateUnits =
    averageImpactIntensityAndProportionChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          averageImpactIntensityAndProportionChange.value,
          effectTypePerKg.internalBaseUnits.unitToConvert,
          effectTypePerKg.internalBaseUnits.otherBaseUnits,
          "kg"
        );

  const proportionVeryLowRatedItemsAndProportionChange =
    getImpactCategoryImpactRatingProportion(
      assessmentSummary,
      impactCategory,
      "VLOW"
    );

  const totalImpactPerSpendAndProportionalChange =
    getTotalImpactPerSpendAndProportionalChange(
      assessmentSummary,
      impactCategory
    );
  const totalImpactPerSpendInAppropriateUnits =
    totalImpactPerSpendAndProportionalChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          totalImpactPerSpendAndProportionalChange.value,
          effectType.internalBaseUnits.unitToConvert,
          effectType.internalBaseUnits.otherBaseUnits,
          assessmentSummary.spendCurrency
        );

  const totalMassInAppropriateUnits = convertUnitsForDisplay(
    intl,
    assessmentSummary.totalMass.value,
    "kg"
  );

  const reportSummaryCards = [
    ...(totalImpactInAppropriateUnits
      ? [
          <ReportSummaryCard
            icon={impactCategoryIcon(impactCategory)}
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:totalImpact"
                defaultMessage="Total Impact"
              />
            }
            value={totalImpactInAppropriateUnits.convertedValue}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              totalImpactAndProportionalChange.proportionalChange
            }
            unit={totalImpactInAppropriateUnits.unitString}
          />,
        ]
      : []),
    ...(averageImpactIntensityAndProportionChange.value !== null &&
    averageImpactInAppropriateUnits !== null
      ? [
          <ReportSummaryCard
            icon={
              averageImpactIntensityImpactRating !== null ? (
                <ImpactRatingDisplay
                  value={averageImpactIntensityImpactRating}
                  text={impactRatingToLongName(
                    averageImpactIntensityImpactRating
                  )}
                />
              ) : (
                impactCategoryIcon(impactCategory)
              )
            }
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:averageImpactIntensity"
                defaultMessage="Impact Intensity*"
              />
            }
            value={averageImpactInAppropriateUnits.convertedValue}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              averageImpactIntensityAndProportionChange.proportionalChange
            }
            unit={averageImpactInAppropriateUnits.unitString}
          />,
        ]
      : []),
    ...(totalImpactPerSpendAndProportionalChange.value !== null &&
    assessmentSummary.spend !== null &&
    assessmentSummary.spendCurrency !== null &&
    totalImpactPerSpendInAppropriateUnits !== null
      ? [
          <ReportSummaryCard
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:impactPerSpend"
                defaultMessage="Impact per Spend**"
              />
            }
            value={totalImpactPerSpendInAppropriateUnits.convertedValue}
            unit={totalImpactPerSpendInAppropriateUnits.unitString}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              totalImpactPerSpendAndProportionalChange.proportionalChange
            }
          />,
        ]
      : []),
    ...(proportionVeryLowRatedItemsAndProportionChange !== null &&
    proportionVeryLowRatedItemsAndProportionChange.value !== null
      ? [
          <ReportSummaryCard
            icon={
              <ImpactRatingDisplay
                value="VLOW"
                text={impactRatingToLongName("VLOW")}
              />
            }
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:veryLowRatedItems"
                defaultMessage="Very Low Rated Items"
              />
            }
            value={intl.formatNumber(
              proportionVeryLowRatedItemsAndProportionChange.value,
              {
                style: "percent",
              }
            )}
            unit={null}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              proportionVeryLowRatedItemsAndProportionChange.proportionalChange
            }
          />,
        ]
      : []),
    <ReportSummaryCard
      icon={<Apple width={32} />}
      title={
        <FormattedMessage
          id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:procuredItems"
          defaultMessage="Procured Items"
        />
      }
      value={intl.formatNumber(assessmentSummary.procuredItemsCount.value)}
      unit={null}
      comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
      proportionChange={assessmentSummary.procuredItemsCount.proportionalChange}
    />,
    ...(assessmentSummary.spend.value !== null &&
    assessmentSummary.spendCurrency !== null
      ? [
          <ReportSummaryCard
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:totalSpend"
                defaultMessage="Total Spend**"
              />
            }
            value={intl.formatNumber(assessmentSummary.spend.value)}
            unit={getCurrencySymbol(assessmentSummary.spendCurrency)}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={assessmentSummary.spend.proportionalChange}
          />,
        ]
      : []),
    <ReportSummaryCard
      icon={<Scales width={32} />}
      title={
        <FormattedMessage
          id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:totalMass"
          defaultMessage="Total Mass*"
        />
      }
      value={totalMassInAppropriateUnits.convertedValue}
      unit={totalMassInAppropriateUnits.unitString}
      comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
      proportionChange={assessmentSummary.totalMass.proportionalChange}
    />,
  ];

  return (
    <Carousel
      items={reportSummaryCards}
      footnote={<Scope3CarouselFootnote />}
    />
  );
}

export const impactCategoryIcon = (impactCategory: ImpactCategory) => {
  let Icon;
  if (impactCategory === ImpactCategory.GHG) {
    Icon = GhgEmissions;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    Icon = LandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    Icon = WaterUse;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
  return <Icon width={32} />;
};

export function impactIntensityAndProportionChange(
  assessment: {
    ghgIntensity: ValueWithProportionalChange<number | null>;
    landUseIntensity: ValueWithProportionalChange<number | null>;
    waterUseIntensity: ValueWithProportionalChange<number | null>;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.ghgIntensity;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.landUseIntensity;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return assessment.waterUseIntensity;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function getTotalImpactPerSpendAndProportionalChange(
  assessment: {
    ghgPerSpend: ValueWithProportionalChange<number | null>;
    landUsePerSpend: ValueWithProportionalChange<number | null>;
    waterUsePerSpend: ValueWithProportionalChange<number | null>;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.ghgPerSpend;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.landUsePerSpend;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return assessment.waterUsePerSpend;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function Scope3CarouselFootnote() {
  return (
    <div className="Carousel_Footnotes">
      <div>
        <FormattedMessage
          id="components/utils/Carousel:massFootnote"
          defaultMessage="* for all items where mass is provided or can be determined."
        />
      </div>
      <div>
        <FormattedMessage
          id="components/utils/Carousel:spendFootnote"
          defaultMessage="** for all items where spend is provided."
        />
      </div>
    </div>
  );
}
