import VectorProps from "./VectorProps";

export default function ForwardArrow(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7094 15.7243C3.34185 15.3568 3.34185 14.7609 3.7094 14.3933L10.1027 8L3.7094 1.60669C3.34185 1.23914 3.34185 0.643216 3.7094 0.275664C4.07695 -0.0918884 4.67287 -0.0918884 5.04043 0.275664L12.0993 7.33449C12.4668 7.70204 12.4668 8.29796 12.0993 8.66551L5.04043 15.7243C4.67287 16.0919 4.07695 16.0919 3.7094 15.7243Z"
        fill={fill}
      />
    </svg>
  );
}
