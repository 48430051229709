import { useEffect, useState } from "react";

import { Assessment } from "../../data-store";
import useAssessments from "../../data-store/useAssessments";
import { ImpactCategory } from "../../domain/impactCategories";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
} from "../../services/useOrganizationFeatures";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import { sequentialDataColors } from "../graphs/colors";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import ImpactCategoryToggle from "../recipes/ImpactCategoryToggle";
import StatusDisplay from "../StatusDisplay";
import "./ProcurementDashboard.css";
import { AssessmentOverview } from "./AssessmentOverview";
import { ImpactByAssessmentChartCard } from "./ImpactByAssessmentChartCard";
import "./ProcurementDashboard.css";
import "../Page.css";
import ProcurementFilterSelect from "./ProcurementFilterSelect";

const tracking = { pageName: "procurementDashboard" };

export default function ProcurementDashboardPage() {
  const [organizationId] = useOrganizationId();
  const [queryString, setQueryString] = useState("");
  const [assessmentsStatus] = useAssessments(organizationId);
  const pages = usePages();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();

  const [value, setValue] = useState({
    filterToSelectedCategories: [],
    filterToSelectedFoodTypes: [],
    filterToSelectedSuppliers: [],
    filterToSelectedSites: [],
  });

  useEffect(() => {
    const buildQueryString = (filters: Record<string, string[]>) => {
      filters = {
        ...filters,
        // The backend needs three separate lists of category ids, because it's possible that
        // e.g. the string "Beef" is both a category-2 and a category-3.
        // This was the easiest way to separate the lists to unblock me.  Feel free to change.
        // Example strings in filters.filterToSelectedCategories: "0/Beef", "0/Chicken", "1/UK-sourced", ...
        filterToSelectedCategories: filters.filterToSelectedCategories
          .filter((x) => x[0] === "0")
          .map((x) => x.slice(2)),
        filterToSelectedCategories2: filters.filterToSelectedCategories
          .filter((x) => x[0] === "1")
          .map((x) => x.slice(2)),
        filterToSelectedCategories3: filters.filterToSelectedCategories
          .filter((x) => x[0] === "2")
          .map((x) => x.slice(2)),
      };
      return Object.entries(filters)
        .filter(([, values]) => values.length > 0) // only include non-empty arrays
        .map(
          ([key, values]) =>
            `${encodeURIComponent(key)}=${values
              .map(encodeURIComponent)
              .join(",")}`
        )
        .join("&");
    };

    setQueryString(buildQueryString(value));
  }, [value]);

  const impactCategoryToggle = additionalImpactCategories && (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={availableImpactCategories}
      selectedImpactCategory={impactCategory}
    />
  );

  const onChange = (input: any) => {
    setValue(input);
  };

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      <div className="StickyPageTitle ProcurementDashboard_Header">
        <Page.Title
          breadcrumb={pages.ProcurementDashboard.breadcrumb()}
          title={pages.ProcurementDashboard.title}
          inlineComponentEnd={
            <div style={{ display: "flex", gap: 20 }}>
              <ProcurementFilterSelect
                containerStyle={{
                  right: "0px",
                  borderRadius: "8px",
                }}
                filterByParentCollections={false}
                showGeneralStatusSection
                showManageTagsButton
                setFilters={(
                  filterToSelectedCategories: Array<string>,
                  filterToSelectedFoodTypes: Array<string>,
                  filterToSelectedSuppliers: Array<string>,
                  filterToSelectedSites: Array<string>
                ) =>
                  onChange({
                    filterToSelectedCategories,
                    filterToSelectedFoodTypes,
                    filterToSelectedSuppliers,
                    filterToSelectedSites,
                  })
                }
                filters={{
                  filterToSelectedCategories: value.filterToSelectedCategories,
                  filterToSelectedFoodTypes: value.filterToSelectedFoodTypes,
                  filterToSelectedSuppliers: value.filterToSelectedSuppliers,
                  filterToSelectedSites: value.filterToSelectedSites,
                }}
                menuIsOpen={menuIsOpen}
                setMenuIsOpen={setMenuIsOpen}
                hideAttention
              />
              <div>{impactCategoryToggle}</div>
            </div>
          }
          rightAlignEndComponent={true}
        />
      </div>
      <StatusDisplay.Many<[Array<Assessment>]> statuses={[assessmentsStatus]}>
        {(assessments) => {
          const assessmentFilter = (assessment: Assessment) => {
            if (impactCategory === ImpactCategory.GHG) {
              return assessment.show_scope3_report;
            } else if (impactCategory === ImpactCategory.LAND_USE) {
              return assessment.show_land_use_report;
            } else if (impactCategory === ImpactCategory.WATER_USE) {
              return assessment.show_water_use_report;
            } else {
              assertNever(impactCategory, "invalid ImpactCategory");
            }
          };

          assessments = assessments.filter(assessmentFilter);
          return (
            <ProcurementDashboardContent
              assessments={assessments}
              impactCategory={impactCategory}
              filters={value}
              queryString={queryString}
            />
          );
        }}
      </StatusDisplay.Many>
    </Page>
  );
}

interface ProcurementDashboardContentProps {
  assessments: Array<Assessment>;
  impactCategory: ImpactCategory;
  filters?: {
    filterToSelectedCategories: string[];
    filterToSelectedFoodTypes: string[];
    filterToSelectedSuppliers: string[];
    filterToSelectedSites: string[];
  };
  queryString?: string;
}

export function ProcurementDashboardContent(
  props: ProcurementDashboardContentProps
) {
  const { assessments, impactCategory, filters, queryString } = props;
  const sortedAssessments = assessments.sort(
    (a, b) =>
      Date.parse(b.period_covered_start) - Date.parse(a.period_covered_start)
  );
  const assessmentIdToColourMap = new Map<string, string>(
    mapNotNull(sortedAssessments, (assessment, index) => [
      assessment.id,
      sequentialDataColors[index % sequentialDataColors.length],
    ])
  );
  return (
    <div className="ProcurementDashboardContent">
      <ImpactByAssessmentChartCard
        filters={filters}
        queryString={queryString}
        sortedAssessments={sortedAssessments}
        impactCategory={impactCategory}
        assessmentIdToColourMap={assessmentIdToColourMap}
      />
      <AssessmentOverview
        filters={filters}
        queryString={queryString}
        assessments={sortedAssessments}
        impactCategory={impactCategory}
        assessmentIdToColourMap={assessmentIdToColourMap}
      />
    </div>
  );
}
