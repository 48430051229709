import { useIntl } from "react-intl";

import { Category } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { Table, TableColumn } from "../utils/Table";
import { intlFormatNumber } from "./helperFunctions";

interface CategoriesTableProps {
  categoryColumnLabel: string;
  data: Array<Category>;
  impactCategory: ImpactCategory;
  unspecifiedCategoryLabel: string;
}

export default function CategoriesTable(props: CategoriesTableProps) {
  const { categoryColumnLabel, data, unspecifiedCategoryLabel } = props;

  const intl = useIntl();

  const columns: Array<TableColumn<Category>> = [
    {
      key: "category",
      label: categoryColumnLabel,
      renderCell: (category) => category.name ?? unspecifiedCategoryLabel,
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "landUseChangeCO2",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:landUseChangeCO2",
        defaultMessage: "Land Use Change - CO2",
      }),
      units: "kg CO2e",
      renderCell: (category) =>
        intlFormatNumber(intl, Number(category.landUseChangeCO2)),
      sortComparator: comparators.map(
        (item) => item.landUseChangeCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCO2",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:landManagementCO2",
        defaultMessage: "Land Management - CO2",
      }),
      units: "kg CO2e",
      renderCell: (category) =>
        intlFormatNumber(intl, Number(category.landManagementCO2)),
      sortComparator: comparators.map(
        (item) => item.landManagementCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCH4",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:landManagementCH4",
        defaultMessage: "Land Management - CH4",
      }),
      units: "kg CO2e",
      renderCell: (category) =>
        intlFormatNumber(intl, Number(category.landManagementCH4)),
      sortComparator: comparators.map(
        (item) => item.landManagementCH4 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementN2O",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:landManagementN2O",
        defaultMessage: "Land Management - N20",
      }),
      units: "kg CO2e",
      renderCell: (category) =>
        intlFormatNumber(intl, Number(category.landManagementN2O)),
      sortComparator: comparators.map(
        (item) => item.landManagementN2O ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "flagTotal",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:flagTotal",
        defaultMessage: "FLAG Total",
      }),
      renderCell: (category) =>
        intlFormatNumber(intl, Number(category.flagTotal)),
      sortComparator: comparators.map(
        (item) => item.flagTotal ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "energy",
      label: intl.formatMessage({
        id: "components/flag/CategoriesTable:energyIndustry",
        defaultMessage: "Energy and Industry",
      }),
      renderCell: (category) => intlFormatNumber(intl, Number(category.energy)),
      sortComparator: comparators.map(
        (item) => item.energy ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
  ];

  return (
    <Table<Category>
      fullWidth
      columns={columns}
      rowKey={(item) => item.name}
      rows={data}
    />
  );
}
