import React from "react";
import { FormattedMessage } from "react-intl";

import { ImpactRating } from "../../__generated__/globalTypes";
import useCollections from "../../data-store/useCollections";
import { useParentCollections } from "../../data-store/useParentCollections";
import useUserInfo from "../../data-store/useUserInfo";
import { ImpactCategory } from "../../domain/impactCategories";
import { useImpactRatingToLongName } from "../../domain/impactRatings";
import { useProductTags } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import { CollectionToSelect } from "../recipes/RecipeQueryControls.graphql";
import StatusDisplay from "../StatusDisplay";
import Accordion from "../utils/Accordion";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import { AttentionTriangle } from "../utils/Vectors";
import { impactRatingToLetter } from "../utils/Vectors/labels/Translations";
import { orderedDietsAndMessages } from "./Diets";
import { ProductFilterSelect_Collection } from "./ProductFilterSelect.graphql";
import "./ProductFilterSelect.css";
import TagsFilterSection from "./TagFilterSection";

interface SegmentedProductFilterMenuListProps {
  filterByParentCollections?: boolean;
  setFilters: (
    filterToSelectedCollectionIds: Array<number>,
    filterToRequiresClientAttention: boolean,
    intersectCollections: boolean,
    filterToSelectedGhgImpactRatings: Array<string>,
    filterToSelectedLandUseImpactRatings: Array<string>,
    filterToSelectedWaterUseImpactRatings: Array<string>,
    filterToSelectedDiets: Array<string>
  ) => void;
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  onMenuClose: () => void;
  needsAttention: boolean;
  setNeedsAttention: (needsAttention: boolean) => void;
  selectedCollectionIds: Array<number>;
  setSelectedCollectionIds: (selectedCollectionIds: Array<number>) => void;
  intersectCollections: boolean;
  setIntersectCollections: (intersectCollections: boolean) => void;
  selectedGhgImpactRatings: Array<string>;
  setSelectedGhgImpactRatings: (
    selectedGhgImpactRatings: Array<string>
  ) => void;
  selectedLandUseImpactRatings: Array<string>;
  setSelectedLandUseImpactRatings: (
    selectedLandUseImpactRatings: Array<string>
  ) => void;
  selectedWaterUseImpactRatings: Array<string>;
  setSelectedWaterUseImpactRatings: (
    selectedWaterUseImpactRatings: Array<string>
  ) => void;
  selectedDiets: Array<string>;
  setSelectedDiets: (selectedDiets: Array<string>) => void;
  containerStyle?: React.CSSProperties;
  hideAttention?: boolean;
}

export const SegmentedProductFilterMenuList = (
  props: SegmentedProductFilterMenuListProps
) => {
  const {
    filterByParentCollections = false,
    setFilters,
    onMenuClose,
    needsAttention,
    setNeedsAttention,
    selectedCollectionIds,
    setSelectedCollectionIds,
    intersectCollections,
    setIntersectCollections,
    selectedGhgImpactRatings,
    setSelectedGhgImpactRatings,
    selectedLandUseImpactRatings,
    setSelectedLandUseImpactRatings,
    selectedWaterUseImpactRatings,
    setSelectedWaterUseImpactRatings,
    selectedDiets,
    setSelectedDiets,
    containerStyle,
    hideAttention,
  } = props;

  const [{ locale }] = useUserInfo();
  const productTagsEnabled = useProductTags();
  const impactRatingToLongName = useImpactRatingToLongName;

  const onImpactRatingChange = (
    impactCategory: ImpactCategory,
    impactRating: ImpactRating
  ) => {
    if (impactCategory === ImpactCategory.GHG) {
      if (selectedGhgImpactRatings.includes(impactRating)) {
        setSelectedGhgImpactRatings(
          selectedGhgImpactRatings.filter((r) => r !== impactRating)
        );
      } else {
        setSelectedGhgImpactRatings([
          ...selectedGhgImpactRatings,
          impactRating,
        ]);
      }
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      if (selectedLandUseImpactRatings.includes(impactRating)) {
        setSelectedLandUseImpactRatings(
          selectedLandUseImpactRatings.filter((r) => r !== impactRating)
        );
      } else {
        setSelectedLandUseImpactRatings([
          ...selectedLandUseImpactRatings,
          impactRating,
        ]);
      }
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      if (selectedWaterUseImpactRatings.includes(impactRating)) {
        setSelectedWaterUseImpactRatings(
          selectedWaterUseImpactRatings.filter((r) => r !== impactRating)
        );
      } else {
        setSelectedWaterUseImpactRatings([
          ...selectedWaterUseImpactRatings,
          impactRating,
        ]);
      }
    }
  };

  const isImpactRatingSelected = (
    impactCategory: ImpactCategory,
    impactRating: ImpactRating
  ) => {
    if (impactCategory === ImpactCategory.GHG) {
      return selectedGhgImpactRatings.includes(impactRating);
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return selectedLandUseImpactRatings.includes(impactRating);
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return selectedWaterUseImpactRatings.includes(impactRating);
    } else {
      assertNever(impactCategory, "Unsupported impact category");
    }
  };

  const { trackFilterProductsApplied } = useTracking();

  const useFilterCollections = filterByParentCollections
    ? useParentCollections
    : useCollections;
  const { collectionsStatus } = useFilterCollections();

  const onApplyClick = (allCollections: ProductFilterSelect_Collection[]) => {
    // if a collection was deleted after it was selected, this makes sure we don't filter by it
    const notDeletedCollections = selectedCollectionIds.filter(
      (selectedCollection) =>
        allCollections.find(
          (collection) => collection.id === selectedCollection
        )
    );
    trackFilterProductsApplied({
      needsAttention,
      numberOfCollections: notDeletedCollections?.length ?? 0,
      intersectCollections,
      selectedGhgImpactRatings,
      selectedLandUseImpactRatings,
      selectedWaterUseImpactRatings,
      selectedDiets,
    });
    setFilters(
      notDeletedCollections && notDeletedCollections.length > 0
        ? notDeletedCollections
        : [],
      needsAttention,
      intersectCollections,
      selectedGhgImpactRatings,
      selectedLandUseImpactRatings,
      selectedWaterUseImpactRatings,
      selectedDiets
    );
    onMenuClose();
  };

  const onClearFiltersClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedCollectionIds([]);
    setNeedsAttention(false);
    setSelectedGhgImpactRatings([]);
    setSelectedLandUseImpactRatings([]);
    setSelectedWaterUseImpactRatings([]);
    setSelectedDiets([]);
    // Leave the any/all choice as last chosen
    e.currentTarget.blur();
  };

  const tagsFilterSection = (collections: Array<CollectionToSelect>) => (
    <TagsFilterSection
      collections={collections}
      selectedCollectionIds={selectedCollectionIds}
      setSelectedCollectionIds={setSelectedCollectionIds}
      intersectCollections={intersectCollections}
      setIntersectCollections={setIntersectCollections}
    />
  );

  const impactRatingOptions = (impactCategory: ImpactCategory) =>
    [
      ImpactRating.VLOW,
      ImpactRating.LOW,
      ImpactRating.MEDIUM,
      ImpactRating.HIGH,
      ImpactRating.VHIGH,
    ].map((impactRating) => ({
      id: "1",
      name: (
        <div>
          {impactRatingToLetter(impactRating, locale)}
          {" - "}
          {impactRatingToLongName(impactRating)}
        </div>
      ),
      checked: isImpactRatingSelected(impactCategory, impactRating),
      onChange: () => onImpactRatingChange(impactCategory, impactRating),
    }));

  const handleDietCheckboxChange = (diet: string) => {
    if (selectedDiets?.includes(diet)) {
      setSelectedDiets([...selectedDiets.filter((id) => id !== diet)]);
    } else {
      setSelectedDiets([...(selectedDiets || []), diet]);
    }
  };

  const menuItems = (collections: Array<CollectionToSelect>) => {
    return [
      ...(productTagsEnabled
        ? [
            {
              eventKey: "tag",
              // We don't use the Accordion options here, and instead
              // handle selection state in the custom section using callbacks
              options: [],
              title: (
                <FormattedMessage
                  id="components/product-filter/SegmentedProductFilterMenuList:tag"
                  defaultMessage="Tag"
                />
              ),
              customContent: tagsFilterSection(collections),
              overrideCount: selectedCollectionIds.length,
            },
          ]
        : []),
      {
        eventKey: "ghgRating",
        options: impactRatingOptions(ImpactCategory.GHG),
        title: (
          <FormattedMessage
            id="components/product-filter/SegmentedProductFilterMenuList:ghgRating"
            defaultMessage="GHG Rating"
          />
        ),
      },
      {
        eventKey: "landUseRating",
        options: impactRatingOptions(ImpactCategory.LAND_USE),
        title: (
          <FormattedMessage
            id="components/product-filter/SegmentedProductFilterMenuList:landUseRating"
            defaultMessage="Land Use Rating"
          />
        ),
      },
      {
        eventKey: "waterUseRating",
        options: impactRatingOptions(ImpactCategory.WATER_USE),
        title: (
          <FormattedMessage
            id="components/product-filter/SegmentedProductFilterMenuList:waterUseRating"
            defaultMessage="Water Use Rating"
          />
        ),
      },
      {
        eventKey: "diet",
        title: (
          <FormattedMessage
            id="components/product-filter/SegmentedProductFilterMenuList:diet"
            defaultMessage="Diet"
          />
        ),
        options: orderedDietsAndMessages.map(([diet, message]) => ({
          id: diet,
          name: message,
          checked: selectedDiets.includes(diet),
          onChange: () => handleDietCheckboxChange(diet),
        })),
      },
      ...(hideAttention
        ? []
        : [
            {
              eventKey: "alerts",
              options: [
                {
                  id: "1",
                  name: (
                    <div className="d-flex flex-row">
                      <AttentionTriangle className="my-auto mr-2" width={20} />
                      <FormattedMessage
                        id="components/product-filter/ProductFilterSelect:filterToRequiresClientAttention"
                        defaultMessage="Needs attention"
                      />
                    </div>
                  ),
                  checked: needsAttention,
                  onChange: () => setNeedsAttention(!needsAttention),
                },
              ],
              title: (
                <FormattedMessage
                  id="components/product-filter/SegmentedProductFilterMenuList:alerts"
                  defaultMessage="Alerts"
                />
              ),
            },
          ]),
    ];
  };

  return (
    <div
      style={containerStyle}
      className="ProductFilterMenuList text-body react-select__menu"
    >
      <div className="Header">
        <h4 className="Title">
          <FormattedMessage
            id="components/product-filter/ProductFilterSelect:Title"
            defaultMessage="Filter by"
          />
        </h4>
      </div>
      <StatusDisplay status={collectionsStatus}>
        {(collections) => {
          return (
            <>
              <div className="FilterSection">
                <Accordion items={menuItems(collections)} />
              </div>

              <hr className="Divider" />

              <div className="EndButtons">
                <PrimaryButton onClick={() => onApplyClick(collections)}>
                  {" "}
                  <FormattedMessage
                    id="components/product-filter/ProductFilterSelect:ApplyButtonText"
                    defaultMessage="Apply"
                  />
                </PrimaryButton>
                <SecondaryButton onClick={onClearFiltersClick}>
                  <FormattedMessage
                    id="components/product-filter/ProductFilterSelect:ClearFiltersButtonText"
                    defaultMessage="Clear filters"
                  />
                </SecondaryButton>
              </div>
            </>
          );
        }}
      </StatusDisplay>
    </div>
  );
};
