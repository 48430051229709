import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useSites(assessmentId?: string, filters?: string) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportSites(
      organizationId,
      assessmentId,
      filters
    );
  }, [assessmentId, dataStore, organizationId, filters]);

  return usePromise(load);
}
