import classNames from "classnames";

import assertNever from "../../util/assertNever";
import { BackArrow, ForwardArrow } from "./Vectors";
import "./ChevronButton.css";

type ChevronButtonProps = {
  disabled?: boolean;
  direction: "back" | "forward";
  onClick: () => void;
};

export default function ChevronButton(props: ChevronButtonProps) {
  const { disabled = false, direction, onClick } = props;

  const icon = () => {
    if (direction === "back") {
      return <BackArrow width={16} />;
    } else if (direction === "forward") {
      return <ForwardArrow width={16} />;
    } else {
      assertNever(direction, "Unsupported direction");
    }
  };

  return (
    <div
      className={classNames("ChevronButton", {
        ChevronButton_Disabled: disabled,
      })}
      onClick={disabled ? undefined : onClick}
    >
      {icon()}
    </div>
  );
}
