import { AreaWrapper } from "./AreaChart/AreaWrapper";
import BarChart2 from "./BarChart2";
import ButterflyChart from "./ButterflyChart";
import { sequentialDataColors } from "./colors";

const stories = {
  name: "Graphs",
  children: [
    {
      name: "BarChart2",
      children: [
        { name: "Default", render: () => <BarChart2Story /> },
        {
          name: "Horizontal",
          render: () => <BarChart2Story horiztonal />,
        },
        {
          name: "Colors",
          render: () => <BarChart2Story colors />,
        },
        {
          name: "Stacked",
          render: () => <BarChart2Story stacked />,
        },
        {
          name: "Sorted",
          render: () => <BarChart2Story sorted />,
        },
        {
          name: "Sorted with maximum number of bars",
          render: () => <BarChart2Story sorted maxBars={10} />,
        },
      ],
    },
    {
      name: "ButterflyChart",
      children: [
        {
          name: "Normal",
          render: () => (
            <ButterflyChart
              data={[
                {
                  value1: 1,
                  value2: { type: "success", value: 2.25 },
                  key: "Test 1",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 5,
                  value2: { type: "none" },
                  key: "Test 2",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 5,
                  value2: { type: "pending" },
                  key: "Test 3",
                  pendingMessage: "Pending...",
                },
                {
                  value1: null,
                  value2: { type: "success", value: 2.25 },
                  key: "Test 4",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 1.0,
                  value2: { type: "unavailable" },
                  key: "Test 5",
                  pendingMessage: "Pending...",
                },
              ]}
              header={{
                left: <h4>This thing</h4>,
                center: <h4>vs.</h4>,
                right: <h4>That thing</h4>,
              }}
              unavailableValueMessage={"Unavailable"}
            />
          ),
        },
        {
          name: "Loading",
          render: () => (
            <ButterflyChart
              data={[
                {
                  value1: 1,
                  value2: { type: "loading" },
                  key: "Loading",
                  pendingMessage: "Pending...",
                },
              ]}
              header={{
                left: <h4>This thing</h4>,
                center: <h4>vs.</h4>,
                right: <h4>That thing</h4>,
              }}
              unavailableValueMessage={"Unavailable"}
            />
          ),
        },
      ],
    },
    {
      name: "Area chart",
      children: [
        {
          name: "wrapper",
          render: () => <AreaWrapper />,
        },
      ],
    },
  ],
};

const normalBars = [
  { label: "Apple", value: [10] },
  { label: "Banana", value: [20] },
  { label: "Orange", value: [30] },
  { label: "A Fruit With a Very Very Long Name", value: [40] },
  { label: "Strawberry", value: [20] },
  { label: "Blueberry", value: [80] },
  { label: "Raspberry", value: [10] },
  { label: "Cherry", value: [40] },
  { label: "Peach", value: [90] },
  { label: "Mango", value: [10] },
  { label: "Watermelon", value: [80] },
  { label: "Kiwi Fruit", value: [25] },
  { label: "Pineapple", value: [10] },
  { label: "Pear", value: [20] },
  { label: "Plum", value: [30] },
];

const stackedBars = [
  { label: "Apple", value: [10] },
  { label: "Banana", value: [20, 30] },
  { label: "Orange", value: [5, 17, 20] },
  { label: "Grape", value: [40, 5, 5] },
  { label: "Strawberry", value: [20] },
];

interface BarChart2StoryProps {
  colors?: boolean;
  horiztonal?: boolean;
  maxBars?: number;
  stacked?: boolean;
  sorted?: boolean;
}

function BarChart2Story(props: BarChart2StoryProps) {
  const {
    colors = false,
    horiztonal = false,
    maxBars,
    sorted,
    stacked = false,
  } = props;

  let bars = stacked ? stackedBars : normalBars;

  bars = colors
    ? bars.map((bar, index) => {
        return {
          ...bar,
          color: sequentialDataColors[index % sequentialDataColors.length],
        };
      })
    : bars;

  return (
    <BarChart2
      bars={bars}
      dependentAxisLabel="Number of items"
      horizontal={horiztonal}
      maxBars={maxBars}
      sorted={sorted}
    />
  );
}

export default stories;
