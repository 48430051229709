import { ChartType, Plugin } from "chart.js";
import { Dispatch, SetStateAction } from "react";

export function createClickableXAxisPlugin<T extends ChartType>({
  id = "clickableXAxis",
  selectedLabelIndexRef,
  setSelectedLabelIndex,
  setSelectedLabelIndexPos,
  dataLabels,
  xAxisMin,
  xAxisMax,
}: {
  id?: string;
  selectedLabelIndexRef: React.MutableRefObject<number | null>;
  setSelectedLabelIndex: Dispatch<SetStateAction<number | null>>;
  setSelectedLabelIndexPos: Dispatch<
    SetStateAction<{ x: number | null; y: number | null }>
  >;
  dataLabels: string[];
  xAxisMin?: number;
  xAxisMax?: number;
}): Plugin<T> {
  return {
    id,
    afterEvent(chart: any, args: any) {
      const { event } = args;
      if (!event) return;

      const xScale = chart.scales.x;
      const yScale = chart.scales.y;
      const { x, y } = event;

      // Ensure click is inside the x-axis labels area
      if (y < xScale.bottom + 0 && y > xScale.bottom - 50) {
        let closestIndex: number | null = null;
        let closestDistance = Infinity;
        let customLabels = [...dataLabels];

        if (xAxisMin && xAxisMax) {
          customLabels = customLabels.slice(
            chart.options.scales.x.min,
            chart.options.scales.x.max + 1
          );
        }

        customLabels.forEach((_: string, index: number) => {
          const tickX = xScale.getPixelForTick(index);
          const distance = Math.abs(x - tickX);

          if (distance < closestDistance && distance < 10) {
            closestIndex = index;
            closestDistance = distance;
          }
        });

        // Only update state if the selected index has changed
        if (closestIndex !== selectedLabelIndexRef.current) {
          selectedLabelIndexRef.current = closestIndex;

          setSelectedLabelIndex(closestIndex);
          setSelectedLabelIndexPos({
            x:
              closestIndex !== null
                ? xScale.getPixelForTick(closestIndex)
                : null,
            y:
              closestIndex !== null
                ? yScale.getPixelForTick(closestIndex)
                : null,
          });

          chart.$selectedLabelIndex =
            chart.$selectedLabelIndex === closestIndex ? null : closestIndex;
        }
      } else {
        if (selectedLabelIndexRef.current !== null) {
          selectedLabelIndexRef.current = null;
          setSelectedLabelIndex(null);
          setSelectedLabelIndexPos({ x: null, y: null });
        }
      }
    },
  };
}
