import { ChartType, Plugin, Tick } from "chart.js";

export function createCustomLabelBackgroundPlugin<T extends ChartType>({
  id = "customLabelBackground",
  selectedLabelIndexRef,
  backgroundColor = "#888",
}: {
  id?: string;
  selectedLabelIndexRef: React.MutableRefObject<number | null>;
  backgroundColor?: string;
}): Plugin<T> {
  return {
    id,
    afterDraw(chart) {
      const ctx = chart.ctx;
      const xScale = chart.scales.x;

      ctx.font = "12px"; // Ensure font is set before measuring text

      xScale.ticks.forEach((tick: Tick, index: number) => {
        const label = tick.label as string; // Get the label text
        const labelX = xScale.getPixelForTick(index);
        const labelY = xScale.bottom - 40; // Positioning
        const paddingX = 10;
        const paddingY = 8;
        const radius = 8;

        // Measure text width
        const textWidth = ctx.measureText(label).width;
        const textHeight = 14; // Approximate text height
        const rectWidth = textWidth + 2 * paddingX;
        const rectHeight = textHeight + 2 * paddingY;

        ctx.save();
        ctx.fillStyle =
          selectedLabelIndexRef.current === index ? "black" : "transparent"; // Background color
        ctx.strokeStyle = "transparent"; // Border color
        ctx.lineWidth = 2;

        // Draw rounded rectangle **around each label individually**
        ctx.beginPath();
        ctx.moveTo(labelX - rectWidth / 2 + radius, labelY);
        ctx.lineTo(labelX + rectWidth / 2 - radius, labelY);
        ctx.quadraticCurveTo(
          labelX + rectWidth / 2,
          labelY,
          labelX + rectWidth / 2,
          labelY + radius
        );
        ctx.lineTo(labelX + rectWidth / 2, labelY + rectHeight - radius);
        ctx.quadraticCurveTo(
          labelX + rectWidth / 2,
          labelY + rectHeight,
          labelX + rectWidth / 2 - radius,
          labelY + rectHeight
        );
        ctx.lineTo(labelX - rectWidth / 2 + radius, labelY + rectHeight);
        ctx.quadraticCurveTo(
          labelX - rectWidth / 2,
          labelY + rectHeight,
          labelX - rectWidth / 2,
          labelY + rectHeight - radius
        );
        ctx.lineTo(labelX - rectWidth / 2, labelY + radius);
        ctx.quadraticCurveTo(
          labelX - rectWidth / 2,
          labelY,
          labelX - rectWidth / 2 + radius,
          labelY
        );
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        // Draw label text
        ctx.fillStyle =
          selectedLabelIndexRef.current === index ? "#fff" : backgroundColor; // Text color
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(label, labelX, labelY + rectHeight / 2);

        ctx.restore();
      });
    },
  };
}
