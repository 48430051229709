import { useIntl } from "react-intl";

import PercentageLozenge from "./PercentageLozenge";

const stories = {
  name: "Percentage Lozenge",
  children: [
    {
      name: "Good increase",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={3} intl={intl} sentiment="positive" />
          );
        };
      },
    },
    {
      name: "Bad increase",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={3} intl={intl} sentiment="negative" />
          );
        };
      },
    },
    {
      name: "Neutral increase",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={3} intl={intl} sentiment="neutral" />
          );
        };
      },
    },
    {
      name: "Good decrease",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={-3} intl={intl} sentiment="positive" />
          );
        };
      },
    },
    {
      name: "Bad decrease",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={-3} intl={intl} sentiment="negative" />
          );
        };
      },
    },
    {
      name: "Neutral decrease",
      render: () => {
        return () => {
          const intl = useIntl();
          return (
            <PercentageLozenge amount={-3} intl={intl} sentiment="neutral" />
          );
        };
      },
    },
  ],
};

export default stories;
