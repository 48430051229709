import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Category, Site, Supplier } from "../../data-store";
import useCategories from "../../data-store/useCategories";
import useSites from "../../data-store/useSites";
import useSuppliers from "../../data-store/useSuppliers";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import BarChart2, { Bar } from "../graphs/BarChart2";
import { impactMagnitude } from "../scope-3/helperFunctions";
import TableOptionToggle, {
  TableOption,
  Option,
} from "../scope-3/TableOptionToggle";
import StatusDisplay from "../StatusDisplay";
import "./ProcurementImpactBreakdownChart.css";

interface ProcurementImpactBreakdownChartProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
  filters?: {
    filterToSelectedCategories: string[];
    filterToSelectedFoodTypes: string[];
    filterToSelectedSuppliers: string[];
    filterToSelectedSites: string[];
  };
  queryString?: string;
}

type ProcurementImpactBreakdownChartTableOption =
  | Option.CATEGORY
  | Option.CATEGORY_2
  | Option.CATEGORY_3
  | Option.SUPPLIER
  | Option.SITE;
const allProcurementImpactBreakdownChartTableOptions: Array<ProcurementImpactBreakdownChartTableOption> =
  [
    Option.CATEGORY,
    Option.CATEGORY_2,
    Option.CATEGORY_3,
    Option.SUPPLIER,
    Option.SITE,
  ];

export function ProcurementImpactBreakdownChart(
  props: ProcurementImpactBreakdownChartProps
) {
  const { assessmentId, impactCategory, queryString } = props;

  const intl = useIntl();
  const [categoriesStatus] = useCategories(assessmentId, queryString);
  const [suppliersStatus] = useSuppliers(assessmentId, queryString);
  const [sitesStatus] = useSites(assessmentId, queryString);

  const [tableOption, setTableOption] =
    useState<ProcurementImpactBreakdownChartTableOption>(Option.CATEGORY);

  const effectType = impactCategoryToEffectType(impactCategory);

  const dependentAxisLabel = `${effectType.title(intl)} ${effectType.largeUnit(
    intl
  )}`;

  const bars = (groups: Array<Category | Supplier>): Array<Bar> => {
    return groups.map((group) => {
      const impact = impactMagnitude(group, impactCategory);
      const convertedImpact =
        impact === null ? null : impact / effectType.largeUnitConversionFactor;
      return { label: group.name, value: [convertedImpact] };
    });
  };

  const noDataMessage = intl.formatMessage({
    id: "components/scope-3/ProcurementImpactBreakdownChart/noDataMessage",
    defaultMessage: "No data available",
  });

  const handleTableOptionChange = (option: TableOption) => {
    if (
      option !== Option.CATEGORY &&
      option !== Option.CATEGORY_2 &&
      option !== Option.CATEGORY_3 &&
      option !== Option.SUPPLIER &&
      option !== Option.SITE
    ) {
      throw new Error("Invalid table option");
    }
    setTableOption(option);
  };

  const barChartHeight = 80;

  return (
    <>
      <div className="ProcurementImpactBreakdownChart_Header">
        <h3>
          <FormattedMessage
            id="components/scope-3/ProcurementImpactBreakdownChart/title"
            defaultMessage="Procurement Impact by"
          />
        </h3>
        <TableOptionToggle
          option={tableOption}
          onChange={handleTableOptionChange}
          options={allProcurementImpactBreakdownChartTableOptions}
        />
      </div>
      <StatusDisplay.Many<
        [
          Array<Array<Category>> | null,
          Array<Supplier> | null,
          Array<Site> | null
        ]
      >
        statuses={[categoriesStatus, suppliersStatus, sitesStatus]}
      >
        {(categories, suppliers, sites) => {
          if (tableOption === Option.CATEGORY) {
            return categories ? (
              <BarChart2
                bars={bars(categories[0])}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else if (tableOption === Option.CATEGORY_2) {
            return categories ? (
              <BarChart2
                bars={bars(categories[1])}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else if (tableOption === Option.CATEGORY_3) {
            return categories ? (
              <BarChart2
                bars={bars(categories[2])}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else if (tableOption === Option.SUPPLIER) {
            return suppliers ? (
              <BarChart2
                bars={bars(suppliers)}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else if (tableOption === Option.SITE) {
            return sites ? (
              <BarChart2
                bars={bars(sites)}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else {
            assertNever(tableOption, "Unsupported table option");
          }
        }}
      </StatusDisplay.Many>
    </>
  );
}
