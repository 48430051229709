import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useFoodTypes(
  assessmentId?: string,
  queryString?: string
) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportFoodTypes(
      organizationId,
      assessmentId,
      queryString
    );
  }, [assessmentId, dataStore, organizationId, queryString]);

  return usePromise(load);
}
