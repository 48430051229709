import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import "./App.scss";
import AccountDetailsPage from "./components/account/AccountDetailsPage";
import ContactUsPage from "./components/account/ContactUsPage";
import {
  OAuth2AuthorizedPage,
  OAuth2AuthorizeRedirect,
} from "./components/account/OAuth2Pages";
import ResetPassword from "./components/account/ResetPassword";
import SignUpPage from "./components/account/sign-up/SignUpPage";
import ToastNotificationProvider from "./components/admin/ToastNotificationProvider";
import CalculationTable from "./components/calculation-table/CalculationTable";
import CollectionPage from "./components/collections/CollectionPage";
import CollectionRecipePage from "./components/collections/CollectionRecipePage";
import CollectionsPage from "./components/collections/CollectionsPage";
import NewCollectionRecipePage from "./components/collections/NewCollectionRecipePage";
import ProcurementDashboardPage from "./components/corporate-reporting-dashboards/ProcurementDashboard";
import ProductsDashboardPage from "./components/dashboard/ProductsDashboardPage";
import { FlagPage } from "./components/flag/FlagPage";
import GraphQLProvider from "./components/graphql/GraphQLProvider";
import { ImpersonationContainer } from "./components/Impersonation";
import IngredientsPage from "./components/ingredients/IngredientsPage";
import * as MaintenanceWindowNotice from "./components/MaintenanceWindowNotice";
import NotFoundPage from "./components/NotFoundPage";
import OrganizationProvider, {
  useOrganization,
} from "./components/organizations/OrganizationProvider";
import EditPackagingComponentPage from "./components/packaging/EditPackagingComponentPage";
import NewPackagingComponentPage from "./components/packaging/NewPackagingComponentPage";
import { PackagingPage } from "./components/packaging/PackagingPage";
import { usePages } from "./components/pages";
import EditRecipePage from "./components/recipes/EditRecipePage";
import NewRecipePage from "./components/recipes/NewRecipePage";
import RecipePage from "./components/recipes/RecipePage";
import RecipesPage from "./components/recipes/RecipesPage";
import UploadRecipesPage from "./components/recipes/UploadRecipesPage";
import SalesDashboardPage from "./components/sales-dashboard/SalesDashboardPage";
import { LandUsePage } from "./components/scope-3/LandUsePage";
import { Scope3Page } from "./components/scope-3/Scope3Page";
import { WaterUsePage } from "./components/scope-3/WaterUsePage";
import SharedProductsPage from "./components/sharing/shared-products/SharedProductsPage";
import SharedProductPage from "./components/sharing/SharedProductPage";
import StoriesPage from "./components/StoriesPage";
import PlansPage from "./components/subscriptions/PlansPage";
import usePlansEnabled from "./components/subscriptions/usePlansEnabled";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import Spinner from "./components/utils/Spinner";
import { CollectionsProviderOwnOrganization } from "./data-store/useCollections";
import useIsAdmin from "./data-store/useIsAdmin";
import { UserInfoProvider } from "./data-store/useUserInfo";
import { IntlProvider, IntlProviderWithExplicitLocale } from "./localization";
import * as monitoring from "./monitoring";
import RemoteDataStoreProvider from "./services/api/RemoteDataStoreProvider";
import {
  useIsStaff,
  useShouldHideIngredientsPage,
} from "./services/useFeatureFlags";
import {
  useProductTags,
  useDashboard,
  useFoodManufacturerOrganization,
  useLandUse,
  useProductPackaging,
  useScope3Report,
  useViewSharedProducts,
  useWaterUse,
  useFlag,
} from "./services/useOrganizationFeatures";
import { useSession } from "./sessions";
import ServerSessionProvider from "./sessions/ServerSessionProvider";
import MixpanelTrackingProvider from "./tracking/MixpanelTrackingProvider";

const AdminSite = React.lazy(() => import("./components/admin/AdminSite"));

monitoring.init();

export default function App() {
  const { DataStoreProvider, Routes, SessionProvider } = {
    DataStoreProvider: RemoteDataStoreProvider,
    Routes: PlatformRoutes,
    SessionProvider: ServerSessionProvider,
  };

  return (
    <div className="App">
      <IntlProviderWithExplicitLocale locale="en-GB">
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <SessionProvider>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <DataStoreProvider>
                  <GraphQLProvider>
                    <UserInfoProvider>
                      <OrganizationProvider>
                        <MixpanelTrackingProvider>
                          <IntlProvider>
                            <MaintenanceWindowNotice.Container>
                              <ImpersonationContainer>
                                <ToastNotificationProvider>
                                  <CollectionsProviderOwnOrganization>
                                    <Routes />
                                  </CollectionsProviderOwnOrganization>
                                </ToastNotificationProvider>
                              </ImpersonationContainer>
                            </MaintenanceWindowNotice.Container>
                          </IntlProvider>
                        </MixpanelTrackingProvider>
                      </OrganizationProvider>
                    </UserInfoProvider>
                  </GraphQLProvider>
                </DataStoreProvider>
              </BrowserRouter>
            </SessionProvider>
          </Suspense>
        </ErrorBoundary>
      </IntlProviderWithExplicitLocale>
    </div>
  );
}

function PlatformRoutes() {
  const pages = usePages();
  const shouldHideIngredientsPage = useShouldHideIngredientsPage();
  const { userId } = useSession();

  const loggedOutRoutes = (
    <Switch>
      <Route exact path={pages.ResetPassword.url}>
        <ResetPassword />
      </Route>
      <Route path={pages.SignUp.url}>
        <SignUpPage />
      </Route>
      <Route exact path="/oauth2/authorized">
        <OAuth2AuthorizedPage />
      </Route>
      <Route path={pages.LogIn.url}>
        <OAuth2AuthorizeRedirect />
      </Route>
    </Switch>
  );

  const loggedInRoutes = (
    <LoggedInRoutes shouldHideIngredientsPage={shouldHideIngredientsPage} />
  );

  return userId === null ? loggedOutRoutes : loggedInRoutes;
}

interface LoggedInRoutesProps {
  shouldHideIngredientsPage: boolean;
}

function LoggedInRoutes(props: LoggedInRoutesProps) {
  const { shouldHideIngredientsPage } = props;
  const isAdmin = useIsAdmin();
  const isStaff = useIsStaff();
  const pages = usePages();
  const canViewCollectionsPage = useProductTags();
  const canViewDashboardPage = useDashboard();
  const canViewPackagingPage = useProductPackaging();
  const landUse = useLandUse();
  const flag = useFlag();
  const plansEnabled = usePlansEnabled();
  const canViewSharedProducts = useViewSharedProducts();
  const [organization] = useOrganization();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const scope3Report = useScope3Report();
  const waterUse = useWaterUse();

  const recipeUploadUrl = pages.RecipesUpload.url;

  return (
    <Switch>
      {(process.env.NODE_ENV === "development" || isStaff) && (
        <Route path="/_stories">
          <StoriesPage />
        </Route>
      )}
      <Route exact path={pages.ResetPassword.url}>
        <ResetPassword />
      </Route>
      <Route exact path={pages.Dashboard().url}>
        {canViewDashboardPage ? (
          <ProductsDashboardPage />
        ) : (
          <Redirect exact to={pages.Recipes.url} />
        )}
      </Route>
      <Route exact path={pages.Recipes.url}>
        <RecipesPage />
      </Route>
      <Route exact path={"/products/all-products"}>
        <Redirect exact to={pages.Recipes.url} />
      </Route>
      {foodManufacturerOrganization && (
        <Route exact path={pages.RecipeEdit.urlTemplate}>
          <EditRecipePage />
        </Route>
      )}
      {pages.RecipesNew.url === null ? null : (
        <Route exact path={pages.RecipesNew.url}>
          <NewRecipePage />
        </Route>
      )}
      {recipeUploadUrl === null ? null : (
        <Route exact path={recipeUploadUrl}>
          <UploadRecipesPage />
        </Route>
      )}
      <Route exact path={pages.Recipe.urlTemplate}>
        <RecipePage />
      </Route>
      <Route exact path={pages.SalesDashboard.url}>
        <SalesDashboardPage />
      </Route>
      {canViewCollectionsPage && (
        <Route path={pages.Collections().url}>
          <Switch>
            <Route exact path={pages.Collections().url}>
              <CollectionsPage />
            </Route>
            <Route exact path={pages.Collection.urlTemplate}>
              <CollectionPage />
            </Route>
            <Route exact path={pages.CollectionRecipe().urlTemplate}>
              <CollectionRecipePage />
            </Route>
            {pages.CollectionRecipesNew.urlTemplate === null ? null : (
              <Route exact path={pages.CollectionRecipesNew.urlTemplate}>
                <NewCollectionRecipePage />
              </Route>
            )}
          </Switch>
        </Route>
      )}
      {canViewPackagingPage && (
        <Route path={pages.Packaging().url}>
          <Switch>
            <Route exact path={pages.Packaging().url}>
              <PackagingPage />
            </Route>
            <Route exact path={pages.PackagingComponentsEdit.urlTemplate}>
              <EditPackagingComponentPage />
            </Route>
            <Route exact path={pages.PackagingComponentsNew.url}>
              <NewPackagingComponentPage />
            </Route>
          </Switch>
        </Route>
      )}
      <Route exact path={pages.AccountDetails.url}>
        <AccountDetailsPage />
      </Route>
      {!shouldHideIngredientsPage && (
        <Route exact path={pages.Ingredients.url}>
          <IngredientsPage />
        </Route>
      )}
      {canViewSharedProducts && organization.parentId !== null && (
        <Route path={pages.SharedProducts.url}>
          <Switch>
            <Route exact path={pages.SharedProducts.url}>
              <SharedProductsPage />
            </Route>
            <Route exact path={pages.SharedProduct().urlTemplate}>
              <SharedProductPage />
            </Route>
          </Switch>
        </Route>
      )}
      <Route exact path={pages.ContactUs.url}>
        <ContactUsPage />
      </Route>
      {plansEnabled && (
        <Route exact path={pages.Plans.url}>
          <PlansPage />
        </Route>
      )}
      {isAdmin && (
        <Route path="/admin">
          <AdminSite />
        </Route>
      )}
      {isStaff && (
        <Route exact path={pages.CalculationTable.url}>
          <CalculationTable />
        </Route>
      )}
      {scope3Report && (
        <>
          <Route exact path={pages.Scope3.url}>
            <Scope3Page />
          </Route>
          {flag && (
            <Route exact path={pages.Flag.url}>
              <FlagPage />
            </Route>
          )}
          {landUse && (
            <Route exact path={pages.LandUse.url}>
              <LandUsePage />
            </Route>
          )}
          {waterUse && (
            <Route exact path={pages.WaterUse.url}>
              <WaterUsePage />
            </Route>
          )}
          <Route exact path={pages.ProcurementDashboard.url}>
            <ProcurementDashboardPage />
          </Route>
        </>
      )}
      <Route exact path={"/recipes"}>
        <Redirect to={pages.Recipes.url} />
      </Route>
      <Route exact path={"/recipes/upload"}>
        <Redirect to={recipeUploadUrl ?? "/"} />
      </Route>
      <Route exact path={"/recipes/new"}>
        <Redirect to={pages.RecipesNew.url ?? "/"} />
      </Route>
      {canViewCollectionsPage && (
        <Route exact path={"/collections"}>
          <Redirect to={pages.Collections().url} />
        </Route>
      )}
      <Route exact path={"/ingredients"}>
        <Redirect to={pages.Ingredients.url} />
      </Route>
      <Route exact path={"/packaging"}>
        <Redirect to={pages.Packaging().url} />
      </Route>
      <Route exact path={"/packaging/new"}>
        <Redirect to={pages.PackagingComponentsNew.url} />
      </Route>
      <Route exact path={"/shared-products"}>
        <Redirect to={pages.SharedProducts.url} />
      </Route>
      <Route exact path={"/account"}>
        <Redirect to={pages.AccountDetails.url} />
      </Route>
      <Route exact path={"/contact"}>
        <Redirect to={pages.ContactUs.url} />
      </Route>
      <Route path="/">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
