import gql from "graphql-tag";

import { ExternalCalculationErrorCode } from "../../__generated__/globalTypes";
import { useRecipeImpactIsPending_RecipeImpact } from "./useRecipeIsPending.graphql";

export function useRecipeImpactIsPending(
  impact: useRecipeImpactIsPending_RecipeImpact
) {
  return impact.errors.some(
    (error) =>
      error.errorCode ===
        ExternalCalculationErrorCode.INGREDIENT_NOT_FOUND_IN_DATABASE ||
      error.errorCode ===
        ExternalCalculationErrorCode.INGREDIENT_IS_NOT_VALIDATED
  );
}

export const fragments = {
  recipeImpact: gql`
    fragment useRecipeImpactIsPending_RecipeImpact on RecipeImpact {
      errors {
        errorCode
      }
    }
  `,
};
