import ReactDOMServer from "react-dom/server";

import { TooltipCarat, TooltipCaratLeft } from "../../utils/Vectors";
export function externalTooltipHandler<T>(
  context: { chart: any; tooltip: any },
  horizontal: boolean,
  customTooltip?: (
    tooltip: { dataPoints: Array<any> },
    tooltipExtraData?: T
  ) => JSX.Element | null,
  positionAdjustment?: { x: string; y: string }
) {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, horizontal, positionAdjustment);

  tooltip.body = [
    {
      lines: [ReactDOMServer.renderToStaticMarkup(customTooltip!(tooltip)!)],
    },
  ];

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = "<thead style='padding-bottom: 0; margin-bottom: 0'>";

    bodyLines.forEach(function (body: any, i: any) {
      const colors = tooltipModel.labelColors[i];
      let style = "background:" + colors.backgroundColor;
      style += "; border-color:" + colors.borderColor;
      style += "; border-width: 8px";
      const span = '<span style="' + style + '">' + body + "</span>";
      innerHtml += "<tr><td>" + span + "</td></tr>";
    });
    innerHtml += "</tbody>";

    let tableRoot = tooltipEl.querySelector("table");
    // @ts-ignore
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.scrollX + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.scrollY + tooltipModel.caretY + "px";
  // tooltipEl.style.font = bodyFont.string;
  tooltipEl.style.padding =
    tooltipModel.padding + "px " + tooltipModel.padding + "px";
  tooltipEl.style.pointerEvents = "none";
}

const getOrCreateTooltip = (
  chart: any,
  horizontal: boolean,
  positionAdjustment?: { x: string; y: string }
) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  const getOrCreateTooltipCarat = (chart: any) => {
    let tooltipCaratEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipCaratEl) {
      tooltipCaratEl = document.createElement("div");
      tooltipCaratEl.style.background = "transparent";
      tooltipCaratEl.style.display = "flex";
      tooltipCaratEl.style.transition = "all .5s ease";

      if (horizontal) {
        tooltipCaratEl.style.justifyContent = "flex-start";
        tooltipCaratEl.style.alignContent = "center";
        tooltipCaratEl.style.transform = "translate(3px, 0)";
        tooltipCaratEl.style.marginLeft = "-12px";
        tooltipCaratEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <TooltipCaratLeft width={14} fill="rgba(0, 0, 0, 0.8)" />
        );
      } else {
        tooltipCaratEl.style.justifyContent = "center";
        tooltipCaratEl.style.alignContent = "flex-end";
        tooltipCaratEl.style.transform = "translate(0, 5px)";
        tooltipCaratEl.style.marginTop = "-6px";
        tooltipCaratEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <TooltipCarat width={14} fill="rgba(0, 0, 0, 0.8)" />
        );
      }
    }
    return tooltipCaratEl;
  };

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
    tooltipEl.style.borderRadius = "6px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    const table = document.createElement("table");
    table.style.margin = "0px";

    if (horizontal) {
      tooltipEl.style.display = "flex";
      tooltipEl.style.flexDirection = "row";
      tooltipEl.style.transform = `translate(calc(12px + ${
        positionAdjustment?.x ?? "0px"
      }), calc(-50% + ${positionAdjustment?.y ?? "0px"}))`;

      tooltipEl.appendChild(getOrCreateTooltipCarat(chart));
      tooltipEl.appendChild(table);
    } else {
      tooltipEl.style.transform = `translate(calc(-50% + ${
        positionAdjustment?.x ?? "0px"
      }), calc(-100% - 12px + ${positionAdjustment?.y ?? "0px"}))`;
      tooltipEl.appendChild(table);
      tooltipEl.appendChild(getOrCreateTooltipCarat(chart));
    }

    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};
