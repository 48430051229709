import { createContext, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { AssessmentsProvider } from "../../data-store/AssessmentsProvider";
import useUserInfo, {
  Organization,
  useUserInfoOrNull,
} from "../../data-store/useUserInfo";
import { useLocalStorageState } from "../../services/LocalStorageService";
import { useLogoutListener } from "../../sessions";
import * as comparators from "../../util/comparators";
import sort from "../../util/sort";
import Card from "../utils/Card";
import ChevronRight from "../utils/Vectors/ChevronRight";

export type { Organization };

const OrganizationContext = createContext<
  [string | null, (organizationId: string) => void] | undefined
>(undefined);

export const FixedOrganizationProvider = OrganizationContext.Provider;

interface OrganizationProviderProps {
  children: React.ReactNode;
}

export default function OrganizationProvider(props: OrganizationProviderProps) {
  const { children } = props;
  const userInfo = useUserInfoOrNull();

  return userInfo === null ? (
    <OrganizationContext.Provider value={[null, () => null]}>
      {children}
    </OrganizationContext.Provider>
  ) : (
    <OrganizationProviderForUser key={userInfo.id}>
      {children}
    </OrganizationProviderForUser>
  );
}

interface OrganizationProviderForUserProps {
  children: React.ReactNode;
}

function OrganizationProviderForUser(props: OrganizationProviderForUserProps) {
  const { children } = props;

  const [userInfo] = useUserInfo();
  const [organizationId, setOrganizationId] = useOrganizationIdState(
    userInfo.id,
    () => {
      // We only automatically select an organization if one is not already
      // selected, such as on login, and there is only one possible organization
      // to select. This avoids users that belong to only organization from
      // having to make a selection on login, while also avoiding weird behaviour
      // for users that belong to two organizations, have one organization
      // removed, and get silently switched to a different organization.
      if (userInfo.organizationMemberships.length === 1) {
        return userInfo.organizationMemberships[0].organization.id;
      } else {
        return null;
      }
    }
  );

  const organizations = userInfo.organizationMemberships.map(
    (membership) => membership.organization
  );

  if (
    organizationId === null ||
    organizations.find((organization) => organization.id === organizationId) ===
      undefined
  ) {
    return (
      <SelectOrganizationPage
        onSelect={setOrganizationId}
        organizations={organizations}
      />
    );
  } else {
    return (
      <OrganizationContext.Provider value={[organizationId, setOrganizationId]}>
        <AssessmentsProvider organizationId={organizationId}>
          {children}
        </AssessmentsProvider>
      </OrganizationContext.Provider>
    );
  }
}

function useOrganizationIdState(
  userId: number,
  initialValue: () => string | null
): [string | null, (organizationId: string | null) => void] {
  const [localStorageUserId, setLocalStorageUserId] = useLocalStorageState(
    "organization_user_id"
  );
  const [localStorageOrganizationId, setLocalStorageOrganizationId] =
    useLocalStorageState("organization_id");

  const [organizationId, setOrganizationId] = useState<string | null>(() => {
    if (
      localStorageUserId !== null &&
      localStorageUserId === userId.toString()
    ) {
      return localStorageOrganizationId;
    } else {
      return initialValue();
    }
  });

  useEffect(() => {
    setLocalStorageUserId(userId.toString());
    setLocalStorageOrganizationId(organizationId);
  }, [
    userId,
    organizationId,
    setLocalStorageUserId,
    setLocalStorageOrganizationId,
  ]);

  useLogoutListener(() => {
    setLocalStorageUserId(null);
    setLocalStorageOrganizationId(null);
  });

  return [organizationId, setOrganizationId];
}

interface SelectOrganizationPageProps {
  onSelect: (organizationId: string) => void;
  organizations: Array<Organization>;
}

function SelectOrganizationPage(props: SelectOrganizationPageProps) {
  const { onSelect, organizations } = props;

  const sortedOrganizations = sort(
    organizations,
    comparators.map(
      (organization) => organization.name,
      comparators.stringSensitivityBase
    )
  );

  return (
    <div className="h-100 d-flex align-items-center justify-content-center bg-light">
      <Card className="p-5">
        <h2 className="mb-4 text-center">
          <FormattedMessage
            id="components/organizations/OrganizationProvider:title"
            defaultMessage="Select Organisation"
          />
        </h2>

        <div className="list-group">
          {sortedOrganizations.map((organization) => (
            <button
              className="d-flex justify-content-between list-group-item list-group-item-action flex-row"
              key={organization.id}
              onClick={() => onSelect(organization.id)}
            >
              {organization.name}{" "}
              <span className="pl-4">
                <ChevronRight width={16} />
              </span>
            </button>
          ))}
        </div>
      </Card>
    </div>
  );
}

export function useOrganizationId(): [string, (organization: string) => void] {
  const [organizationId, setOrganizationId] = useOrganizationIdOrNull();

  if (organizationId === null) {
    throw new Error("organizationId not set");
  } else {
    return [organizationId, setOrganizationId];
  }
}

function useOrganizationIdOrNull(): [
  string | null,
  (organization: string) => void
] {
  const result = useContext(OrganizationContext);

  if (result === undefined) {
    throw new Error("missing provider for OrganizationContext");
  }

  return result;
}

export function useOrganization(): [
  Organization,
  (organizationId: string) => void
] {
  const [organization, setOrganizationId] = useOrganizationOrNull();
  if (organization === null) {
    throw new Error("organization not set");
  } else {
    return [organization, setOrganizationId];
  }
}

export function useOrganizationWithRefresh(): [
  Organization,
  () => Promise<void>
] {
  const [organization] = useOrganization();
  const [, refreshUserInfo] = useUserInfo();

  return [organization, refreshUserInfo];
}

export function useOrganizationOrNull(): [
  Organization | null,
  (organizationId: string) => void
] {
  const [organizationId, setOrganizationId] = useOrganizationIdOrNull();
  const userInfo = useUserInfoOrNull();
  if (organizationId === null || userInfo === null) {
    return [null, setOrganizationId];
  } else {
    const membership = userInfo.organizationMemberships.find(
      (membership) => membership.organization.id === organizationId
    );
    return [
      membership === undefined ? null : membership.organization,
      setOrganizationId,
    ];
  }
}
