import { useCallbackOne } from "use-memo-one";

import { useDataStore } from ".";
import usePromise from "../components/utils/usePromise";

export default function useAssessments(
  organizationId: string,
  queryString?: string
) {
  const dataStore = useDataStore();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportAssessmentList(
      organizationId,
      queryString
    );
  }, [dataStore, organizationId, queryString]);

  return usePromise(load);
}
