import { createContext, useContext, useState, ReactNode } from "react";

import { ImpactCategory } from "../../domain/impactCategories";

interface ImpactCategoryContextProps {
  impactCategory: ImpactCategory;
  setImpactCategory: (impactCategory: ImpactCategory) => void;
}

const ImpactCategoryContext = createContext<
  ImpactCategoryContextProps | undefined
>(undefined);

export function ImpactCategoryProvider({ children }: { children: ReactNode }) {
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );

  return (
    <ImpactCategoryContext.Provider
      value={{ impactCategory, setImpactCategory }}
    >
      {children}
    </ImpactCategoryContext.Provider>
  );
}

export function useImpactCategory() {
  const context = useContext(ImpactCategoryContext);
  if (!context) {
    throw new Error(
      "useImpactCategory must be used within an ImpactCategoryProvider"
    );
  }
  return context;
}
