import { useState } from "react";
import { useIntl } from "react-intl";

import { ProcuredItem } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { usePages } from "../pages";
import { Table, TableColumn, TableSort } from "../utils/Table";
import { formatString, intlFormatNumber } from "./helperFunctions";

interface ProcuredItemsTableProps {
  data: Array<ProcuredItem>;
  impactCategory: ImpactCategory;
}

export default function ProcuredItemsTable(props: ProcuredItemsTableProps) {
  const { data } = props;

  const intl = useIntl();
  const pages = usePages();
  const [queryParams] = pages.Scope3Page.useQueryParams();
  const [sort, setSort] = useState<TableSort | null>(
    queryParams.viewHighestImpactItems
      ? {
          columnKey: "impact",
          descending: true,
        }
      : null
  );

  const columns: Array<TableColumn<ProcuredItem>> = [
    {
      key: "procured-item",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:procuredItem",
        defaultMessage: "Procured Item",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.name),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "category",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:category",
        defaultMessage: "Category",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.categoryName),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.categoryName,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "category 2",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:category2",
        defaultMessage: "Category 2",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.category2Name),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.category2Name,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "category 3",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:category3",
        defaultMessage: "Category 3",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.category3Name),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.category3Name,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "site",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:site",
        defaultMessage: "Site",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.site),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.site,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "supplier",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:supplier",
        defaultMessage: "Supplier",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.supplier),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.supplier,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "foodType",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:foodType",
        defaultMessage: "Site",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.foodType),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.foodType,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "landUseChangeCO2",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:landUseChangeCO2",
        defaultMessage: "Land Use Change - CO2",
      }),
      units: "kg CO2e",
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.landUseChangeCO2)),
      sortComparator: comparators.map(
        (item) => item.landUseChangeCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCO2",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:landManagementCO2",
        defaultMessage: "Land Management - CO2",
      }),
      units: "kg CO2e",
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.landManagementCO2)),
      sortComparator: comparators.map(
        (item) => item.landManagementCO2 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementCH4",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:landManagementCH4",
        defaultMessage: "Land Management - CH4",
      }),
      units: "kg CO2e",
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.landManagementCH4)),
      sortComparator: comparators.map(
        (item) => item.landManagementCH4 ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "landManagementN2O",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:landManagementN2O",
        defaultMessage: "Land Management - N20",
      }),
      units: "kg CO2e",
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.landManagementN2O)),
      sortComparator: comparators.map(
        (item) => item.landManagementN2O ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "flagTotal",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:flagTotal",
        defaultMessage: "FLAG Total",
      }),
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.flagTotal)),
      sortComparator: comparators.map(
        (item) => item.flagTotal ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "energy",
      label: intl.formatMessage({
        id: "components/flag/ProcuredItemsTable:energyIndustry",
        defaultMessage: "Energy and Industry",
      }),
      renderCell: (procuredItem) =>
        intlFormatNumber(intl, Number(procuredItem.energy)),
      sortComparator: comparators.map(
        (item) => item.energy ?? null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (procuredItem) => intlFormatNumber(intl, procuredItem.massKg),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.massKg,
        comparators.nullsLast(comparators.number)
      ),
    },
  ];

  return (
    <Table<ProcuredItem>
      fullWidth
      columns={columns}
      onSortChange={setSort}
      rowKey={(procuredItem) => procuredItem.internalId}
      rows={data}
      sort={sort}
    />
  );
}
