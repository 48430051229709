import { PropsWithChildren, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { SalesDashboardData } from "../../../data-store";
import useSalesDashboardData from "../../../data-store/useSalesDashboardData";
import StatusDisplay from "../../StatusDisplay";
import "../SalesDashboardPage.css";
import ChartsContainerController from "./ChartsContainerController";

interface ChartsContainerProps {}

const ChartsContainer: React.FC<
  PropsWithChildren<ChartsContainerProps>
> = () => {
  const intl = useIntl();
  const availableCharts = ["IMPACT_CATEGORY", "RATING_DISTRIBUTION"];
  const availablePeriods = [
    {
      label: intl.formatMessage({
        id: "components/pages:SalesDashBoard/monthly",
        defaultMessage: "Monthly",
      }),
      value: "MONTHLY",
    },
    // Deprioritized for now
    // {
    //   label: intl.formatMessage({
    //     id: "components/pages:SalesDashBoard/quarterly",
    //     defaultMessage: "Quarterly",
    //   }),
    //   value: "QUARTERLY",
    // },
    // {
    //   label: intl.formatMessage({
    //     id: "components/pages:SalesDashBoard/yearly",
    //     defaultMessage: "Yearly",
    //   }),
    //   value: "YEARLY",
    // },
  ];
  const [salesDashboardDataStatus] = useSalesDashboardData();
  const [selectedPeriod, setSelectedPeriod] = useState(
    availablePeriods[0].value
  );
  const [selectedChart, setSelectedChart] = useState(availableCharts[0]);

  const handleChangeChart = useCallback((e) => {
    setSelectedChart(e);
  }, []);
  const handleChangePeriod = useCallback((e) => {
    setSelectedPeriod(e);
  }, []);

  return (
    <div style={{ minHeight: 348 }} className="SalesDashboardCard">
      <StatusDisplay.Many<[SalesDashboardData | null]>
        statuses={[salesDashboardDataStatus]}
      >
        {() => {
          return (
            <div>
              <ChartsContainerController
                handleChangeChart={handleChangeChart}
                handleChangePeriod={handleChangePeriod}
                selectedChart={selectedChart}
                availableCharts={availableCharts}
                availablePeriods={availablePeriods}
                selectedPeriod={selectedPeriod}
              />
            </div>
          );
        }}
      </StatusDisplay.Many>
    </div>
  );
};

export default ChartsContainer;
