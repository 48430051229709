import { useCallbackOne } from "use-memo-one";

import { useDashboardOrganization } from "../components/dashboard/DashboardOrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useIsHeadOffice } from "../services/useOrganizationFeatures";
import { useDataStore } from "./index";

export default function useProductsDashboardData(filterParams = "") {
  const dataStore = useDataStore();
  const [dashboardOrganization] = useDashboardOrganization();
  const isHeadOffice = useIsHeadOffice();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchProductsDashboardData(
      dashboardOrganization.id,
      isHeadOffice,
      filterParams
    );
  }, [dataStore, dashboardOrganization, isHeadOffice, filterParams]);

  return usePromise(load);
}
