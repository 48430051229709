import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { ProductsDashboardPageContent } from "./ProductsDashboardPage";

const stories = {
  name: "productsDashboard",
  children: [
    {
      name: "Products Dashboard",
      render: () => (
        <ProductsDashboardPageContent
          impactCategory={ImpactCategory.GHG}
          filterParams=""
        />
      ),
    },
  ],
};

export default stories;
